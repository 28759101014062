import React from 'react';
import { Button, ListGroup, Badge } from 'react-bootstrap';

/**
 *
 * @param {*} props:readlist objetct with details and tags
 * @returns details modal with readlist links and tags associated
 */
const DetailView = (props) => {
  const { readListObj, setShowDetailModal } = props;

  return (
    <>
      <div className="modal-head-wrapper">
        <div className="d-flex justify-content-between w-100">
          <h2 className="modal-heading">Parent: {readListObj.title}</h2>
          <Button onClick={() => setShowDetailModal(false)} className="transparent-btn close-btn">
            <span className="cmp-close" />
          </Button>
        </div>
        <div className="form-wrapper">
          <div>
            <div className="card-list">
              <ListGroup variant="flush">
                {readListObj.signalMeta.map((articals) => (
                  <ListGroup.Item>
                    <a href={articals.signalUrl} target="_target">
                      {articals.signalTitle}
                    </a>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>

            <div className="mt-4">
              <p className="tag-title"> Tags: </p>
              {readListObj.tags ? (
                <>
                  <p className="minor-major grey-btn ">
                    {readListObj.tags.major.length > 0
                      ? readListObj.tags.major
                          .split(',')
                          .sort()
                          .map((tag) => <Badge key={tag}>{tag}</Badge>)
                      : ''}
                  </p>
                  <p className="grey-btn">
                    {readListObj.tags.minor.length > 0
                      ? readListObj.tags.minor
                          .split(',')
                          .sort()
                          .map((tag) => <Badge key={tag}>{tag}</Badge>)
                      : ''}
                  </p>
                </>
              ) : (
                <p className="grey-btn">
                  {readListObj.technologyList.length > 0 ? (
                    readListObj.technologyList.map((tag) => <Badge key={tag}>{tag}</Badge>)
                  ) : (
                    <Badge key={readListObj.title}>{readListObj.title}</Badge>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailView;
