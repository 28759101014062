import ApiUtils from './apiUtils';

/**
 * Fetch tags
 * @param {Object} param
 */
const fetchTags = async () => {
  try {
    const response = await ApiUtils.get(`/signals?status=failed`);
    const { data } = response;
    if (data && data.data.length > 0) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Put configuration by Id
 * @param {Object} param
 */
const putTagsById = async (param) => {
  try {
    const response = await ApiUtils.put(`/technologies/${param.signalId}`, param.data);
    const { data } = response;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: null,
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchTags,
  putTagsById,
};
