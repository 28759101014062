import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import Error from '../../../common/components/Error';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { putSourcesRequested } from '../../../state/sourcesTypes/sourcesTypesActions';
import validation from '../../../common/utils/validation';

/**
 *
 * @param {object} props:handlers to show and close edit modal with source detail
 * @returns form component to edit configuraion values
 */
const EditSources = (props) => {
  const { closeEdit, source } = props;
  const dispatch = useDispatch();
  const { isLoading, isError, errorMessage } = useSelector((state) => state.sourcesTypes.sources);
  const [title, setTitle] = useState(source.title || '');
  const [url, setUrl] = useState(source.url || '');
  const [istagsTouch, setIstagsTouch] = useState(false);

  const [titleValidationError, setTitleValidationError] = useState('');
  const [urlValidationError, setUrlValidationError] = useState('');
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

  /**
   * Function to validate cron values edited in edit-form
   * @returns {Boolean} - checks if the cron entered is valid or not
   */
  const validate = () => {
    // validate description
    if (validation.checkIsEmpty(title) && validation.checkIsEmpty(url)) {
      setTitleValidationError('');
      setUrlValidationError('');
      setIsDisabledSubmit(false);
      return true;
    }
    if (!validation.checkIsEmpty(title)) {
      setTitleValidationError('Title can not be empty');
      setIsDisabledSubmit(true);
    }
    if (!validation.checkIsEmpty(url)) {
      setTitleValidationError('URL can not be empty');
      setIsDisabledSubmit(true);
    }
    return false;
  };

  /**
   * Edit configuration form submit handler
   * @param {event} event
   */
  const editSourceFormHandler = (event) => {
    event.preventDefault();
    if (title || url) {
      setIstagsTouch(true);
    }
    const isFormValid = validate();
    if (isFormValid) {
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure want to submit?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              dispatch(
                putSourcesRequested({
                  key: source.id,
                  data: {
                    title,
                    url,
                  },
                })
              );
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (istagsTouch) {
      validate();
    }
  }, [title, url, istagsTouch]);

  return (
    <>
      <div className="modal-head-wrapper">
        <h2 className="modal-heading">Edit Sources</h2>
        <Button onClick={closeEdit} className="transparent-btn close-btn">
          <span className="cmp-close" />
        </Button>
      </div>
      {isError ? <Error msg={errorMessage} /> : null}
      <div className="form-wrapper">
        <Form className="compass-form" onSubmit={editSourceFormHandler}>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>ID:</Form.Label>
                <Form.Control type="text" value={source.id} disabled />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Title:</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Type:</Form.Label>
                <Form.Control type="text" value={source.type} disabled />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>URL:</Form.Label>
                <Form.Control
                  type="text"
                  value={url}
                  onChange={(event) => {
                    setUrl(event.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <p className="compass-red mt-2">{titleValidationError}</p>
              <p className="compass-red mt-2">{urlValidationError}</p>
            </Col>
          </Row>
          <div className="d-flex justify-content-end submit-btn">
            <Button
              className={isLoading ? 'button-loader' : ''}
              type="submit"
              disabled={isDisabledSubmit}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
export default EditSources;
