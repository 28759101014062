import { put, call, takeLatest } from 'redux-saga/effects';
import { SourcesTypesTypes } from './sourcesTypesTypes';
import {
  fetchSourcesSuccessfull,
  fetchSourcesFailure,
  fetchTypesSuccessfull,
  fetchTypesFailure,
  putSourcesFailure,
  putSourcesSuccessfull,
  postSourcesFailure,
  postSourcesSuccessfull,
} from './sourcesTypesActions';

import sourcesTypesApi from '../../api/sourcesTypesApi';
/**
 * Fetch sources
 * @param {Object} action
 */
function* fetchSources(action) {
  const { response, error } = yield call(sourcesTypesApi.fetchSources, action.payload);

  if (response) {
    yield put(fetchSourcesSuccessfull(response));
  } else {
    yield put(fetchSourcesFailure(error));
  }
}

/**
 * Fetch Types
 * @param {Object} action
 */
function* fetchTypes(action) {
  const { response, error } = yield call(sourcesTypesApi.fetchTypes, action.payload);
  if (response) {
    yield put(fetchTypesSuccessfull(response));
  } else {
    yield put(fetchTypesFailure(error));
  }
}

/**
 * Put source
 * @param {Object} action
 */
function* putSources(action) {
  const { response, error } = yield call(sourcesTypesApi.putSources, action.payload);
  if (response) {
    yield put(putSourcesSuccessfull(response));
  } else {
    yield put(putSourcesFailure(error));
  }
}

/**
 * POST source
 * @param {Object} action
 */
function* postSources(action) {
  const { response, error } = yield call(sourcesTypesApi.postSources, action.payload);
  if (response) {
    yield put(postSourcesSuccessfull(response));
  } else {
    yield put(postSourcesFailure(error));
  }
}
export default function* sourcesTypesSaga() {
  yield takeLatest(SourcesTypesTypes.FETCH_SOURCES_REQUESTED, fetchSources);
  yield takeLatest(SourcesTypesTypes.FETCH_TYPES_REQUESTED, fetchTypes);
  yield takeLatest(SourcesTypesTypes.PUT_SOURCES_REQUESTED, putSources);
  yield takeLatest(SourcesTypesTypes.POST_SOURCES_REQUESTED, postSources);
}
