import React from 'react';
import { Button, Badge, ListGroup } from 'react-bootstrap';

/**
 *
 * @param {*} props:readlist objetct with details and tags
 * @returns details modal with readlist links and tags associated
 */
const DetailView = (props) => {
  const { signalsObj, setShowDetailModal } = props;
  return (
    <>
      <div className="modal-head-wrapper">
        <div className="d-flex justify-content-between w-100">
          <Button onClick={() => setShowDetailModal(false)} className="transparent-btn close-btn">
            <span className="cmp-close" />
          </Button>
        </div>
        <div className="form-wrapper">
          <div>
            <div className="card-list">
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="tag-title">
                    Title:{' '}
                    <a href={signalsObj.url} target="_target">
                      {signalsObj.title}
                    </a>
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className="mt-4">
              <ListGroup variant="flush">
                <ListGroup.Item>Signal Type: {signalsObj.signalType}</ListGroup.Item>
                <ListGroup.Item className="source-text">Source: {signalsObj.source}</ListGroup.Item>
              </ListGroup>
            </div>
            <div className="mt-4">
              <p className="tag-title"> Tags: </p>
              {signalsObj.tags ? (
                <p className="grey-btn ">
                  {signalsObj.tags.length > 0
                    ? signalsObj.tags.map((tag) => <Badge key={tag}>{tag}</Badge>)
                    : ''}
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailView;
