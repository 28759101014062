import React from 'react';

const UnderConstruction = () => (
  <div className="construction">
    <h1 className="heading-one">Under Construction</h1>
    <h6 className="compass-white text-center">Check back in a bit to see our new look.</h6>
  </div>
);

export default UnderConstruction;
