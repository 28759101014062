export const SORT_OPTIONS = {
  1: 'All',
  2: 'By Date',
  3: 'By Type',
};

export const USER_SORT_OPTIONS = {
  1: 'All',
  2: 'By Date',
};

export const USER_ROLES = ['Admin', 'Internal', 'External'];
export const SIGNALS_TYPE = ['Manual', 'Newsletter', 'RSS'];

export const CONFIGURATION_TYPE = ['string', 'date', 'number', 'cron'];
export const IS_SECRET = { true: 'Yes', false: 'No' };

export const APPLY_ON = ['Created On', 'Updated On'];
export const DATE_RANGE = ['03/01/2021 - 03/31/2021'];

export const TRENDS_STATUS = ['Adopt', 'Hold'];

export const TRENDS_GROUP_BY = {
  days: 'last 7 days',
  month: 'last month',
  year: 'last year',
  custom: 'custom',
};

export const READLISTS_GROUP_BY = {
  days: 'last 7 days',
  month: 'last month',
  year: 'last year',
  custom: 'custom',
};

export const SIGNALS_GROUP_BY = {
  day: 'last 7 days',
  month: 'last month',
  year: 'last year',
  custom: 'custom',
};
export const TRENDS_GROUP_BY_URL = {
  days: 'days',
  month: 'days',
  year: 'month',
  custom: 'custom',
};

export const READLSIT_GROUP_BY_URL = {
  days: 'days',
  month: 'days',
  year: 'month',
  custom: 'custom',
};
export const SIGNALS_GROUP_BY_URL = {
  day: 'day',
  month: 'day',
  year: 'month',
  custom: 'custom',
};
