import { TrendsActionTypes } from './trendsTypes';

export const fetchTrendsRequested = (query) => ({
  type: TrendsActionTypes.FETCH_TRENDS_REQUESTED,
  payload: query,
});
export const fetchTrendsSuccessfull = (trends) => ({
  type: TrendsActionTypes.FETCH_TRENDS_SUCCESSFULL,
  payload: trends,
});

export const fetchTrendsFailure = (error) => ({
  type: TrendsActionTypes.FETCH_TRENDS_FAILURE,
  payload: error,
});
