/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_CONFIGURATION_REQUESTED,
  FETCH_CONFIGURATION_SUCCESSFULL,
  FETCH_CONFIGURATION_FAILURE,
  PUT_CONFIGURATION_REQUESTED,
  PUT_CONFIGURATION_SUCCESSFULL,
  PUT_CONFIGURATION_FAILURE,
  CONFIGURATION_RECORD_UPDATE_SUCCESS,
} from '../../common/constants/actions';

/**
 * Export the actions for reducers, actions and sagas
 */
export const ConfigurationActionTypes = {
  FETCH_CONFIGURATION_REQUESTED,
  FETCH_CONFIGURATION_SUCCESSFULL,
  FETCH_CONFIGURATION_FAILURE,
  PUT_CONFIGURATION_REQUESTED,
  PUT_CONFIGURATION_SUCCESSFULL,
  PUT_CONFIGURATION_FAILURE,
  CONFIGURATION_RECORD_UPDATE_SUCCESS,
};
