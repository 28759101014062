import React from 'react';
import { Button } from 'react-bootstrap';

/**
 *
 * @param {*} props:readlist objetct with details and tags
 * @returns details modal with readlist links and tags associated
 */
const DetailView = (props) => {
  const { setShowDetailModal } = props;

  const dropdownHandler = (delta) => {
    setShowDetailModal(false);
    window.open(`/report?delta=${delta}`);
  };
  return (
    <>
      <div className="modal-head-wrapper">
        <div className="d-flex justify-content-between w-100">
          <Button onClick={() => setShowDetailModal(false)} className="transparent-btn close-btn">
            <span className="cmp-close" />
          </Button>
        </div>
        <div className="form-wrapper">
          <div>
            <div className="card-list">
              <Button
                onClick={() => {
                  dropdownHandler('monthly');
                }}
              >
                Monthly
              </Button>
              <Button
                onClick={() => {
                  dropdownHandler('quarterly');
                }}
              >
                Quarter
              </Button>
              <Button
                onClick={() => {
                  dropdownHandler('halfyearly');
                }}
              >
                Year
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailView;
