import { put, call, takeLatest } from 'redux-saga/effects';
import { AreaSuAreaTagsActionTypes } from './areaSubAreaTagsTypes';
import { fetchTagSuccessfull, fetchTagFailure } from './areaSubAreaTagsActions';
import areaSubAreaTagsApi from '../../api/areaSubAreaTagsApi';

/**
 * Featch tag
 * @param {Object} action
 */
function* fetchTag(action) {
  const { response, error } = yield call(areaSubAreaTagsApi.fetchTag, action.payload);
  if (response) {
    yield put(fetchTagSuccessfull(response));
  } else {
    yield put(fetchTagFailure(error));
  }
}

export default function* areaSubAreaTagsSaga() {
  yield takeLatest(AreaSuAreaTagsActionTypes.FETCH_TAG_REQUESTED, fetchTag);
}
