import { ReportTypes } from './reportTypes';

export const fetchOverviewRequested = (query) => ({
  type: ReportTypes.FETCH_OVERVIEW_REQUESTED,
  payload: query,
});

export const fetchOverviewSuccessfull = (sources) => ({
  type: ReportTypes.FETCH_OVERVIEW_SUCCESSFULL,
  payload: sources,
});

export const fetchOverviewFailure = (error) => ({
  type: ReportTypes.FETCH_OVERVIEW_FAILURE,
  payload: error,
});

export const fetchReportTagsRequested = (query) => ({
  type: ReportTypes.FETCH_REPORT_TAGS_REQUESTED,
  payload: query,
});

export const fetchReportTagsSuccessfull = (tags) => ({
  type: ReportTypes.FETCH_REPORT_TAGS_SUCCESSFULL,
  payload: tags,
});

export const fetchReportTagsFailure = (error) => ({
  type: ReportTypes.FETCH_REPORT_TAGS_FAILURE,
  payload: error,
});

export const fetchReportSignalsRequested = (query) => ({
  type: ReportTypes.FETCH_REPORT_SIGNALS_REQUESTED,
  payload: query,
});

export const fetchReportSignalsSuccessfull = (signals) => ({
  type: ReportTypes.FETCH_REPORT_SIGNALS_SUCCESSFULL,
  payload: signals,
});

export const fetchReportSignalsFailure = (error) => ({
  type: ReportTypes.FETCH_REPORT_SIGNALS_FAILURE,
  payload: error,
});

export const fetchReportSourcesRequested = (query) => ({
  type: ReportTypes.FETCH_REPORT_SOURCES_REQUESTED,
  payload: query,
});

export const fetchReportSourcesSuccessfull = (sources) => ({
  type: ReportTypes.FETCH_REPORT_SOURCES_SUCCESSFULL,
  payload: sources,
});

export const fetchReportSourcesFailure = (error) => ({
  type: ReportTypes.FETCH_REPORT_SOURCES_FAILURE,
  payload: error,
});

export const fetchReportReadlsitsRequested = (query) => ({
  type: ReportTypes.FETCH_REPORT_READLISTS_REQUESTED,
  payload: query,
});

export const fetchReportReadlsitsSuccessfull = (sources) => ({
  type: ReportTypes.FETCH_REPORT_READLISTS_SUCCESSFULL,
  payload: sources,
});

export const fetchReportReadlsitsFailure = (error) => ({
  type: ReportTypes.FETCH_REPORT_READLISTS_FAILURE,
  payload: error,
});
