/**
 * Import constant types
 */
import { UserActionTypes } from './userTypes';

/**
 *
 * @function :  dashBoardReducer
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */
const userReducer = (state = {}, action) => {
  switch (action.type) {
    case UserActionTypes.FETCH_USER_REQUESTED:
      return { ...state, isLoading: true };

    case UserActionTypes.FETCH_USER_SUCCESSFULL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case UserActionTypes.FETCH_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        userList: [],
      };

    case UserActionTypes.PUT_USER_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionTypes.PUT_USER_SUCCESSFULL: {
      const updatedData = state.data;
      updatedData[updatedData.findIndex((el) => el.key === action.payload.data.key)] =
        action.payload.data;
      return {
        ...state,
        data: updatedData,
        isLoading: false,
        isUpdateSuccess: true,
      };
    }
    case UserActionTypes.PUT_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case UserActionTypes.USER_RECORD_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
