/**
 * Import constant types
 */
import { ConfigurationActionTypes } from './configurationTypes';

/**
 *
 * @function :  configurationReducer
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */
const configurationReducer = (state = {}, action) => {
  switch (action.type) {
    /**
     * Action Creator - fetchConfiguration
     * This action is use to call the service to get configuration
     */
    case ConfigurationActionTypes.FETCH_CONFIGURATION_REQUESTED:
      return { ...state, isLoading: true, isError: false };
    /**
     * Action Creator - fetchConfigurationSuccessfull
     * it will receive the data dispatching from the fetchConfigurationSuccessfull
     */
    case ConfigurationActionTypes.FETCH_CONFIGURATION_SUCCESSFULL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    /**
     * Action Creator - fetchConfigurationFailure
     * it will receive the error dispatching from the fetchConfigurationFailure
     */
    case ConfigurationActionTypes.FETCH_CONFIGURATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        configurationList: [],
      };
    /**
     * Action Creator - putConfiguration
     * This action is use to call the service to edit configuration
     */
    case ConfigurationActionTypes.PUT_CONFIGURATION_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    /**
     * Action Creator - putConfigurationSuccessfull
     * it will receive the data dispatching from the putConfigurationSuccessfull
     */
    case ConfigurationActionTypes.PUT_CONFIGURATION_SUCCESSFULL: {
      const updatedData = state.data;
      updatedData[updatedData.findIndex((el) => el.key === action.payload.data.key)] =
        action.payload.data;
      return {
        ...state,
        data: updatedData,
        isLoading: false,
        isUpdateSuccess: true,
      };
    }
    /**
     * Action Creator - putConfigurationFailure
     * it will receive the error dispatching from the putConfigurationFailure
     */
    case ConfigurationActionTypes.PUT_CONFIGURATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
      };
    case ConfigurationActionTypes.CONFIGURATION_RECORD_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: action.payload,
      };

    default:
      return state;
  }
};

export default configurationReducer;
