import React, { useState, useEffect } from 'react';
import { Col, Row, Accordion, Button, Card } from 'react-bootstrap';
import moment from 'moment';
import { TIME_FORMAT } from '../../../common/constants/common';
import { SIGNALS_GROUP_BY } from '../../../common/constants/options';
import SelectedFilter from './SelectedFilter';
import { textCapitalized } from '../../../common/utils/helpers';
import 'react-datepicker/dist/react-datepicker.css';
import SignalFilterView from './SignalFilterView';

/**
 *
 * @param {object} props -  from main component
 * @returns search filter component
 */
const SearchFilter = (props) => {
  const {
    // selected values
    selectedTags,
    selectedSources,
    selectedTypes,
    fromDate,
    toDate,

    // change hadlers
    tagChangeHandler,
    sourcesChangeHandler,
    typesChangeHandler,

    setSelectedDateRange,
    groupBy,
    setGroupByHandler,
    // remove handlers
    dateRangeRemoveHandler,
    gropByRemoveHandler,
    searchTagRemoveHandler,
    searchSourcesRemoveHandler,
    searchTypesRemoveHandler,
    // clear all filters
    clearFilterHandler,
    // apply filter handler
    applyFilterHandler,
    // all data
    allTags,
    allSources,
    allTypes,
  } = props;

  const [showSignalFilterView, setShowSignalFilterView] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const offset = !!(window.scrollY > 200);
    setScrolled(offset);
  };

  // Scroll listner for filter
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const filterSection = () => (
    <div className="btn-box">
      {fromDate && toDate !== '' && groupBy === 'custom' ? (
        <SelectedFilter
          key={fromDate}
          colorCode="filter-select-green"
          item={{
            label: `${fromDate ? moment(fromDate).format(TIME_FORMAT) : ''} - ${
              toDate ? moment(toDate).format(TIME_FORMAT) : ''
            }`,
          }}
          removeHandler={dateRangeRemoveHandler}
        />
      ) : null}
      {selectedSources.map((item) => (
        <SelectedFilter
          key={item.label}
          item={item}
          colorCode="filter-select-purple"
          removeHandler={searchSourcesRemoveHandler}
        />
      ))}
      {selectedTypes.map((item) => (
        <SelectedFilter
          key={item.label}
          item={item}
          colorCode="filter-select-red"
          removeHandler={searchTypesRemoveHandler}
        />
      ))}
      {selectedTags.map((item) => (
        <SelectedFilter key={item.label} item={item} removeHandler={searchTagRemoveHandler} />
      ))}
      {groupBy !== '' && groupBy !== 'custom' ? (
        <SelectedFilter
          key={groupBy}
          colorCode="filter-select-green"
          item={{
            label: textCapitalized(SIGNALS_GROUP_BY[groupBy] ? SIGNALS_GROUP_BY[groupBy] : ''),
          }}
          removeHandler={gropByRemoveHandler}
        />
      ) : null}
    </div>
  );
  return (
    <>
      <Row>
        <Button
          className={scrolled ? 'filter-scrolled filter-button d-block' : 'filter-button d-block'}
          onClick={() => setShowSignalFilterView(true)}
        >
          <span className="cmp-settings" />
        </Button>
        <Col>
          <Accordion>
            <Card>
              <Card.Header>
                <div className="acc-header-wrapper">
                  <p className="acc-heading">Filter:</p>
                  {filterSection()}
                </div>
              </Card.Header>
            </Card>
            <div>
              {showSignalFilterView ? <div className="back-drop" /> : null}
              <div
                className="add-config-form readlists-detail"
                style={{
                  transform: showSignalFilterView ? 'translateX(0)' : 'translateX(100%)',
                }}
              >
                {showSignalFilterView ? (
                  <SignalFilterView
                    selectedTags={selectedTags}
                    selectedSources={selectedSources}
                    selectedTypes={selectedTypes}
                    fromDate={fromDate}
                    toDate={toDate}
                    // mainSearchBarValue={mainSearchBarValue}
                    tagChangeHandler={tagChangeHandler}
                    sourcesChangeHandler={sourcesChangeHandler}
                    typesChangeHandler={typesChangeHandler}
                    setSelectedDateRange={setSelectedDateRange}
                    groupBy={groupBy}
                    setGroupByHandler={setGroupByHandler}
                    // remove search handlers
                    searchTagRemoveHandler={searchTagRemoveHandler}
                    // mainSearchBarValueRemoveHandler={mainSearchBarValueRemoveHandler}
                    dateRangeRemoveHandler={dateRangeRemoveHandler}
                    searchSourcesRemoveHandler={searchSourcesRemoveHandler}
                    searchTypesRemoveHandler={searchTypesRemoveHandler}
                    gropByRemoveHandler={gropByRemoveHandler}
                    // clr all handler
                    clearFilterHandler={clearFilterHandler}
                    // search filter submit handler
                    applyFilterHandler={applyFilterHandler}
                    // dropdown values
                    allTags={allTags}
                    allSources={allSources}
                    allTypes={allTypes}
                    setShowSignalFilterView={setShowSignalFilterView}
                  />
                ) : null}
              </div>
            </div>
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default SearchFilter;
