import ApiUtils from './apiUtils';

/**
 * Fetch tag list
 * @param {Object} param
 */
const fetchTag = async () => {
  try {
    const response = await ApiUtils.get(`/technologies`);
    const { data } = response;
    if (data && data.data.length > 0) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchTag,
};
