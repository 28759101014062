import React, { useEffect, useState } from 'react';
import { Col, Row, Alert, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Loading from '../../common/components/Loading';
import Error from '../../common/components/Error';
import {
  MSG_EXPIRY_TIME,
  RECORD_ADDED_SUCCESS_MESSAGE,
  SUCCESS_MESSAGE,
} from '../../common/constants/common';
import {
  setSourcesRecordUpdateStatus,
  setSourcesRecordAddStatus,
  fetchSourcesRequested,
} from '../../state/sourcesTypes/sourcesTypesActions';

import EditSources from './components/EditSources';
import AddSources from './components/AddSources';
import ImportModal from './components/ImportModal';
/**
 * Component for showing configurations
 * @returns {Component} The wrapped component.
 */
const Sources = () => {
  const [editSources, setEditSources] = useState(null);
  const [addSources, setAddSources] = useState({});

  const [filteredData, setFilteredData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const sourcesStates = useSelector((state) => state.sourcesTypes);
  const { data, isLoading, isError, isUpdateSuccess, isAddSuccess, errorMessage } =
    sourcesStates.sources;
  const dispatch = useDispatch();

  const [showImport, setShowImport] = useState(false);

  const handleShow = () => setShowImport(true);

  /**
   * Disptach API call for getting configuration data
   */
  const getData = () => {
    dispatch(fetchSourcesRequested());
  };

  /**
   * Close Edit form and get the all the configuration details
   */
  const closeEditModal = () => {
    setShowEditModal(false);
    getData();
  };

  /**
   * Close Edit form and get the all the configuration details
   */
  const closeAddModal = () => {
    setShowAddModal(false);
    getData();
  };
  /**
   * Close Edit form and get the all the configuration details
   */
  const closeImportModal = () => {
    setShowImport(false);
    getData();
  };
  /**
   * Show edit configuration model
   * @param {object} source - single source details to be edited
   */
  const editSourcesModal = (source) => {
    setShowEditModal(true);
    setEditSources(source);
  };
  const addSourcesModal = () => {
    setShowAddModal(true);
    setAddSources();
  };
  useEffect(() => {
    if (isLoading) getData();
    else if (data) {
      const datatableData = data.map((record) => ({
        id: record.id,
        value: record.title,
        url: (
          <a href={record.url} target="_blank" rel="noreferrer">
            {record.url}
          </a>
        ),
        type: record.type,
        actions: (
          <Button className="btn me-1" onClick={() => editSourcesModal(record)}>
            Edit
          </Button>
        ),
      }));
      setFilteredData(datatableData);
    }

    // close edit status successfull message
    if (isUpdateSuccess || isAddSuccess) {
      getData();
      closeEditModal();
      closeAddModal();
      closeImportModal();
      setTimeout(() => {
        dispatch(setSourcesRecordUpdateStatus(false));
        dispatch(setSourcesRecordAddStatus(false));
      }, MSG_EXPIRY_TIME);
    }
  }, [isLoading, isUpdateSuccess, isAddSuccess]);

  const [filterToggle, setFilterToggle] = useState(false);
  /**
   * Checks if the search option should be visible or not
   * @returns
   */
  const toggleFilter = () => setFilterToggle(!filterToggle);

  const SOURCES_TABLE_HEADING = [
    {
      dataField: 'id',
      text: (
        <>
          <Button className="filter-btn" onClick={toggleFilter}>
            <span className="cmp-search" />
          </Button>
          ID
        </>
      ),
      name: 'ID',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      filter: filterToggle ? textFilter({ placeholder: 'Search' }) : null,
      classes: 'col-2',
      headerClasses: 'col-2',
    },
    {
      dataField: 'value',
      text: 'Title',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: filterToggle ? textFilter({ placeholder: 'Search' }) : null,
      classes: 'col-4 url',
      headerClasses: 'col-4 url',
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      filter: filterToggle ? textFilter({ placeholder: 'Search' }) : null,
      classes: 'col-1',
      headerClasses: 'col-1',
    },
    {
      dataField: 'url',
      text: 'URL',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: filterToggle ? textFilter({ placeholder: 'Search' }) : null,
      classes: 'col-4 url',
      headerClasses: 'col-4 url',
    },
    {
      dataField: 'actions',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      classes: 'col-1',
      headerClasses: 'col-1',
    },
  ];

  return (
    <>
      <div>
        <Row>
          <Col xs={12}>
            <div>
              <div className="mb-3">
                <Button className="me-2 small-btn" onClick={() => addSourcesModal()}>
                  Add
                </Button>
                <Button className="small-btn" onClick={handleShow}>
                  Import
                </Button>
              </div>
              <Row>
                <Col md={12} xl={12} className="d-flex mb-12 mb-md-0">
                  {isUpdateSuccess ? (
                    <Alert className="mb-4" variant="success">
                      {SUCCESS_MESSAGE}
                    </Alert>
                  ) : isAddSuccess ? (
                    <Alert className="mb-4" variant="success">
                      {RECORD_ADDED_SUCCESS_MESSAGE}
                    </Alert>
                  ) : null}
                </Col>
              </Row>
              {/* SHOW SOURCES TABLE START */}
              {isLoading ? (
                <Loading />
              ) : isError ? (
                <Error msg={errorMessage} />
              ) : (
                <>
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="id"
                      data={filteredData}
                      columns={SOURCES_TABLE_HEADING}
                      filter={filterFactory()}
                      classes="compass-table"
                      filterPosition="inline"
                      bordered={false}
                    />
                  </div>
                </>
              )}

              {/* SHOW SOURCES TABLE END */}
            </div>
          </Col>
        </Row>
        {/* EDIT SOURCES STARTS */}
        {showEditModal || showAddModal || showImport ? <div className="back-drop" /> : null}
        <div
          className="add-config-form"
          style={{
            transform:
              showEditModal || showAddModal || showImport ? 'translateX(0)' : 'translateX(100%)',
          }}
        >
          {showEditModal ? (
            <EditSources show={showEditModal} closeEdit={closeEditModal} source={editSources} />
          ) : showAddModal ? (
            <AddSources show={showAddModal} closeAdd={closeAddModal} source={addSources} />
          ) : showImport ? (
            <ImportModal show={showImport} closeImport={closeImportModal} />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Sources;
