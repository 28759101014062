import React, { useEffect, useState } from 'react';
import { Col, Row, Alert, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import EditConfiguration from './components/EditConfiguration';
import Loading from '../../common/components/Loading';
import Error from '../../common/components/Error';
import {
  TIME_FORMAT,
  MSG_EXPIRY_TIME,
  DATE_TIME_FORMAT,
  SUCCESS_MESSAGE,
} from '../../common/constants/common';
import { showLimited, cronTotext } from '../../common/utils/helpers';
import {
  fetchConfigurationRequested,
  setConfigurationRecordUpdateStatus,
} from '../../state/configuration/configurationActions';

/**
 * Component for showing configurations
 * @returns {Component} The wrapped component.
 */
const Parameters = () => {
  const [editConfiguration, setEditConfiguration] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);

  const { data, isLoading, isError, isUpdateSuccess } = useSelector((state) => state.configuration);
  const dispatch = useDispatch();

  /**
   * Disptach API call for getting configuration data
   */
  const getData = () => {
    dispatch(
      fetchConfigurationRequested({
        searchTerm: '',
      })
    );
  };

  /**
   * Close Edit form and get the all the configuration details
   */
  const closeEditModal = () => {
    setShowEditModal(false);
    getData();
  };
  /**
   * Show edit configuration model
   * @param {object} configutaion - single configuration details to be edited
   */
  const editConfigurationModal = (configutaion) => {
    setShowEditModal(true);
    setEditConfiguration(configutaion);
  };
  /**
   *
   * @param {object} record - individual configuration records
   * @returns
   */
  const getValueDetails = (record) => {
    if (record.isEncrypted) {
      return '*'.repeat(10);
    }
    if (record.configType === 'cron') {
      const { err, crontext } = cronTotext(record.value, 1);
      if (!err) {
        return `Next:${moment(crontext).format(DATE_TIME_FORMAT)}`;
      }
    } else if (record.configType === 'date') {
      return record.value ? moment(record.value).format(TIME_FORMAT) : '';
    }
    return record.value;
  };

  useEffect(() => {
    if (isLoading) getData();
    else {
      const datatableData = data.map((record) => ({
        key: showLimited(record.key),
        value: getValueDetails(record),
        isSecret: record.isEncrypted ? 'Yes' : 'No',
        last_updated: record.lastUpdate,
        actions: (
          <Button className="btn me-1" onClick={() => editConfigurationModal(record)}>
            Edit
          </Button>
        ),
      }));
      setFilteredData(datatableData);
    }

    // close edit status successfull message
    if (isUpdateSuccess) {
      getData();
      closeEditModal();
      setTimeout(() => {
        dispatch(setConfigurationRecordUpdateStatus(false));
      }, MSG_EXPIRY_TIME);
    }
  }, [isLoading, isUpdateSuccess]);

  const [filterToggle, setFilterToggle] = useState(false);
  /**
   * Checks if the search option should be visible or not
   * @returns
   */
  const toggleFilter = () => setFilterToggle(!filterToggle);

  const CONFIGURATION_TABLE_HEADING = [
    {
      dataField: 'key',
      text: (
        <>
          <Button className="filter-btn" onClick={toggleFilter}>
            <span className="cmp-search" />
          </Button>
          Key
        </>
      ),
      name: 'key',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: filterToggle ? textFilter({ placeholder: 'Search' }) : null,
      classes: 'col-2',
      headerClasses: 'col-2',
    },
    {
      dataField: 'value',
      text: 'Value',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: filterToggle ? textFilter({ placeholder: 'Search' }) : null,
      classes: 'col-2 tags',
      headerClasses: 'col-2 tags',
    },
    {
      dataField: 'isSecret',
      text: 'Is Secret',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      filter: filterToggle ? textFilter({ placeholder: 'Search' }) : null,
      classes: 'col-1',
      headerClasses: 'col-1',
    },
    {
      dataField: 'last_updated',
      text: 'Last Updated',
      sort: true,
      formatter: (value) => moment(value).format(TIME_FORMAT),
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return new Date(a) - new Date(b);
        }
        return new Date(b) - new Date(a);
      },
      align: 'center',
      headerAlign: 'center',
      filter: filterToggle ? textFilter({ placeholder: 'Search' }) : null,
      classes: 'col-1',
      headerClasses: 'col-1',
    },
    {
      dataField: 'actions',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      classes: 'col-1',
      headerClasses: 'col-1',
    },
  ];

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div>
            <Row>
              <Col md={12} xl={12} className="d-flex mb-12 mb-md-0">
                {isUpdateSuccess ? (
                  <Alert className="mb-4" variant="success">
                    {SUCCESS_MESSAGE}
                  </Alert>
                ) : null}
              </Col>
            </Row>
            {/* SHOW CONFIGURATION TABLE START */}
            {isLoading ? (
              <Loading />
            ) : isError ? (
              <Error />
            ) : (
              <>
                <div className="table-responsive">
                  <BootstrapTable
                    keyField="id"
                    data={filteredData}
                    columns={CONFIGURATION_TABLE_HEADING}
                    filter={filterFactory()}
                    classes="compass-table"
                    filterPosition="inline"
                    bordered={false}
                  />
                </div>
              </>
            )}

            {/* SHOW CONFIGURATION TABLE END */}
          </div>
        </Col>
      </Row>
      {/* EDIT CONFIGURATION STARTS */}
      {showEditModal ? <div className="back-drop" /> : null}
      <div
        className="add-config-form"
        style={{
          transform: showEditModal ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        {showEditModal ? (
          <EditConfiguration
            show={showEditModal}
            closeEdit={closeEditModal}
            configuration={editConfiguration}
          />
        ) : null}
      </div>
    </div>
  );
};
export default Parameters;
