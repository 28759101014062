import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const LoginPassword = () => (
  <Container fluid>
    <Row className="">
      <Col xs={11} md={5} className="mx-auto">
        <div className="Email-login">
          <Form>
            <Form.Group className="mb-4">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" />
            </Form.Group>
            <Form.Group className="d-flex align-items-center">
              <p className="forgot-password">Forgot password?</p>
              <Button className="ms-auto">Next</Button>
            </Form.Group>
          </Form>
        </div>
      </Col>
    </Row>
  </Container>
);

export default LoginPassword;
