import React from 'react';
import { kFormatter } from '../../../common/utils/helpers';

/**
 *
 * @param {array} receivedCounters: array of object with types and signla count
 * @returns
 */
const ReceivedSignalCount = ({ receivedCounters }) => {
  const colorCodes = ['yellow', 'green', 'purple'];
  const countList = receivedCounters.map((item, index) => {
    const colorClass = `content-heading compass-${colorCodes[index] || colorCodes[0]} text-center`;
    return (
      <div className="count-box mb-3 mb-sm-0">
        <p className={colorClass}>{kFormatter(item.totalCount)}</p>
        <p className="count-text text-center">
          {item.type === 'rss' ? item.type.toUpperCase() : item.type}
        </p>
      </div>
    );
  });

  return <div className="count-wrapper">{countList}</div>;
};
export default ReceivedSignalCount;
