/**
 * Import constant types
 */
import { ReportTypes } from './reportTypes';

/**
 *
 * @function :  sourcesTypesReducers
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */
const reportReducers = (state = {}, action) => {
  switch (action.type) {
    case ReportTypes.FETCH_OVERVIEW_REQUESTED: {
      const newState = { ...state };
      newState.overview.isLoading = true;
      return newState;
    }
    case ReportTypes.FETCH_OVERVIEW_SUCCESSFULL: {
      const newState = { ...state };
      newState.overview.isLoading = false;
      newState.overview.data = { ...action.payload.data };
      return newState;
    }
    case ReportTypes.FETCH_OVERVIEW_FAILURE: {
      const newState = { ...state };
      newState.overview.isLoading = false;
      return newState;
    }

    case ReportTypes.FETCH_REPORT_TAGS_REQUESTED: {
      const newState = { ...state };
      newState.tags.isLoading = true;
      return newState;
    }
    case ReportTypes.FETCH_REPORT_TAGS_SUCCESSFULL: {
      const newState = { ...state };
      newState.tags.isLoading = false;
      newState.tags.data = action.payload.data;
      return newState;
    }
    case ReportTypes.FETCH_REPORT_TAGS_FAILURE: {
      const newState = { ...state };
      newState.tags.isLoading = false;
      return newState;
    }

    case ReportTypes.FETCH_REPORT_SIGNALS_REQUESTED: {
      const newState = { ...state };
      newState.signals.isLoading = true;
      return newState;
    }
    case ReportTypes.FETCH_REPORT_SIGNALS_SUCCESSFULL: {
      const newState = { ...state };
      newState.signals.isLoading = false;
      newState.signals.data = action.payload.data;
      return newState;
    }
    case ReportTypes.FETCH_REPORT_SIGNALS_FAILURE: {
      const newState = { ...state };
      newState.signals.isLoading = false;
      return newState;
    }

    case ReportTypes.FETCH_REPORT_SOURCES_REQUESTED: {
      const newState = { ...state };
      newState.sources.isLoading = true;
      return newState;
    }
    case ReportTypes.FETCH_REPORT_SOURCES_SUCCESSFULL: {
      const newState = { ...state };
      newState.sources.isLoading = false;
      newState.sources.data = action.payload.data;
      return newState;
    }
    case ReportTypes.FETCH_REPORT_SOURCES_FAILURE: {
      const newState = { ...state };
      newState.sources.isLoading = false;
      return newState;
    }

    case ReportTypes.FETCH_REPORT_READLISTS_REQUESTED: {
      const newState = { ...state };
      newState.readlists.isLoading = true;
      return newState;
    }
    case ReportTypes.FETCH_REPORT_READLISTS_SUCCESSFULL: {
      const newState = { ...state };
      newState.readlists.isLoading = false;
      newState.readlists.data = action.payload.data;
      return newState;
    }
    case ReportTypes.FETCH_REPORT_READLISTS_FAILURE: {
      const newState = { ...state };
      newState.readlists.isLoading = false;
      return newState;
    }
    default:
      return state;
  }
};

export default reportReducers;
