import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Loading from './Loading';

const Logout = () => {
  useEffect(() => {
    Auth.signOut().then(() => window.location.replace('/'));
  }, []);
  return (
    <div className="logout">
      <Loading />
      <p className="logout-text">Logging you out...</p>
    </div>
  );
};
export default Logout;
