import ApiUtils from './apiUtils';

/**
 * Fetch overview report
 * @param {Object} param
 */
const fetchOverview = async (param) => {
  try {
    const response = await ApiUtils.get(`report/overview?${param}`);
    const { data } = response;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: {},
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Fetch tag report
 * @param {Object} param
 */
const fetchTags = async (param) => {
  try {
    const response = await ApiUtils.get(`report/tags?${param}`);
    const { data } = response;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Fetchsignals report
 * @param {Object} param
 */
const fetchSignals = async (param) => {
  try {
    const response = await ApiUtils.get(`report/signals?${param}`);
    const { data } = response;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Fetch sources report
 * @param {Object} param
 */
const fetchSources = async (param) => {
  try {
    const response = await ApiUtils.get(`report/sources?${param}`);
    const { data } = response;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Fetch readlists report
 * @param {Object} param
 */
const fetchReadlists = async (param) => {
  try {
    const response = await ApiUtils.get(`report/readlists?${param}`);
    const { data } = response;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchOverview,
  fetchTags,
  fetchSignals,
  fetchSources,
  fetchReadlists,
};
