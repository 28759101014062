import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button, InputGroup, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Loading from '../../common/components/Loading';
import SearchFilter from './componenets/SearchFilter';
import ReceivedSignalGraph from './componenets/ReceivedSignalGraph';
import ReceivedSignalCount from './componenets/ReceivedSignalCount';
import FailedProcessingSignal from './componenets/FailedProcessingSignal';
import SignalProcessed from './componenets/SignalProcessed';
import ListView from './componenets/ListView';
import {
  fetchSignalRequested,
  fetchSignalGraphRequested,
  fetchSignalListRequested,
} from '../../state/signals/signalsActions';
import { fetchTagRequested } from '../../state/areaSubAreaTags/areaSubAreaTagsActions';
import {
  fetchSourcesRequested,
  fetchTypesRequested,
} from '../../state/sourcesTypes/sourcesTypesActions';
import { setSearchTag } from '../../state/searchTag/searchTagActions';
import SearchBar from '../../common/components/SearchBar';
import Error from '../../common/components/Error';
import RenderTooltip from '../../common/components/RenderTooltip ';
import {
  TIME_FORMAT_BACKEND,
  PAGINATION_PER_PAGE,
  OFFSET_DEFAULT_VALUE,
  DEFAULT_ORDER_BY_SIGNALS,
  SIGNALS_CUSTOM_DAYS,
  NUMBER_OF_DAYS,
  NO_DATA_FOUND_MESAGE,
  SEARCH_TOOLTIP,
  SIGNALS_FAILED_CONTAINER_TOOLTIP,
  RECEIVED_SIGNALS_GRAPH_TOOLTIP,
  SIGNAL_CARD_TOOLTIP,
  SIGNALS_PROCESSED_CONTAINER_TOOLTIP,
  SIGNALS_CONTAINER_TOOLTIP,
} from '../../common/constants/common';
import { SIGNALS_GROUP_BY_URL } from '../../common/constants/options';
import { generateParamsUrl } from '../../common/utils/helpers';
import GridView from './componenets/GridView';
import userAuth from '../../common/utils/userAuth';
import { VIEW_FAIELD_SIGNALS } from '../../common/constants/roles';

/**
 *
 * @param {object} props - url query params and browser history
 * @returns jsx to be rendered
 */
const SignalsContainer = (props) => {
  const dispatch = useDispatch();
  const { loginUserGroups } = useSelector((state) => state.auth);

  /* main search bar related code  start */

  /**
   * Get typehead selected value from url and set default value to state
   */
  const [mainSearchBarValue, setMainSearchBarValue] = useState(
    new URLSearchParams(props.location.search).get('search') || ''
  );
  const [open, setOpen] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [shouldTriggerSummaryAPI, setShouldTriggerSummaryAPI] = useState(false);

  /* this state should set and use only after clicking in appy filter */
  const [selectedTagsForFilter, setSelectedTagsForFilter] = useState(() => {
    const incommingTag = new URLSearchParams(props.location.search).get('tags');
    if (incommingTag) {
      return [
        {
          value: incommingTag,
          label: incommingTag,
        },
      ];
    }
    return [];
  });
  const [selectedFromDateForFilter, setSelectedFromDateForFilter] = useState(() => {
    if (new URLSearchParams(props.location.search).get('fromDate')) {
      return new Date(new URLSearchParams(props.location.search).get('fromDate'));
    }
    return null;
  });
  const [selectedToDateForFilter, setSelectedToDateForFilter] = useState(() => {
    if (new URLSearchParams(props.location.search).get('toDate')) {
      return new Date(new URLSearchParams(props.location.search).get('toDate'));
    }
    return null;
  });
  const [selectedSourcesForFilter, setSelectedSourcesForFilter] = useState(() => {
    const urlParamSources = new URLSearchParams(props.location.search).get('sourceFilter');
    if (urlParamSources) {
      const urlParamSourcesList = urlParamSources.split(',');
      if (urlParamSourcesList) {
        const preSelectedValues = urlParamSourcesList.map((item) => ({
          value: item,
          label: item,
        }));
        return preSelectedValues;
      }
    }
    return [];
  });
  const [selectedTypesForFilter, setSelectedTypesForFilter] = useState(() => {
    const urlParamTypes = new URLSearchParams(props.location.search).get('typeFilter');
    if (urlParamTypes) {
      const urlParamTypesList = urlParamTypes.split(',');
      if (urlParamTypesList) {
        const preSelectedValues = urlParamTypesList.map((item) => ({
          value: item,
          label: item,
        }));
        return preSelectedValues;
      }
    }
    return [];
  });

  /**
   * Get selected value from typehead and set it to state
   * If selected value is empty close the typehead
   * @param {Array} value
   */
  const mainSerchBarChangeHandler = (value) => {
    if (value.length !== 0) {
      setMainSearchBarValue(value.length !== 0 ? value[0].name : '');
    } else {
      setOpen(false);
    }
  };

  /**
   * set typehead to open on click of it
   */
  const onFocusMainSearchHandler = () => {
    setOpen(true);
  };
  /* main search bar related code  end */

  /* search form related codebase start */

  const [selectedSources, setSelectedSources] = useState(() => {
    const urlParamSources = new URLSearchParams(props.location.search).get('sourceFilter');
    if (urlParamSources) {
      const urlParamSourcesList = urlParamSources.split(',');
      if (urlParamSourcesList) {
        const preSelectedValues = urlParamSourcesList.map((item) => ({
          value: item,
          label: item,
        }));
        return preSelectedValues;
      }
    }
    return [];
  });

  const [selectedTypes, setSelectedTypes] = useState(() => {
    const urlParamTypes = new URLSearchParams(props.location.search).get('typeFilter');
    if (urlParamTypes) {
      const urlParamTypesList = urlParamTypes.split(',');
      if (urlParamTypesList) {
        const preSelectedValues = urlParamTypesList.map((item) => ({
          value: item,
          label: item,
        }));
        return preSelectedValues;
      }
    }
    return [];
  });
  // if tags available in the url then get those, convert into array of object and set tags state
  const [selectedTags, setSelectedTags] = useState(() => {
    const urlParamTags = new URLSearchParams(props.location.search).get('tags');
    const urlParamSearch = new URLSearchParams(props.location.search).get('search');
    let preSelectedValues = [];
    if (urlParamTags) {
      const urlParamTagsList = urlParamTags.split(',');
      if (urlParamTagsList) {
        preSelectedValues = urlParamTagsList.map((item) => ({
          value: item,
          label: item,
        }));
      }
    }
    if (urlParamSearch) {
      preSelectedValues.push({
        value: urlParamSearch,
        label: urlParamSearch,
      });
    }
    return preSelectedValues;
  });
  /**
   * Tags dropdown change handler
   * @param {array} selectedTags
   * On change of tags dropdown set it's value into state
   * Change area list to only show area's which are related to tags
   * Chnage subArea's list to only show tags related to tags
   */
  const tagChangeHandler = (selectedTagsList) => {
    setSelectedTags(selectedTagsList);
  };
  const [offset, setOffset] = useState(
    new URLSearchParams(props.location.search).get('offset')
      ? parseInt(new URLSearchParams(props.location.search).get('offset'), 10)
      : OFFSET_DEFAULT_VALUE
  );

  const [fromDate, setFromDate] = useState(() => {
    if (new URLSearchParams(props.location.search).get('fromDate')) {
      return new Date(new URLSearchParams(props.location.search).get('fromDate'));
    }
    return null;
  });
  const [toDate, setToDate] = useState(() => {
    if (new URLSearchParams(props.location.search).get('toDate')) {
      return new Date(new URLSearchParams(props.location.search).get('toDate'));
    }
    return null;
  });

  const [groupBy, setGroupBy] = useState(() => {
    if (new URLSearchParams(props.location.search).get('groupBy')) {
      return new URLSearchParams(props.location.search).get('isMonth')
        ? 'month'
        : new URLSearchParams(props.location.search).get('isYear')
        ? 'year'
        : new URLSearchParams(props.location.search).get('groupBy');
    }
    return '';
  });
  const [selectedSortBy, setSelectedSortBy] = useState(() => {
    if (new URLSearchParams(props.location.search).get('order')) {
      return new URLSearchParams(props.location.search).get('order');
    }
    return DEFAULT_ORDER_BY_SIGNALS;
  });
  const [selectedTitle, setSelectedTitle] = useState(
    new URLSearchParams(props.location.search).get('title') || ''
  );

  const { sources, types } = useSelector((state) => state.sourcesTypes);

  // get signals data from store
  const { listData } = useSelector((state) => state.signals);
  const { tag } = useSelector((state) => state.areaSubAreaTags);
  // set tag values from store
  const [allTags, setAllTags] = useState(tag.data);

  // set sources and types from store
  const [allSources, setAllSources] = useState(sources.data);

  const [allTypes, setAllTypes] = useState(types.data);

  /**
   * fetch call to sources list
   */
  const fetchSources = () => {
    dispatch(fetchSourcesRequested());
  };
  /**
   * fetch call to types list
   */
  const fetchTypes = () => {
    dispatch(fetchTypesRequested());
  };
  /**
   * fetch call to tags list
   */
  const fetchTags = () => {
    dispatch(fetchTagRequested());
  };

  const sourcesChangeHandler = (selectedSourcesList) => {
    // initialize with alredy selected values
    const newSelectedTypes = [...selectedTypes];
    selectedSourcesList.forEach((item) => {
      // get types which are belongs to sources
      types.data.forEach((typesItem) => {
        if (typesItem.label === item.type) {
          const isExist = newSelectedTypes.filter(
            (selectedObject) => selectedObject.label === typesItem.label
          );
          if (!isExist.length) {
            newSelectedTypes.push(typesItem);
          }
        }
      });
    });
    // if no sources selected then show all types
    setAllTypes(selectedSourcesList.length > 0 ? newSelectedTypes : types.data);

    setSelectedSources(selectedSourcesList);
  };

  const typesChangeHandler = (selectedTypesList) => {
    // initialize with alredy selected values
    const newSelectedSources = [...selectedSources];
    selectedTypesList.forEach((item) => {
      // get types which are belongs to sources
      sources.data.forEach((sourcesItem) => {
        if (sourcesItem.type === item.label) {
          const isExist = newSelectedSources.filter(
            (selectedObject) => selectedObject.label === sourcesItem.label
          );
          if (!isExist.length) {
            newSelectedSources.push(sourcesItem);
          }
        }
      });
    });
    // if no types selected then show all sources
    setAllSources(selectedTypesList.length > 0 ? newSelectedSources : sources.data);

    setSelectedTypes(selectedTypesList);
  };

  /**
   * remove main search value
   */
  const mainSearchBarValueRemoveHandler = () => {
    setMainSearchBarValue('');
    setShouldTriggerSummaryAPI(true);
    // setShouldRedirect(true);
  };
  /**
   * set date range
   */
  const setSelectedDateRange = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  const setGroupByHandler = (selectedGroupBy) => {
    setGroupBy(selectedGroupBy);
    if (selectedGroupBy !== 'custom') {
      const today = new Date();
      setToDate(today);
      if (selectedGroupBy === 'day') {
        setFromDate(moment(today).subtract(NUMBER_OF_DAYS, 'day').toDate());
      } else if (selectedGroupBy === 'month') {
        setFromDate(moment(today).subtract(1, 'month').toDate());
      } else if (selectedGroupBy === 'year') {
        setFromDate(moment(today).subtract(1, 'year').toDate());
      }
    }
  };
  /**
   * date range remove handler
   */
  const dateRangeRemoveHandler = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedFromDateForFilter(null);
    setSelectedToDateForFilter(null);
    setShouldTriggerSummaryAPI(true);
    setShouldRedirect(true);
  };

  /**
   * Remove sources by label from filter
   * @param {Integer} id
   */
  const searchSourcesRemoveHandler = (label) => {
    setSelectedSources(selectedSources.filter((sourcesItem) => !(sourcesItem.label === label)));
    setSelectedSourcesForFilter(
      selectedSources.filter((sourcesItem) => !(sourcesItem.label === label))
    );

    setShouldTriggerSummaryAPI(true);
    setAllTags(tag.data);
    setAllTypes(types.data);
    setAllSources(sources.data);
    setShouldRedirect(true);
  };

  /**
   * Remove typpes by label from filter
   * @param {Integer} id
   */
  const searchTypesRemoveHandler = (label) => {
    setSelectedTypes(selectedTypes.filter((typesItem) => !(typesItem.label === label)));
    setSelectedTypesForFilter(selectedTypes.filter((typesItem) => !(typesItem.label === label)));
    setShouldTriggerSummaryAPI(true);
    setAllTags(tag.data);
    setAllTypes(types.data);
    setAllSources(sources.data);
    setShouldRedirect(true);
  };
  /**
   * Remove tag by label from filter
   * @param {Integer} id
   */
  const searchTagRemoveHandler = (label) => {
    setSelectedTags(selectedTags.filter((tagItem) => !(tagItem.label === label)));
    setSelectedTagsForFilter(selectedTags.filter((tagItem) => !(tagItem.label === label)));
    setShouldTriggerSummaryAPI(true);
    setAllTags(tag.data);
    setAllTypes(types.data);
    setAllSources(sources.data);
    setShouldRedirect(true);
  };

  /**
   * Remove group by
   */
  const gropByRemoveHandler = () => {
    dateRangeRemoveHandler();
    setGroupBy('');
  };
  /**
   * clear all selected filters
   */
  const clearFilterHandler = () => {
    setSelectedSources([]);
    setSelectedTypes([]);
    setSelectedTagsForFilter([]);
    setSelectedTypesForFilter([]);
    setSelectedSourcesForFilter([]);
    setSelectedTags([]);
    gropByRemoveHandler();
    setOffset(OFFSET_DEFAULT_VALUE);
    setSelectedDateRange('');
    setAllTags(tag.data);
    setAllTypes(types.data);
    setAllSources(sources.data);
    dispatch(setSearchTag(null));
  };

  /**
   * Generate and return url with search parameters
   * @returns url string
   */
  function getUrlParams() {
    return generateParamsUrl({
      fromDate: selectedFromDateForFilter
        ? moment(selectedFromDateForFilter).format(TIME_FORMAT_BACKEND)
        : '',
      toDate: selectedToDateForFilter
        ? moment(selectedToDateForFilter).format(TIME_FORMAT_BACKEND)
        : '',
      tags: selectedTagsForFilter,
      sourceFilter: selectedSourcesForFilter,
      typeFilter: selectedTypesForFilter,
      offset,
      groupBy: SIGNALS_GROUP_BY_URL[groupBy],
      isMonth: groupBy === 'month' ? 1 : 0,
      isYear: groupBy === 'year' ? 1 : 0,
      limit: PAGINATION_PER_PAGE,
      order: selectedSortBy,
      secondaryFilter: selectedTitle,
    }).join('&');
  }
  /**
   * change url on updating any filter
   */

  const triggetChangeUrl = () => {
    props.history.push({
      pathname: '/signals',
      search: getUrlParams(),
    });
  };

  /**
   * trigger url change when shouldRedirect flag change
   */
  useEffect(() => {
    if (shouldRedirect) {
      triggetChangeUrl();
      setShouldRedirect(false);
    }
  }, [shouldRedirect]);

  /**
   * Update url on applying filters
   */
  const applyFilterHandler = () => {
    let newDate = '';
    if (fromDate && !toDate) {
      newDate = moment(fromDate).subtract(NUMBER_OF_DAYS, 'day').toDate();
      setToDate(fromDate);
      setFromDate(newDate);
      setSelectedFromDateForFilter(newDate);
      setSelectedToDateForFilter(fromDate);
    } else {
      setSelectedFromDateForFilter(fromDate);
      setSelectedToDateForFilter(toDate);
    }
    setSelectedTypesForFilter(selectedTypes);
    setSelectedSourcesForFilter(selectedSources);
    setSelectedTagsForFilter(selectedTags);
    setShouldTriggerSummaryAPI(true);
    setShouldRedirect(true);
    setOffset(OFFSET_DEFAULT_VALUE);
  };

  /**
   * change offset on pagination
   * @param {number} offsetCount
   */
  const setOffsetHandler = (offsetCount) => {
    setOffset(offsetCount);
    setShouldRedirect(true);
  };

  /**
   * apply secondary search to listing view
   */
  const setSecondaryFilter = (value) => {
    setSelectedTitle(value);
    if (value.length > 2) {
      const url = getUrlParams();
      const newUrl = url.replace('fromDate', '?fromDate');
      dispatch(fetchSignalListRequested(newUrl));
      setShouldRedirect(true);
    } else if (value.length === 0) {
      setShouldRedirect(true);
    }
  };
  /* search form related codebase end */

  const [toggleListAndGidView, setToggleListAndGidView] = useState(false);

  const { data, isLoading, graphData, isError } = useSelector((state) => state.signals);
  useEffect(() => {
    dispatch(fetchSignalRequested(props.location.search));
    if (shouldTriggerSummaryAPI) {
      dispatch(fetchSignalGraphRequested(props.location.search));
    }
    setShouldTriggerSummaryAPI(false);
  }, [shouldTriggerSummaryAPI]);

  useEffect(() => {
    // set typehead close initialy
    setOpen(false);

    const searchFromdate = moment(new URLSearchParams(props.location.search).get('fromDate'));
    const searchTodate = moment(new URLSearchParams(props.location.search).get('toDate'));
    const totaldateGap = searchTodate.diff(searchFromdate, 'day');
    const groupByFromUrl = new URLSearchParams(props.location.search).get('groupBy');

    const tagsFromUrl = new URLSearchParams(props.location.search).get('tags');
    if (tagsFromUrl) {
      const updatedTags = tagsFromUrl.split(',').map((item) => ({ value: item, label: item }));

      setSelectedTagsForFilter(updatedTags);
      setSelectedTags(updatedTags);
    }

    let customeGroupBy = '&groupBy=';

    if (groupByFromUrl === 'custom') {
      Object.keys(SIGNALS_CUSTOM_DAYS).forEach((range) => {
        const [minLimit, maxLimit] = range.split('-');
        if (totaldateGap === parseInt(minLimit, 10) && totaldateGap === parseInt(maxLimit, 10)) {
          customeGroupBy += SIGNALS_CUSTOM_DAYS[range];
        }
        if (totaldateGap > parseInt(minLimit, 10) && totaldateGap < parseInt(maxLimit, 10)) {
          customeGroupBy += SIGNALS_CUSTOM_DAYS[range];
        }
        if (
          parseInt(minLimit, 10) === totaldateGap ||
          (!maxLimit && parseInt(minLimit, 10) < totaldateGap)
        ) {
          if (maxLimit) {
            customeGroupBy += SIGNALS_CUSTOM_DAYS[`${minLimit}-${maxLimit}`];
          } else {
            customeGroupBy += SIGNALS_CUSTOM_DAYS[minLimit];
          }
        }
      });
    }
    const url = props.location.search.replace('&groupBy=custom', customeGroupBy);
    // dispatch listing request
    dispatch(fetchSignalListRequested(url));
    dispatch(fetchSignalGraphRequested(url));
    setShouldRedirect(true);
  }, [props.location.search]);

  // for getting sources list
  useEffect(() => {
    if (sources.isLoading && sources.data.length === 0) {
      fetchSources();
    } else {
      setAllSources(sources.data);
    }
  }, [sources.isLoading]);

  // for getting types list
  useEffect(() => {
    if (types.isLoading && types.data.length === 0) {
      fetchTypes();
    } else {
      setAllTypes(types.data);
    }
  }, [types.isLoading]);

  // for getting tags list
  useEffect(() => {
    if (tag.isLoading && tag.data.length === 0) {
      fetchTags();
    } else {
      setAllTags(tag.data);
    }
  }, [tag.isLoading]);
  const sortedTags = _.orderBy(allTags, 'id');
  const sortedSources = _.orderBy(allSources, 'id');
  const sortedTypes = _.orderBy(allTypes, 'id');

  return (
    <div className="page-wrapper">
      <Container fluid>
        <Row className="page-search-bar">
          <Col>
            <Form>
              <InputGroup className="mb-2 search-input">
                <InputGroup.Text>
                  <span className="cmp-search" />
                </InputGroup.Text>
                <SearchBar
                  mainSerchBarChangeHandler={mainSerchBarChangeHandler}
                  open={open}
                  onFocusMainSearchHandler={onFocusMainSearchHandler}
                  mainSearchBarValue={mainSearchBarValue}
                  setOpen={setOpen}
                  getUrlParams={getUrlParams}
                />
              </InputGroup>
            </Form>
          </Col>
        </Row>

        <Row className="heading-wrapper">
          <Col xs={12}>
            <h1 className="heading-one mb-4">
              Signals
              <RenderTooltip toolTipMsg={SIGNALS_CONTAINER_TOOLTIP} />
            </h1>
          </Col>
        </Row>
        {/* Accordion STARTS HERE */}
        <SearchFilter
          // local states
          selectedTags={selectedTags}
          selectedSources={selectedSources}
          selectedTypes={selectedTypes}
          fromDate={fromDate}
          toDate={toDate}
          mainSearchBarValue={mainSearchBarValue}
          tagChangeHandler={tagChangeHandler}
          sourcesChangeHandler={sourcesChangeHandler}
          typesChangeHandler={typesChangeHandler}
          setSelectedDateRange={setSelectedDateRange}
          groupBy={groupBy}
          setGroupByHandler={setGroupByHandler}
          // remove search handlers
          searchTagRemoveHandler={searchTagRemoveHandler}
          mainSearchBarValueRemoveHandler={mainSearchBarValueRemoveHandler}
          dateRangeRemoveHandler={dateRangeRemoveHandler}
          searchSourcesRemoveHandler={searchSourcesRemoveHandler}
          searchTypesRemoveHandler={searchTypesRemoveHandler}
          gropByRemoveHandler={gropByRemoveHandler}
          // clr all handler
          clearFilterHandler={clearFilterHandler}
          // search filter submit handler
          applyFilterHandler={applyFilterHandler}
          // dropdown values
          allTags={sortedTags}
          allSources={sortedSources}
          allTypes={sortedTypes}
          // isShowSelectedFilter={isShowSelectedFilter}
        />
        {/* Accordion ENDS HERE */}
        <>
          <Row>
            <Col xs={12}>
              <div className="signals-graph-wrapper">
                <p className="tooltip-subhead">
                  Received
                  <RenderTooltip toolTipMsg={RECEIVED_SIGNALS_GRAPH_TOOLTIP} />
                </p>
                {graphData.isLoading ? (
                  <Loading />
                ) : graphData.isError && !selectedFromDateForFilter ? (
                  <p className="filter-suggestion">Please select date range</p>
                ) : graphData.data.length <= 0 ? (
                  <div className="no-data-found">
                    <p>{NO_DATA_FOUND_MESAGE} </p>
                  </div>
                ) : (
                  <ReceivedSignalGraph graphData={graphData.data} groupBy={groupBy} />
                )}
              </div>
            </Col>
            <Col lg={6} xl={4}>
              <div className="count">
                <p className="tooltip-subhead">
                  Types
                  <RenderTooltip toolTipMsg={SIGNAL_CARD_TOOLTIP} />
                </p>
                {isLoading ? (
                  <Loading />
                ) : isError ? (
                  <Error />
                ) : (
                  <ReceivedSignalCount receivedCounters={data ? data.details : []} />
                )}
              </div>
            </Col>
            {userAuth.can(VIEW_FAIELD_SIGNALS, loginUserGroups) ? (
              <Col md={6} xl={4}>
                <div className="count">
                  <p className="tooltip-subhead">
                    <Link to="/configuration?tabName=Signals">Failed Processing</Link>
                    <RenderTooltip toolTipMsg={SIGNALS_FAILED_CONTAINER_TOOLTIP} />
                  </p>
                  {isLoading ? (
                    <Loading />
                  ) : isError ? (
                    <Error />
                  ) : (
                    <FailedProcessingSignal failedProcessCount={data ? data.failed : 0} />
                  )}
                </div>
              </Col>
            ) : null}

            <Col md={6} xl={4}>
              <div className="count">
                <p className="tooltip-subhead">
                  Signals Processed
                  <RenderTooltip toolTipMsg={SIGNALS_PROCESSED_CONTAINER_TOOLTIP} />
                </p>
                {isLoading ? (
                  <Loading />
                ) : isError ? (
                  <Error />
                ) : (
                  <SignalProcessed signalProcessedCount={data ? data.success : 0} />
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="mt-4">
              <div>
                <Row className="mb-4 align-items-end">
                  <Col md={12} lg={10}>
                    <div className="compass-form filter">
                      <Row className="grid-list-search align-items-end">
                        <Col xs={12} sm="auto" className="mb-3 mb-lg-0">
                          <Form.Label>Sort </Form.Label>
                          <div className="select">
                            <Form.Control
                              as="select"
                              size="sm"
                              value={selectedSortBy}
                              onChange={(event) => {
                                setSelectedSortBy(event.target.value);
                                setShouldRedirect(true);
                                setOffset(OFFSET_DEFAULT_VALUE);
                              }}
                            >
                              <option value="createdAt,ASC">Date (Ascending)</option>
                              <option value="createdAt,DESC">Date (Descending)</option>
                            </Form.Control>
                          </div>
                        </Col>
                        <Col sm={6} lg={5} xl={4} xxl={3} className="mb-3 mb-lg-0">
                          <Form.Label className="form-label">Search</Form.Label>
                          <div className="input-tooltip-wrap">
                            <InputGroup>
                              <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={selectedTitle}
                                onChange={(event) => {
                                  setSecondaryFilter(event.target.value);
                                }}
                                className="mb-2 mb-sm-0"
                              />
                            </InputGroup>
                            <RenderTooltip toolTipMsg={SEARCH_TOOLTIP} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {listData.data.length > 0 ? (
                    <Col className="d-flex justify-content-end align-items-center">
                      <Button className="grid-icon" onClick={() => setToggleListAndGidView(false)}>
                        <span
                          className={
                            toggleListAndGidView ? 'cmp-grid-icon' : 'cmp-grid-icon active'
                          }
                        />
                      </Button>
                      <span className="divider" />
                      <Button className="list-icon" onClick={() => setToggleListAndGidView(true)}>
                        <span className={toggleListAndGidView ? 'active cmp-list' : 'cmp-list'} />
                      </Button>
                    </Col>
                  ) : null}
                </Row>
                {listData.data.length > 0 ? (
                  <div className="grid-list-block">
                    {listData.isLoading ? (
                      <Loading />
                    ) : listData.isError ? (
                      <Error />
                    ) : listData.data.length <= 0 ? (
                      <div className="no-data-found">
                        <p>{NO_DATA_FOUND_MESAGE}</p>
                      </div>
                    ) : toggleListAndGidView ? (
                      <ListView
                        signalsListData={listData}
                        setOffsetHandler={setOffsetHandler}
                        offset={offset}
                        summaryData={data}
                        selectedTitle={selectedTitle}
                      />
                    ) : (
                      <GridView
                        signalsListData={listData}
                        setOffsetHandler={setOffsetHandler}
                        offset={offset}
                        summaryData={data}
                        selectedTitle={selectedTitle}
                        tagChangeHandler={tagChangeHandler}
                        selectedTags={selectedTags}
                      />
                    )}
                  </div>
                ) : (
                  <div className="no-data-found">
                    <p>{NO_DATA_FOUND_MESAGE}</p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </>
      </Container>
    </div>
  );
};
export default SignalsContainer;
