import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const ConfirmPassword = () => (
  <Container fluid>
    <Row className="">
      <Col xs={11} md={5} className="mx-auto">
        <div className="Email-login">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type="password" />
            </Form.Group>
            <Form.Group className="d-flex">
              <Button className="ms-auto">Next</Button>
            </Form.Group>
          </Form>
        </div>
      </Col>
    </Row>
  </Container>
);

export default ConfirmPassword;
