import { DashBoardActionTypes } from './dashboardTypes';

export const fetchDashBoardRequested = (query) => ({
  type: DashBoardActionTypes.FETCH_DASHBOARD_REQUESTED,
  payload: query,
});

export const fetchDashBoardSuccessfull = (configuration) => ({
  type: DashBoardActionTypes.FETCH_DASHBOARD_SUCCESSFULL,
  payload: configuration,
});

export const fetchDashBoardFailure = (error) => ({
  type: DashBoardActionTypes.FETCH_DASHBOARD_FAILURE,
  payload: error,
});
