import React from 'react';
import { kFormatter } from '../../../common/utils/helpers';

/**
 *
 * @param {number} readListCount:count to display on card for total signals
 * @returns JSX with the signal count
 */
const SignalProcessed = ({ signalProcessedCount }) => (
  <>
    <div className="count-wrapper failed-processing">
      <div className="count-box">
        <p className="content-heading text-end compass-green">{kFormatter(signalProcessedCount)}</p>
        <p className="count-text">Total</p>
      </div>
    </div>
  </>
);

export default SignalProcessed;
