import React from 'react';
import { Button } from 'react-bootstrap';

/**
 * Display selected filters
 * @param {*} param0 props from SearchFilter component
 * @returns {Button} - with value seleted as part of filter from SearchFilter
 */

const SelectedFilter = ({ item, removeHandler, colorCode }) => (
  <div className="filter-btn-box" key={item.label}>
    <Button className={`filter-btn ${colorCode}`}>
      <span
        role="link"
        tabIndex={0}
        className="filter-close"
        onClick={() => removeHandler(item.label)}
      >
        <span className="cmp-close" />
      </span>
      {item.value ? item.value : item.label}
    </Button>
  </div>
);

export default SelectedFilter;
