import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import _ from 'lodash';
import { putTagsRequested } from '../../../state/tags/tagsActions';
import Error from '../../../common/components/Error';
import Validation from '../../../common/utils/validation';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { TIME_FORMAT } from '../../../common/constants/common';
import { fetchTagRequested } from '../../../state/areaSubAreaTags/areaSubAreaTagsActions';
import MultiSelect from '../../../common/components/MultiSelect';

const EditTag = ({ close, editObj }) => {
  const dispatch = useDispatch();
  const { isError, isLoading } = useSelector((state) => state.tags);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [istagsTouch, setIstagsTouch] = useState(false);
  const signalDate = moment(editObj.date).format(TIME_FORMAT);
  const [tagsValidationError, setTagsValidationError] = useState('');
  const validate = () => {
    // validate description
    if (Validation.checkIsEmpty(selectedTags)) {
      setTagsValidationError('');
      setIsDisabledSubmit(false);
      return true;
    }
    setTagsValidationError('Tags can not be empty');
    setIsDisabledSubmit(true);
    return false;
  };

  // tag data from redux store
  const { tag } = useSelector((state) => state.areaSubAreaTags);

  /**
   * fetch call to tags list
   */
  const fetchTags = () => {
    dispatch(fetchTagRequested());
  };
  const tagChangeHandler = (selectedTagsList) => {
    setSelectedTags(selectedTagsList);
    setIstagsTouch(true);
  };

  // for getting tags list
  useEffect(() => {
    if (tag.isLoading && tag.data.length === 0) {
      fetchTags();
    }
  }, [tag.isLoading]);
  const tags1 = _.orderBy(tag.data, 'id');

  /**
   * Edit tags form handler
   * trigger put tags request
   * @param {event} event
   */
  const editTagHandler = (event) => {
    const tagsArray = _.map(selectedTags, 'value');
    const tagsString = tagsArray.join(',');

    event.preventDefault();

    if (validate()) {
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure want to submit?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              dispatch(
                putTagsRequested({
                  signalId: editObj.signalId,
                  data: {
                    signalId: editObj.signalId,
                    url: editObj.url,
                    signalType: editObj.signalType,
                    source: editObj.source,
                    tags: tagsString,
                  },
                })
              );
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
  };
  useEffect(() => {
    if (istagsTouch) {
      validate();
    }
  }, [selectedTags, istagsTouch]);

  return (
    <>
      <div className="modal-head-wrapper">
        <h2 className="modal-heading">Add Tags</h2>
        <Button onClick={close} className="transparent-btn close-btn">
          <span className="cmp-close" />
        </Button>
      </div>
      {isError ? <Error /> : null}
      <div className="form-wrapper">
        <Row className="justify-content-start h-100">
          <Col xs={12} md={10} className="offset-0 offset-md-2">
            <Form className="compass-form edit-tag" onSubmit={editTagHandler}>
              <Row className="justify-content-start">
                <Col md={10}>
                  <Form.Group className="mb-3">
                    <Form.Label>Signal Title:</Form.Label>
                    <span className="form-label"> {editObj.title}</span>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-start">
                <Col md={10}>
                  <Form.Group className="mb-3">
                    <Form.Label>Signal Type:</Form.Label>
                    <span className="form-label"> {editObj.signalType}</span>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-start">
                <Col md={10}>
                  <Form.Group className="mb-3">
                    <Form.Label>Source:</Form.Label>
                    <span className="form-label"> {editObj.source}</span>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-start">
                <Col md={10}>
                  <Form.Group className="mb-3">
                    <Form.Label>Signal URL:</Form.Label>
                    <span className="form-label">
                      <a href={editObj.url} target="_blank" rel="noreferrer">
                        {' '}
                        {editObj.url}
                      </a>
                    </span>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-start">
                <Col md={10}>
                  <Form.Group className="mb-3">
                    <Form.Label>Signal Date:</Form.Label>
                    <span className="form-label"> {signalDate}</span>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-start">
                <Col md={8}>
                  <Form.Group className="mb-3">
                    <Form.Label>Tags:</Form.Label>
                    <div className="failed-tag-list">
                      {selectedTags
                        ? selectedTags.map((item) => (
                            <p className="grey-btn">
                              <span className="badge">{item.value}</span>
                            </p>
                          ))
                        : null}
                    </div>
                    <div className="select">
                      <div className="multiselect">
                        <MultiSelect
                          options={tags1}
                          value={selectedTags}
                          onChangeHandler={tagChangeHandler}
                        />
                      </div>
                    </div>
                    <p className="compass-red mt-2">{tagsValidationError}</p>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex justify-content-end submit-btn">
                <Button
                  className={isLoading ? 'button-loader' : ''}
                  type="submit"
                  disabled={isDisabledSubmit}
                >
                  Save
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditTag;
