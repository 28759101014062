import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import readListApi from '../../../api/readListApi';

/**
 *
 * @returns JSX Element to download readlsit content
 */
const Download = ({ categoryId, readListName, date, setIsError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const downloadHandler = () => {
    setIsLoading(true);
    setIsError(false);
    readListApi
      .readListDownload(categoryId, readListName, date)
      .then((response) => {
        setIsLoading(false);
        window.open(response.response);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  };
  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          className={isLoading ? 'download-btn button-loader' : 'download-btn'}
          onClick={downloadHandler}
        >
          Download
        </Button>
      </div>
    </>
  );
};

export default Download;
