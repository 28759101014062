import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = () => (
  <div className="loading">
    <Spinner animation="border" role="status" variant="primary">
      <span className="visually-hidden" />
    </Spinner>
    <span className="loading-text">Loading...</span>
  </div>
);
export default Loading;
