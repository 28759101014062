import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row, Form, InputGroup } from 'react-bootstrap';
import DashboardCard from './components/DashboardCard';
import { fetchDashBoardRequested } from '../../state/dashboard/dashboardActions';
import Loading from '../../common/components/Loading';
import Error from '../../common/components/Error';
import SearchBar from '../../common/components/SearchBar';
import { generateParamsUrl } from '../../common/utils/helpers';
import { setSearchTag } from '../../state/searchTag/searchTagActions';

/**
 * Container for showing Dashboard Components
 * @returns The wapped component
 *
 */
const DashboardContainer = () => {
  const [mainSearchBarValue, setMainSearchBarValue] = useState([]);
  const [open, setOpen] = useState(false);

  const { data, isLoading, isError } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDashBoardRequested());
    dispatch(setSearchTag(null));
  }, []);

  const mainSerchBarChangeHandler = (value) => {
    setMainSearchBarValue(value.length !== 0 ? value[0].name : '');
  };
  const onFocusMainSearchHandler = () => {
    setOpen(true);
  };

  /**
   * Generate and return url with search parameters
   * @returns url string
   */
  function getUrlParams() {
    return generateParamsUrl({
      search: mainSearchBarValue,
    }).join('&');
  }

  /**
   * The render function
   */
  return (
    <div>
      <div className="main">
        <Container fluid>
          <Row>
            <Col>
              <Form>
                <InputGroup className="mb-2 search-input">
                  <InputGroup.Text>
                    <span className="cmp-search" />
                  </InputGroup.Text>
                  <SearchBar
                    mainSerchBarChangeHandler={mainSerchBarChangeHandler}
                    open={open}
                    setOpen={setOpen}
                    mainSearchBarValue={mainSearchBarValue}
                    onFocusMainSearchHandler={onFocusMainSearchHandler}
                    getUrlParams={getUrlParams}
                  />
                </InputGroup>
              </Form>
            </Col>
          </Row>
          {isError ? <Error /> : isLoading ? <Loading /> : <DashboardCard dashBoardData={data} />}
        </Container>
      </div>
    </div>
  );
};
export default DashboardContainer;
