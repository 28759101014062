import jwtDecode from 'jwt-decode';
import awsCronParser from 'aws-cron-parser';
import moment from 'moment';

/**
 * Helper method to validate  user token
 * @param {*} token
 * @returns {boolean}
 */
export const validateToken = (token) => {
  if (!token) {
    return false;
  }
  try {
    const decodedJwt = jwtDecode(token);
    return decodedJwt.exp >= Date.now() / 1000;
  } catch (e) {
    return false;
  }
};
export const showLimited = (str) => {
  if (!str) return '-';
  return str.length > 20 ? `${str.substring(0, 35)}...` : str;
};

export const generateParamsUrl = (params) => {
  const urlData = [];

  Object.keys(params).forEach((key) => {
    if (params[key] && params[key].constructor === Array) {
      if (params[key].length > 0) {
        urlData.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(
            params[key]
              .map(({ value }) => `${value}`)
              .join(',')
              .toString()
          )}`
        );
      }
    } else if (params[key] && params[key] !== '') {
      urlData.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key].toString())}`);
    }
  });
  return urlData;
};

export const textCapitalized = (text) => text.charAt(0).toUpperCase() + text.slice(1);

export const cronTotext = (value, shouldRepace = 0) => {
  try {
    let cronFormat = '';
    if (shouldRepace) {
      cronFormat = value.trim().replace('cron(', '').replace(')', '');
    } else {
      cronFormat = value;
    }

    const cron = awsCronParser.parse(cronFormat);
    const fromDate = awsCronParser.prev(cron, new Date());
    const toDate = awsCronParser.next(cron, fromDate);
    return { err: false, crontext: toDate.toString() };
  } catch (err) {
    return { err: true, crontext: '' };
  }
};

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
    : Math.sign(num) * Math.abs(num);
}

/**
 *
 * @param {number} screenResolution
 * @returns {number}
 */
export const intervalByWidth = (screenResolution) => {
  let INTERVAL_BY_WIDTH = 0;
  if (screenResolution > 768) {
    INTERVAL_BY_WIDTH = 4;
  } else if (screenResolution > 576) {
    INTERVAL_BY_WIDTH = 7;
  } else {
    INTERVAL_BY_WIDTH = 12;
  }
  return INTERVAL_BY_WIDTH;
};

export const dateConversion = (date, format) => moment(date).format(format);
