/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_TAG_REQUESTED,
  FETCH_TAG_SUCCESSFULL,
  FETCH_TAG_FAILURE,
} from '../../common/constants/actions';

/**
 * Export the types for reducers, actions and sagas
 */
export const AreaSuAreaTagsActionTypes = {
  FETCH_TAG_REQUESTED,
  FETCH_TAG_SUCCESSFULL,
  FETCH_TAG_FAILURE,
};
