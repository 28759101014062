import React, { useState, useEffect } from 'react';
import { Col, Row, Accordion, Button, Card } from 'react-bootstrap';
import moment from 'moment';
import SelectedFilter from './SelectedFilter';
import 'react-datepicker/dist/react-datepicker.css';
import { TIME_FORMAT } from '../../../common/constants/common';
import { textCapitalized } from '../../../common/utils/helpers';
import { READLISTS_GROUP_BY } from '../../../common/constants/options';
import ReadListFilterView from './ReadListFilterView';
/**
 *
 * @param {object} props -  from main component
 * @returns search filter component
 */
const SearchFilter = (props) => {
  const {
    // selected values
    selectedTags,
    // selectedDateRange,
    fromDate,
    toDate,
    // change hadlers
    setSelectedDateRange,
    tagChangeHandler,
    groupBy,
    setGroupByHandler,
    // remove handlers
    dateRangeRemoveHandler,
    gropByRemoveHandler,
    searchTagRemoveHandler,
    // clear all filters
    clearFilterHandler,
    // apply filter handler
    applyFilterHandler,
    // all data
    allTags,
    // isShowSelectedFilter,
  } = props;

  const [showReadListFilterView, setShowReadListFilterView] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = !!(window.scrollY > 100);
    setScrolled(offset);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const filterSection = () => (
    <div className="btn-box">
      {fromDate && toDate !== '' && groupBy === 'custom' ? (
        <SelectedFilter
          key={fromDate}
          colorCode="filter-select-green"
          item={{
            label: `${fromDate ? moment(fromDate).format(TIME_FORMAT) : ''} - ${
              toDate ? moment(toDate).format(TIME_FORMAT) : ''
            }`,
          }}
          removeHandler={dateRangeRemoveHandler}
        />
      ) : null}
      {selectedTags.map((item) => (
        <SelectedFilter key={item.label} item={item} removeHandler={searchTagRemoveHandler} />
      ))}
      {groupBy !== '' && groupBy !== 'custom' ? (
        <SelectedFilter
          key={groupBy}
          colorCode="filter-select-green"
          item={{
            label: textCapitalized(READLISTS_GROUP_BY[groupBy] ? READLISTS_GROUP_BY[groupBy] : ''),
          }}
          removeHandler={gropByRemoveHandler}
        />
      ) : null}
    </div>
  );
  return (
    <Row>
      <Button
        className={scrolled ? 'filter-scrolled filter-button d-block' : 'filter-button d-block'}
        onClick={() => setShowReadListFilterView(true)}
      >
        <span className="cmp-settings" />
      </Button>
      <Col>
        <Accordion>
          <Card>
            <Card.Header>
              <div className="acc-header-wrapper">
                <p className="acc-heading">Filter:</p>
                {filterSection()}
              </div>
            </Card.Header>
          </Card>
          <div>
            {showReadListFilterView ? <div className="back-drop" /> : null}
            <div
              className="add-config-form readlists-detail"
              style={{
                transform: showReadListFilterView ? 'translateX(0)' : 'translateX(100%)',
              }}
            >
              {showReadListFilterView ? (
                <ReadListFilterView
                  selectedTags={selectedTags}
                  fromDate={fromDate}
                  toDate={toDate}
                  // mainSearchBarValue={mainSearchBarValue}
                  tagChangeHandler={tagChangeHandler}
                  setSelectedDateRange={setSelectedDateRange}
                  groupBy={groupBy}
                  setGroupByHandler={setGroupByHandler}
                  // remove search handlers
                  searchTagRemoveHandler={searchTagRemoveHandler}
                  // mainSearchBarValueRemoveHandler={mainSearchBarValueRemoveHandler}
                  dateRangeRemoveHandler={dateRangeRemoveHandler}
                  gropByRemoveHandler={gropByRemoveHandler}
                  // clr all handler
                  clearFilterHandler={clearFilterHandler}
                  // search filter submit handler
                  applyFilterHandler={applyFilterHandler}
                  // dropdown values
                  allTags={allTags}
                  setShowReadListFilterView={setShowReadListFilterView}
                />
              ) : null}
            </div>
          </div>
        </Accordion>
      </Col>
    </Row>
  );
};

export default SearchFilter;
