// Actions for configuration
// Fetch configuration
export const FETCH_CONFIGURATION_REQUESTED = 'FETCH_CONFIGURATION_REQUESTED';
export const FETCH_CONFIGURATION_SUCCESSFULL = 'FETCH_CONFIGURATION_SUCCESSFULL';
export const FETCH_CONFIGURATION_FAILURE = 'FETCH_CONFIGURATION_FAILURE';

// update configuration
export const PUT_CONFIGURATION_REQUESTED = 'PUT_CONFIGURATION_REQUESTED';
export const PUT_CONFIGURATION_SUCCESSFULL = 'PUT_CONFIGURATION_SUCCESSFULL';
export const PUT_CONFIGURATION_FAILURE = 'PUT_CONFIGURATION_FAILURE';
export const CONFIGURATION_RECORD_UPDATE_SUCCESS = 'CONFIGURATION_RECORD_UPDATE_SUCCESS';

// Actions for trends
// Fetch trends
export const FETCH_TRENDS_REQUESTED = 'FETCH_TRENDS_REQUESTED';
export const FETCH_TRENDS_SUCCESSFULL = 'FETCH_TRENDS_SUCCESSFULL';
export const FETCH_TRENDS_FAILURE = 'FETCH_TRENDS_FAILURE';

// Actions for trends
// Fetch USER
export const FETCH_USER_REQUESTED = 'FETCH_USER_REQUESTED';
export const FETCH_USER_SUCCESSFULL = 'FETCH_USER_SUCCESSFULL';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const PUT_USER_REQUESTED = 'PUT_USER_REQUESTED';
export const PUT_USER_SUCCESSFULL = 'PUT_USER_SUCCESSFULL';
export const PUT_USER_FAILURE = 'PUT_USER_FAILURE';
export const USER_RECORD_UPDATE_SUCCESS = 'USER_RECORD_UPDATE_SUCCESS';

// action for signal
// fetch signal
export const FETCH_SIGNALS_REQUESTED = 'FETCH_SIGNALS_REQUESTED';
export const FETCH_SIGNALS_SUCCESSFULL = 'FETCH_SIGNALS_SUCCESSFULL';
export const FETCH_SIGNALS_FAILURE = 'FETCH_SIGNALS_FAILURE';
// fetch signal graph data
export const FETCH_SIGNALS_GRAPH_REQUESTED = 'FETCH_SIGNALS_GRAPH_REQUESTED';
export const FETCH_SIGNALS_GRAPH_SUCCESSFULL = 'FETCH_SIGNALS_GRAPH_SUCCESSFULL';
export const FETCH_SIGNALS_GRAPH_FAILURE = 'FETCH_SIGNALS_GRAPH_FAILURE';
// fetch signal listing data
export const FETCH_SIGNALS_LIST_REQUESTED = 'FETCH_SIGNALS_LIST_REQUESTED';
export const FETCH_SIGNALS_LIST_SUCCESSFULL = 'FETCH_SIGNALS_LIST_SUCCESSFULL';
export const FETCH_SIGNALS_LIST_FAILURE = 'FETCH_SIGNALS_LIST_FAILURE';

// Actions for tags
// Fetch TAGS
export const FETCH_TAGS_REQUESTED = 'FETCH_TAGS_REQUESTED';
export const FETCH_TAGS_SUCCESSFULL = 'FETCH_TAGS_SUCCESSFULL';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';
// update tags
export const PUT_TAGS_REQUESTED = 'PUT_TAGS_REQUESTED';
export const PUT_TAGS_SUCCESSFULL = 'PUT_TAGS_SUCCESSFULL';
export const PUT_TAGS_FAILURE = 'PUT_TAGS_FAILURE';
export const TAGS_RECORD_UPDATE_SUCCESS = 'TAGS_RECORD_UPDATE_SUCCESS';

// fetch tag
export const FETCH_TAG_REQUESTED = 'FETCH_TAG_REQUESTED';
export const FETCH_TAG_SUCCESSFULL = 'FETCH_TAG_SUCCESSFULL';
export const FETCH_TAG_FAILURE = 'FETCH_TAG_FAILURE';

// action for readList
// fetch readlist summery
export const FETCH_READLIST_SUMMARY_REQUESTED = 'FETCH_READLIST_SUMMARY_REQUESTED';
export const FETCH_READLIST_SUMMARY_SUCCESSFULL = 'FETCH_READLIST_SUMMARY_SUCCESSFULL';
export const FETCH_READLIST_SUMMARY_FAILURE = 'FETCH_READLIST_SUMMARY_FAILURE';
// fetch readlist listing
export const FETCH_READLIST_LISTING_REQUESTED = 'FETCH_READLIST_LISTING_REQUESTED';
export const FETCH_READLIST_LISTING_SUCCESSFULL = 'FETCH_READLIST_LISTING_SUCCESSFULL';
export const FETCH_READLIST_LISTING_FAILURE = 'FETCH_READLIST_LISTING_FAILURE';

// action for dashboard
// fetch dashboard
export const FETCH_DASHBOARD_REQUESTED = 'FETCH_DASHBOARD_REQUESTED';
export const FETCH_DASHBOARD_SUCCESSFULL = 'FETCH_DASHBOARD_SUCCESSFULL';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';

// Actions for typehead
// Fetch tyhead
export const FETCH_TYPEHEAD_REQUESTED = 'FETCH_TYPEHEAD_REQUESTED';
export const FETCH_TYPEHEAD_SUCCESSFULL = 'FETCH_TYPEHEAD_SUCCESSFULL';
export const FETCH_TYPEHEAD_FAILURE = 'FETCH_TYPEHEAD_FAILURE';

// Actions for SOURCES
// Fetch sources
export const FETCH_SOURCES_REQUESTED = 'FETCH_SOURCES_REQUESTED';
export const FETCH_SOURCES_SUCCESSFULL = 'FETCH_SOURCES_SUCCESSFULL';
export const FETCH_SOURCES_FAILURE = 'FETCH_SOURCES_FAILURE';

// Actions for TYPES
// Fetch types
export const FETCH_TYPES_REQUESTED = 'FETCH_TYPES_REQUESTED';
export const FETCH_TYPES_SUCCESSFULL = 'FETCH_TYPES_SUCCESSFULL';
export const FETCH_TYPES_FAILURE = 'FETCH_TYPES_FAILURE';

// Action to retain search tag
export const SET_SEARCH_TAG = 'SET_SEARCH_TAG';

// action to set login user details into store
export const SET_LOGIN_USER_GROUP = 'SET_LOGIN_USER_GROUP';

export const PUT_SOURCES_FAILURE = 'PUT_SOURCES_FAILURE';
export const PUT_SOURCES_SUCCESSFULL = 'PUT_SOURCES_SUCCESSFULL';
export const PUT_SOURCES_REQUESTED = 'PUT_SOURCES_REQUESTED';
export const SOURCES_RECORD_UPDATE_SUCCESS = 'SOURCES_RECORD_UPDATE_SUCCESS';
export const SOURCES_RECORD_ADD_SUCCESS = 'SOURCES_RECORD_ADD_SUCCESS';

export const POST_SOURCES_FAILURE = 'POST_SOURCES_FAILURE';
export const POST_SOURCES_SUCCESSFULL = 'POST_SOURCES_SUCCESSFULL';
export const POST_SOURCES_REQUESTED = 'POST_SOURCES_REQUESTED';

// Actions to fetch overview data for report
export const FETCH_OVERVIEW_REQUESTED = 'FETCH_OVERVIEW_REQUESTED';
export const FETCH_OVERVIEW_SUCCESSFULL = 'FETCH_OVERVIEW_SUCCESSFULL';
export const FETCH_OVERVIEW_FAILURE = 'FETCH_OVERVIEW_FAILURE';

// Action to fetch tags for report
export const FETCH_REPORT_TAGS_REQUESTED = 'FETCH_REPORT_TAGS_REQUESTED';
export const FETCH_REPORT_TAGS_SUCCESSFULL = 'FETCH_REPORT_TAGS_SUCCESSFULL';
export const FETCH_REPORT_TAGS_FAILURE = 'FETCH_REPORT_TAGS_FAILURE';

// Action to fetch sources for report
export const FETCH_REPORT_SOURCES_REQUESTED = 'FETCH_REPORT_SOURCES_REQUESTED';
export const FETCH_REPORT_SOURCES_SUCCESSFULL = 'FETCH_REPORT_SOURCES_SUCCESSFULL';
export const FETCH_REPORT_SOURCES_FAILURE = 'FETCH_REPORT_SOURCES_FAILURE';

// Action to fetch signals for report

export const FETCH_REPORT_SIGNALS_REQUESTED = 'FETCH_REPORT_SIGNALS_REQUESTED';
export const FETCH_REPORT_SIGNALS_SUCCESSFULL = 'FETCH_REPORT_SIGNALS_SUCCESSFULL';
export const FETCH_REPORT_SIGNALS_FAILURE = 'FETCH_REPORT_SIGNALS_FAILURE';

// Actions fro report readlist

export const FETCH_REPORT_READLISTS_REQUESTED = 'FETCH_REPORT_READLISTS_REQUESTED';
export const FETCH_REPORT_READLISTS_SUCCESSFULL = 'FETCH_REPORT_READLISTS_SUCCESSFULL';
export const FETCH_REPORT_READLISTS_FAILURE = 'FETCH_REPORT_READLISTS_FAILURE';
