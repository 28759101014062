/**
 * Import constant types
 */
import { SourcesTypesTypes } from './sourcesTypesTypes';

/**
 *
 * @function :  sourcesTypesReducers
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */
const sourcesTypesReducers = (state = {}, action) => {
  switch (action.type) {
    case SourcesTypesTypes.FETCH_SOURCES_REQUESTED: {
      const newState = { ...state };
      newState.sources.isLoading = true;
      return newState;
    }

    case SourcesTypesTypes.FETCH_SOURCES_SUCCESSFULL: {
      const data = action.payload.map((record) => ({
        id: record.id,
        title: record.title,
        label: record.title,
        value: record.title,
        type: record.type,
        url: record.url,
        updatedAt: record.updatedAt,
      }));
      const newState = { ...state };
      newState.sources.isLoading = false;
      newState.sources.data = data;
      return newState;
    }

    case SourcesTypesTypes.FETCH_SOURCES_FAILURE: {
      const newState = { ...state };
      newState.sources.isLoading = false;
      newState.sources.isError = true;
      newState.sources.errorMessage = action.payload.errorMessage;

      return newState;
    }
    case SourcesTypesTypes.FETCH_TYPES_REQUESTED: {
      const newState = { ...state };
      newState.types.isLoading = true;
      return newState;
    }
    case SourcesTypesTypes.FETCH_TYPES_SUCCESSFULL: {
      const data = action.payload.map((record) => ({
        id: record.Type,
        value: record.Type,
        label: record.Type,
      }));
      const newState = { ...state };
      newState.types.isLoading = false;
      newState.types.data = data;
      return newState;
    }
    case SourcesTypesTypes.FETCH_TYPES_FAILURE: {
      const newState = { ...state };
      newState.types.isLoading = false;
      return newState;
    }

    case SourcesTypesTypes.PUT_SOURCES_REQUESTED: {
      const newState = { ...state };
      newState.sources.isLoading = true;
      return newState;
    }
    case SourcesTypesTypes.PUT_SOURCES_SUCCESSFULL: {
      const newState = { ...state };
      newState.sources.isLoading = false;
      newState.sources.isUpdateSuccess = true;
      return newState;
    }
    case SourcesTypesTypes.PUT_SOURCES_FAILURE: {
      const newState = { ...state };
      newState.sources.isLoading = false;
      newState.sources.isError = true;
      return newState;
    }

    case SourcesTypesTypes.POST_SOURCES_REQUESTED: {
      const newState = { ...state };
      newState.sources.isLoading = true;
      return newState;
    }
    case SourcesTypesTypes.POST_SOURCES_SUCCESSFULL: {
      const newState = { ...state };
      newState.sources.isLoading = false;
      newState.sources.isAddSuccess = true;

      return newState;
    }
    case SourcesTypesTypes.POST_SOURCES_FAILURE: {
      const newState = { ...state };
      newState.sources.isLoading = false;
      newState.sources.isError = true;

      return newState;
    }
    case SourcesTypesTypes.SOURCES_RECORD_UPDATE_SUCCESS: {
      const newState = { ...state };
      newState.sources.isUpdateSuccess = action.payload;
      return newState;
    }
    case SourcesTypesTypes.SOURCES_RECORD_ADD_SUCCESS: {
      const newState = { ...state };
      newState.sources.isAddSuccess = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

export default sourcesTypesReducers;
