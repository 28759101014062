import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import { MONTHS_LIST, TIME_FORMAT_WITH_MONTH_NAME } from '../../../common/constants/common';
import { SIGNALS_GROUP_BY } from '../../../common/constants/options';

/**
 *
 * @param {object} graphData:object with date and corresponding array of selected tags
 * @param {string} groupBy:which determines how data should be clubbed
 * @returns renders graph on main container with parameters received
 */
const ReceivedSignalGraph = ({ graphData, groupBy }) => {
  const dataForGraph = graphData.map((item) => {
    let month = '';
    const record = {};
    const GroupBykeys = Object.keys(SIGNALS_GROUP_BY);
    if (groupBy === GroupBykeys[1]) {
      // days format for graph
      month = moment(item.date).format(TIME_FORMAT_WITH_MONTH_NAME);
    } else if (groupBy === GroupBykeys[2]) {
      // months format for graph
      month = MONTHS_LIST[new Date(item.date).getMonth()];
    } else {
      // days format for graph
      month = moment(item.date).format(TIME_FORMAT_WITH_MONTH_NAME);
    }
    record.month = month;
    record.signalCount = parseInt(item.signalCount, 10);
    return record;
  });

  return (
    <>
      <div className="graph-wrapper">
        <div className="graph-block">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={1000}
              height={500}
              data={dataForGraph}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="month" />
              <YAxis axisLine={false} stroke="#e58fff" />
              <Tooltip />
              <Legend
                wrapperStyle={{
                  bottom: -16,
                }}
              />
              <Line
                dataKey="signalCount"
                name="Signals"
                aria-label="Signal"
                stroke="#999fcc"
                strokeWidth={2}
                legendType="line"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};
export default ReceivedSignalGraph;
