import { put, call, takeLatest } from 'redux-saga/effects';

import { TrendsActionTypes } from './trendsTypes';
import { fetchTrendsSuccessfull, fetchTrendsFailure } from './trendsActions';
import trendsApi from '../../api/trendsApi';

function* fetchTrends(action) {
  const { response, error } = yield call(trendsApi.fetchTrends, action.payload);
  if (response) {
    yield put(fetchTrendsSuccessfull(response));
  } else {
    yield put(fetchTrendsFailure(error));
  }
}

export default function* trendsSaga() {
  yield takeLatest(TrendsActionTypes.FETCH_TRENDS_REQUESTED, fetchTrends);
}
