import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Navbar, Dropdown, Button, Image } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/sm-logo.svg';
import { setLoginUserGroup } from '../../state/auth/authActions';
import UserAuth from '../utils/userAuth';
import { VIEW_CONFIGURATION, VIEW_USERS, VIEW_REPORT } from '../constants/roles';
import DetailView from './DetailView';

const Header = ({ toggle }) => {
  const [loginUserName, setLoginUserName] = useState('');
  const { loginUserGroups } = useSelector((state) => state.auth);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  Auth.currentAuthenticatedUser().then((data) => {
    const groups = data.signInUserSession.accessToken.payload['cognito:groups'];
    if (groups && loginUserGroups.length === 0) {
      dispatch(setLoginUserGroup(groups));
    }
    if (data.attributes && data.attributes.name) {
      setLoginUserName(data.attributes.name);
    }
  });

  const reportEvent = (event) => {
    setShowDetailModal(true);
    event.preventDefault();
  };

  return (
    <>
      <Navbar collapseOnSelect fixed="top" expand="lg" className="justify-content-between">
        <div className="d-flex align-items-center">
          <div className="logo-wrapper">
            <Link to="/">
              <Image src={logo} />
            </Link>
          </div>
          <Button className="toggler" onClick={toggle}>
            <span className="cmp-menu" />
          </Button>
        </div>
        <Dropdown className="logo">
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <p className="user-text">{loginUserName ? loginUserName[0] : ''}</p>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <ul className="nav-list">
              <Dropdown.Item>
                <li>
                  <Link to="/" className="profile-link">
                    {loginUserName ? loginUserName.split('@')[0] : ''}
                  </Link>
                </li>
              </Dropdown.Item>
              {UserAuth.can(VIEW_CONFIGURATION, loginUserGroups) ? (
                <Dropdown.Item className={location.pathname === '/configuration' ? 'active' : ''}>
                  <li>
                    <Link to="/configuration">
                      <span className="cmp-configuration icon" />
                      <span className="dropdown-text">Configuration</span>
                    </Link>
                  </li>
                </Dropdown.Item>
              ) : null}
              {UserAuth.can(VIEW_USERS, loginUserGroups) ? (
                <Dropdown.Item className={location.pathname === '/user-setting' ? 'active' : ''}>
                  <li>
                    <Link to="/user-setting">
                      <span className="cmp-users icon" />
                      <span className="dropdown-text">Users</span>
                    </Link>
                  </li>
                </Dropdown.Item>
              ) : null}
              {UserAuth.can(VIEW_REPORT, loginUserGroups) ? (
                <Dropdown.Item
                  className={location.pathname === '/report?delta=monthly' ? 'active' : ''}
                >
                  <li>
                    <Link
                      to="/"
                      onClick={(event) => {
                        reportEvent(event);
                      }}
                    >
                      <span className="cmp-report icon" />
                      <span className="dropdown-text">Report</span>
                    </Link>
                  </li>
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item>
                <li>
                  <Link to="/logout">
                    <span className="cmp-logout icon" />
                    <span className="dropdown-text">Logout</span>
                  </Link>
                </li>
              </Dropdown.Item>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar>

      {showDetailModal ? <div className="back-drop" /> : null}
      <div
        className="add-config-form readlists-detail"
        style={{
          transform: showDetailModal ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        {showDetailModal ? <DetailView setShowDetailModal={setShowDetailModal} /> : null}
      </div>
    </>
  );
};
export default Header;
