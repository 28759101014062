import React, { useState } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { TIME_FORMAT } from '../../../common/constants/common';
import Loading from '../../../common/components/Loading';
import Error from '../../../common/components/Error';
import PaginationComponent from '../../../common/components/PaginationComponent';
import DetailView from './DetailView';

/**
 *
 * @param {object} :with data for redalist and pagination details
 * @returns list-view for received data of readlist
 */
const ListView = ({ signalsListData, setOffsetHandler, offset, summaryData }) => {
  const { isLoading, isError, data } = signalsListData;
  const [detailsData, setDetailsData] = useState(null);

  const { isLoading: isSummaryLoading, isError: isSummaryError } = summaryData;
  if (isLoading) return <Loading />;
  if (isError) return <Error />;
  const [showDetailModal, setShowDetailModal] = useState(false);

  const listData = data
    ? data.map((item) => (
        <tr>
          <td className="col-md-4 tags">
            <a href={item.url} target="_blank" rel="noreferrer">
              {item.title}
            </a>
          </td>
          <td className="col-md-1 text-center">{item.signalType}</td>
          <td className="col-md-1 text-center source-text">{item.source}</td>
          <td className="col-md-4 tags">{item.tags.sort().join(', ')}</td>
          <td className="col-md-1 text-center">{moment(item.date).format(TIME_FORMAT)}</td>
          <td className="col-md-2 text-center">
            <Button
              className="download-btn"
              onClick={() => {
                setShowDetailModal(true);
                setDetailsData(item);
              }}
            >
              Details
            </Button>
          </td>
        </tr>
      ))
    : [];

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  return (
    <>
      <div className="table-responsive grid-list-table">
        <table className="table table-borderless compass-table">
          <thead>
            <th>Title</th>
            <th className="text-center">Signal type</th>
            <th className="text-center">Source</th>
            <th className="text-center">Tags</th>
            <th className="text-center">Date</th>
            <th className="text-center">Details</th>
          </thead>
          <tbody>{listData}</tbody>
        </table>
        {showDetailModal ? <div className="back-drop" /> : null}
        <div
          className="add-config-form readlists-detail"
          style={{
            transform: showDetailModal ? 'translateX(0)' : 'translateX(100%)',
          }}
        >
          {showDetailModal ? (
            <DetailView setShowDetailModal={setShowDetailModal} signalsObj={detailsData} />
          ) : null}
        </div>
      </div>
      {isSummaryLoading ? (
        <Loading />
      ) : isSummaryError ? (
        <Error />
      ) : (
        <PaginationComponent
          recordCount={signalsListData.count}
          setOffsetHandler={setOffsetHandler}
          offset={offset}
        />
      )}
    </>
  );
};

export default ListView;
