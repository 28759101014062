import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button, InputGroup, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Loading from '../../common/components/Loading';
import Error from '../../common/components/Error';
import SearchFilter from './componenets/SearchFilter';
import ReceivedSignalCount from './componenets/ReceivedSignalCount';
import ReadListCount from './componenets/ReadListCount';
import GridView from './componenets/GridView';
import ListView from './componenets/ListView';
import {
  fetchReadListSummaryRequested,
  fetchReadListListingRequested,
} from '../../state/readList/readListActions';
import { fetchTagRequested } from '../../state/areaSubAreaTags/areaSubAreaTagsActions';
import SearchBar from '../../common/components/SearchBar';
import RenderTooltip from '../../common/components/RenderTooltip ';
import {
  PAGINATION_PER_PAGE,
  OFFSET_DEFAULT_VALUE,
  TIME_FORMAT_BACKEND,
  DEFAULT_ORDER_BY,
  NUMBER_OF_DAYS,
  NO_DATA_FOUND_MESAGE,
  READLIST_CONTAINER_TOOLTIP,
  SEARCH_TOOLTIP,
  SIGNALS_PROCESSED_TOOLTIP,
  READLIST_CARD_TOOLTIP,
  READLISTS_CUSTOM_DAYS,
} from '../../common/constants/common';
import { setSearchTag } from '../../state/searchTag/searchTagActions';
import { fetchSignalRequested } from '../../state/signals/signalsActions';
import { generateParamsUrl } from '../../common/utils/helpers';
import { READLSIT_GROUP_BY_URL } from '../../common/constants/options';

/**
 *
 * @param {object} props - url query params and browser history
 * @returns jsx to be rendered
 */
const ReadListContainer = (props) => {
  const dispatch = useDispatch();

  /* main search bar related code  start */

  /**
   * Get typehead selected value from url and set default value to state
   */
  const [mainSearchBarValue, setMainSearchBarValue] = useState(
    new URLSearchParams(props.location.search).get('search') || ''
  );

  const [open, setOpen] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [shouldTriggerSummaryAPI, setShouldTriggerSummaryAPI] = useState(false);

  /* this state should set and use only after clicking in appy filter */
  const [selectedTagsForFilter, setSelectedTagsForFilter] = useState(() => {
    const incommingTag = new URLSearchParams(props.location.search).get('tags');
    if (incommingTag) {
      return [
        {
          value: incommingTag,
          label: incommingTag,
        },
      ];
    }
    return [];
  });
  const [selectedFromDateForFilter, setSelectedFromDateForFilter] = useState(() => {
    if (new URLSearchParams(props.location.search).get('fromDate')) {
      return new Date(new URLSearchParams(props.location.search).get('fromDate'));
    }
    return null;
  });
  const [selectedToDateForFilter, setSelectedToDateForFilter] = useState(() => {
    if (new URLSearchParams(props.location.search).get('toDate')) {
      return new Date(new URLSearchParams(props.location.search).get('toDate'));
    }
    return null;
  });

  /**
   * Get selected value from typehead and set it to state
   * If selected value is empty close the typehead
   * @param {Array} value
   */
  const mainSerchBarChangeHandler = (value) => {
    if (value.length !== 0) {
      setMainSearchBarValue(value.length !== 0 ? value[0].name : '');
    } else {
      setOpen(false);
    }
  };

  /**
   * set typehead to open on click of it
   */
  const onFocusMainSearchHandler = () => {
    setOpen(true);
  };
  /* main search bar related code  end */

  /* search form related codebase start */

  // if tags available in the url then get those, convert into array of object and set tags state
  const [selectedTags, setSelectedTags] = useState(() => {
    const urlParamArea = new URLSearchParams(props.location.search).get('tags');
    if (urlParamArea) {
      const urlParamAreaList = urlParamArea.split(',');
      if (urlParamAreaList) {
        const preSelectedValues = urlParamAreaList.map((item) => ({
          value: item,
          label: item,
        }));
        return preSelectedValues;
      }
    }
    return [];
  });
  const [offset, setOffset] = useState(
    new URLSearchParams(props.location.search).get('offset')
      ? parseInt(new URLSearchParams(props.location.search).get('offset'), 10)
      : OFFSET_DEFAULT_VALUE
  );

  const [fromDate, setFromDate] = useState(() => {
    if (new URLSearchParams(props.location.search).get('fromDate')) {
      return new Date(new URLSearchParams(props.location.search).get('fromDate'));
    }
    return null;
  });
  const [toDate, setToDate] = useState(() => {
    if (new URLSearchParams(props.location.search).get('toDate')) {
      return new Date(new URLSearchParams(props.location.search).get('toDate'));
    }
    return null;
  });

  const [groupBy, setGroupBy] = useState(() => {
    if (new URLSearchParams(props.location.search).get('groupBy')) {
      return new URLSearchParams(props.location.search).get('isMonth')
        ? 'month'
        : new URLSearchParams(props.location.search).get('isYear')
        ? 'year'
        : new URLSearchParams(props.location.search).get('groupBy');
    }
    return '';
  });

  const [selectedSortBy, setSelectedSortBy] = useState(() => {
    if (new URLSearchParams(props.location.search).get('order')) {
      return new URLSearchParams(props.location.search).get('order');
    }
    return DEFAULT_ORDER_BY;
  });
  const [selectedTitle, setSelectedTitle] = useState(
    new URLSearchParams(props.location.search).get('title') || ''
  );

  // toggale between card and list view
  const [toggleListAndGidView, setToggleListAndGidView] = useState(false);

  // get area, subarea, tag data from redux store
  const { tag } = useSelector((state) => state.areaSubAreaTags);

  // get readlist data from store
  const { listingData, summaryData } = useSelector((state) => state.readList);

  const { data: signalData } = useSelector((state) => state.signals);

  // set tag values from store
  const [allTags, setAllTags] = useState(tag.data);
  const tags = _.orderBy(allTags, 'id');
  /**
   * fetch call to tags list
   */
  const fetchTags = () => {
    dispatch(fetchTagRequested());
  };

  /**
   * Tags dropdown change handler
   * @param {array} selectedTags
   * On change of tags dropdown set it's value into state
   * Change area list to only show area's which are related to tags
   * Chnage subArea's list to only show tags related to tags
   */
  const tagChangeHandler = (selectedTagsList) => {
    setSelectedTags(selectedTagsList);
  };

  /**
   * remove main search value
   */
  const mainSearchBarValueRemoveHandler = () => {
    setMainSearchBarValue('');
    // setShouldTriggerSummaryAPI(true);
  };
  /**
   * set date range
   */
  const setSelectedDateRange = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };
  const setGroupByHandler = (selectedGroupBy) => {
    setGroupBy(selectedGroupBy);
    if (selectedGroupBy !== 'custom') {
      const today = new Date();
      setToDate(today);
      if (selectedGroupBy === 'days') {
        setFromDate(moment(today).subtract(NUMBER_OF_DAYS, 'day').toDate());
      } else if (selectedGroupBy === 'month') {
        setFromDate(moment(today).subtract(1, 'month').toDate());
      } else if (selectedGroupBy === 'year') {
        setFromDate(moment(today).subtract(1, 'year').toDate());
      }
    }
  };
  /**
   * date range remove handler
   */
  const dateRangeRemoveHandler = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedFromDateForFilter(null);
    setSelectedToDateForFilter(null);
    setShouldRedirect(true);
  };

  /**
   * Remove tag by label from filter
   * @param {Integer} id
   */
  const searchTagRemoveHandler = (label) => {
    setSelectedTags(selectedTags.filter((tagItem) => !(tagItem.label === label)));
    setSelectedTagsForFilter(selectedTags.filter((tagItem) => !(tagItem.label === label)));
    setShouldTriggerSummaryAPI(true);
    setShouldRedirect(true);
  };

  /**
   * Remove group by
   */
  const gropByRemoveHandler = () => {
    dateRangeRemoveHandler();
    setGroupBy('');
  };

  /**
   * clear all selected filters
   */
  const clearFilterHandler = () => {
    setSelectedTags([]);
    setSelectedTagsForFilter([]);
    gropByRemoveHandler();
    setOffset(OFFSET_DEFAULT_VALUE);
    dispatch(setSearchTag(null));
  };

  /**
   * Generate and return url with search parameters
   * @returns url string
   */
  function getUrlParams() {
    return generateParamsUrl({
      fromDate: selectedFromDateForFilter
        ? moment(selectedFromDateForFilter).format(TIME_FORMAT_BACKEND)
        : '',
      toDate: selectedToDateForFilter
        ? moment(selectedToDateForFilter).format(TIME_FORMAT_BACKEND)
        : '',
      tags: selectedTagsForFilter,
      offset,
      groupBy: READLSIT_GROUP_BY_URL[groupBy],
      isMonth: groupBy === 'month' ? 1 : 0,
      isYear: groupBy === 'year' ? 1 : 0,
      limit: PAGINATION_PER_PAGE,
      order: selectedSortBy,
      secondaryFilter: selectedTitle,
    }).join('&');
  }

  /**
   * apply secondary search to listing view
   */
  const setSecondaryFilter = (value) => {
    setSelectedTitle(value);
    if (value.length > 2) {
      setShouldRedirect(true);
    } else if (value.length === 0) {
      setShouldRedirect(true);
    }
  };
  /**
   * change url on updating any filter
   */
  const triggetChangeUrl = () => {
    props.history.push({
      pathname: '/readlist',
      search: getUrlParams(),
    });
  };

  /**
   * Update url on applying filters
   */
  const applyFilterHandler = () => {
    let newDate = '';
    if (fromDate && !toDate) {
      newDate = moment(fromDate).subtract(NUMBER_OF_DAYS, 'days').toDate();
      setToDate(fromDate);
      setFromDate(newDate);
      setSelectedFromDateForFilter(newDate);
      setSelectedToDateForFilter(fromDate);
    } else {
      setSelectedFromDateForFilter(fromDate);
      setSelectedToDateForFilter(toDate);
    }
    setSelectedTagsForFilter(selectedTags);
    setShouldTriggerSummaryAPI(true);
    setShouldRedirect(true);
    setOffset(OFFSET_DEFAULT_VALUE);
  };

  /**
   * change offset on pagination
   * @param {number} offsetCount
   */
  const setOffsetHandler = (offsetCount) => {
    setOffset(offsetCount);
    setShouldRedirect(true);
  };

  /* search form related codebase end */

  useEffect(() => {
    dispatch(fetchSignalRequested(props.location.search));
    setShouldRedirect(true);
    setShouldTriggerSummaryAPI(false);
  }, [shouldTriggerSummaryAPI]);

  useEffect(() => {
    // set typehead close initialy
    setOpen(false);

    const searchFromdate = moment(new URLSearchParams(props.location.search).get('fromDate'));
    const searchTodate = moment(new URLSearchParams(props.location.search).get('toDate'));
    const totaldateGap = searchTodate.diff(searchFromdate, 'day');
    const groupByFromUrl = new URLSearchParams(props.location.search).get('groupBy');

    const tagsFromUrl = new URLSearchParams(props.location.search).get('tags');
    if (tagsFromUrl) {
      const updatedTags = tagsFromUrl.split(',').map((item) => ({ value: item, label: item }));

      setSelectedTagsForFilter(updatedTags);
      setSelectedTags(updatedTags);
    }
    // if any filtered applies then show content alse hide content
    if (props.location.search === '') {
      clearFilterHandler(); // clear all filter if no parameters found in url
    }

    let customeGroupBy = '&groupBy=';

    if (groupByFromUrl === 'custom') {
      Object.keys(READLISTS_CUSTOM_DAYS).forEach((range) => {
        const [minLimit, maxLimit] = range.split('-');
        if (totaldateGap === parseInt(minLimit, 10) && totaldateGap === parseInt(maxLimit, 10)) {
          customeGroupBy += READLISTS_CUSTOM_DAYS[range];
        }
        if (totaldateGap > parseInt(minLimit, 10) && totaldateGap < parseInt(maxLimit, 10)) {
          customeGroupBy += READLISTS_CUSTOM_DAYS[range];
        }
        if (
          parseInt(minLimit, 10) === totaldateGap ||
          (!maxLimit && parseInt(minLimit, 10) < totaldateGap)
        ) {
          if (maxLimit) {
            customeGroupBy += READLISTS_CUSTOM_DAYS[`${minLimit}-${maxLimit}`];
          } else {
            customeGroupBy += READLISTS_CUSTOM_DAYS[minLimit];
          }
        }
      });
    }
    const url = props.location.search.replace('&groupBy=custom', customeGroupBy);
    // dispatch listing request
    dispatch(fetchReadListListingRequested(url));
    dispatch(fetchReadListSummaryRequested(url));
    setShouldRedirect(true);
  }, [props.location.search]);

  // for getting tags list
  useEffect(() => {
    if (tag.isLoading && tag.data.length === 0) {
      fetchTags();
    } else {
      setAllTags(tag.data);
    }
  }, [tag.isLoading]);
  /**
   * trigger url change when shouldRedirect flag change
   */
  useEffect(() => {
    if (shouldRedirect) {
      triggetChangeUrl();
      setShouldRedirect(false);
    }
  }, [shouldRedirect]);
  return (
    <div className="page-wrapper">
      <Container fluid>
        <Row className="page-search-bar">
          <Col>
            <Form>
              <InputGroup className="mb-2 search-input">
                <InputGroup.Text>
                  <span className="cmp-search" />
                </InputGroup.Text>
                <SearchBar
                  mainSerchBarChangeHandler={mainSerchBarChangeHandler}
                  open={open}
                  setOpen={setOpen}
                  mainSearchBarValue={mainSearchBarValue}
                  onFocusMainSearchHandler={onFocusMainSearchHandler}
                  getUrlParams={getUrlParams}
                />
              </InputGroup>
            </Form>
          </Col>
        </Row>
        <Row className="heading-wrapper">
          <Col xs={12}>
            <h1 className="heading-one mb-4">
              Readlists
              <RenderTooltip toolTipMsg={READLIST_CONTAINER_TOOLTIP} />
            </h1>
          </Col>
        </Row>
        {/* Accordion STARTS HERE */}
        <SearchFilter
          // local state
          selectedTags={selectedTags}
          fromDate={fromDate}
          toDate={toDate}
          mainSearchBarValue={mainSearchBarValue}
          // add change handlers
          tagChangeHandler={tagChangeHandler}
          setSelectedDateRange={setSelectedDateRange}
          groupBy={groupBy}
          setGroupByHandler={setGroupByHandler}
          // remove search handlers
          mainSearchBarValueRemoveHandler={mainSearchBarValueRemoveHandler}
          dateRangeRemoveHandler={dateRangeRemoveHandler}
          searchTagRemoveHandler={searchTagRemoveHandler}
          gropByRemoveHandler={gropByRemoveHandler}
          // clear all handler
          clearFilterHandler={clearFilterHandler}
          // search filter submit handler
          applyFilterHandler={applyFilterHandler}
          // dropdown values
          allTags={tags}
        />
        {/* Accordion ENDS HERE */}
        <>
          <Row>
            <Col md={6} xl={4}>
              <div className="count">
                <p className="tooltip-subhead">
                  Signals
                  <RenderTooltip toolTipMsg={SIGNALS_PROCESSED_TOOLTIP} />
                </p>
                {summaryData.isLoading ? (
                  <Loading />
                ) : summaryData.isError ? (
                  <Error />
                ) : (
                  <ReceivedSignalCount receivedCounters={signalData ? signalData.success : 0} />
                )}
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div className="count">
                <p className="tooltip-subhead">
                  Readlists
                  <RenderTooltip toolTipMsg={READLIST_CARD_TOOLTIP} />
                </p>
                {summaryData.isLoading ? (
                  <Loading />
                ) : summaryData.isError ? (
                  <Error />
                ) : (
                  <ReadListCount
                    readListCount={
                      summaryData.readlistCount ? summaryData.readlistCount[0].count : 0
                    }
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-4">
              <div>
                <Row className="mb-4 align-items-end">
                  <Col md={12} lg={10}>
                    <div className="compass-form filter">
                      <Row className="grid-list-search">
                        <Col sm={6} lg={4} xl={3} xxl="auto" className="mb-3 mb-lg-0">
                          <Form.Label>Sort</Form.Label>
                          <div className="select">
                            <Form.Control
                              as="select"
                              size="sm"
                              value={selectedSortBy}
                              onChange={(event) => {
                                setSelectedSortBy(event.target.value);
                                setShouldRedirect(true);
                                setOffset(OFFSET_DEFAULT_VALUE);
                              }}
                            >
                              <option value="createdAt,ASC">Date (Ascending)</option>
                              <option value="createdAt,DESC">Date (Descending)</option>
                              <option value="score,asc"> Score (Ascending)</option>
                              <option value="score,desc">Score (Descending)</option>
                            </Form.Control>
                          </div>
                        </Col>
                        <Col sm={6} lg={5} xl={4} xxl={3} className="mb-3 mb-lg-0">
                          <Form.Label className="form-label">Search</Form.Label>
                          <div className="input-tooltip-wrap">
                            <InputGroup>
                              <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={selectedTitle}
                                onChange={(event) => {
                                  setSecondaryFilter(event.target.value);
                                }}
                                className="mb-2 mb-sm-0"
                              />
                            </InputGroup>
                            <RenderTooltip toolTipMsg={SEARCH_TOOLTIP} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {listingData.data.length > 0 ? (
                    <Col className="d-flex justify-content-end">
                      <Button className="grid-icon" onClick={() => setToggleListAndGidView(false)}>
                        <span
                          className={
                            toggleListAndGidView ? 'cmp-grid-icon' : 'cmp-grid-icon active'
                          }
                        />
                      </Button>
                      <span className="divider" />
                      <Button className="list-icon" onClick={() => setToggleListAndGidView(true)}>
                        <span className={toggleListAndGidView ? 'active cmp-list' : 'cmp-list'} />
                      </Button>
                    </Col>
                  ) : null}
                </Row>
                {listingData.data.length > 0 ? (
                  <div className="grid-list-block">
                    {listingData.isLoading ? (
                      <Loading />
                    ) : listingData.isError ? (
                      <Error />
                    ) : listingData.data.length <= 0 ? (
                      <div className="no-data-found">
                        <p>{NO_DATA_FOUND_MESAGE}</p>
                      </div>
                    ) : toggleListAndGidView ? (
                      <ListView
                        readListListData={listingData}
                        setOffsetHandler={setOffsetHandler}
                        offset={offset}
                        summaryData={summaryData}
                        selectedTitle={selectedTitle}
                      />
                    ) : (
                      <GridView
                        readListListData={listingData}
                        setOffsetHandler={setOffsetHandler}
                        offset={offset}
                        summaryData={summaryData}
                        selectedTitle={selectedTitle}
                        tagChangeHandler={tagChangeHandler}
                        selectedTags={selectedTags}
                      />
                    )}
                  </div>
                ) : (
                  <div className="no-data-found">
                    <p>{NO_DATA_FOUND_MESAGE}</p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </>
      </Container>
    </div>
  );
};
export default ReadListContainer;
