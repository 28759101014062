/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_SOURCES_FAILURE,
  FETCH_SOURCES_REQUESTED,
  FETCH_SOURCES_SUCCESSFULL,
  FETCH_TYPES_REQUESTED,
  FETCH_TYPES_SUCCESSFULL,
  FETCH_TYPES_FAILURE,
  PUT_SOURCES_FAILURE,
  PUT_SOURCES_SUCCESSFULL,
  PUT_SOURCES_REQUESTED,
  POST_SOURCES_FAILURE,
  POST_SOURCES_SUCCESSFULL,
  POST_SOURCES_REQUESTED,
  SOURCES_RECORD_UPDATE_SUCCESS,
  SOURCES_RECORD_ADD_SUCCESS,
} from '../../common/constants/actions';

/**
 * Export the actions for reducers, actions and sagas
 */
export const SourcesTypesTypes = {
  FETCH_SOURCES_FAILURE,
  FETCH_SOURCES_REQUESTED,
  FETCH_SOURCES_SUCCESSFULL,
  FETCH_TYPES_REQUESTED,
  FETCH_TYPES_SUCCESSFULL,
  FETCH_TYPES_FAILURE,
  PUT_SOURCES_FAILURE,
  PUT_SOURCES_SUCCESSFULL,
  PUT_SOURCES_REQUESTED,
  POST_SOURCES_FAILURE,
  POST_SOURCES_SUCCESSFULL,
  POST_SOURCES_REQUESTED,
  SOURCES_RECORD_UPDATE_SUCCESS,
  SOURCES_RECORD_ADD_SUCCESS,
};
