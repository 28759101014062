import { SignalsActionTypes } from './signalsTypes';

export const fetchSignalRequested = (query) => ({
  type: SignalsActionTypes.FETCH_SIGNALS_REQUESTED,
  payload: query,
});

export const fetchSignalSuccessfull = (signals) => ({
  type: SignalsActionTypes.FETCH_SIGNALS_SUCCESSFULL,
  payload: signals,
});

export const fetchSignalFailure = (error) => ({
  type: SignalsActionTypes.FETCH_SIGNALS_FAILURE,
  payload: error,
});

// fetch graph data
export const fetchSignalGraphRequested = (query) => ({
  type: SignalsActionTypes.FETCH_SIGNALS_GRAPH_REQUESTED,
  payload: query,
});

export const fetchSignalGraphSuccessfull = (signals) => ({
  type: SignalsActionTypes.FETCH_SIGNALS_GRAPH_SUCCESSFULL,
  payload: signals,
});

export const fetchSignalGraphFailure = (error) => ({
  type: SignalsActionTypes.FETCH_SIGNALS_GRAPH_FAILURE,
  payload: error,
});

// fetch list data
export const fetchSignalListRequested = (query) => ({
  type: SignalsActionTypes.FETCH_SIGNALS_LIST_REQUESTED,
  payload: query,
});

export const fetchSignalListSuccessfull = (signals) => ({
  type: SignalsActionTypes.FETCH_SIGNALS_LIST_SUCCESSFULL,
  payload: signals,
});

export const fetchSignalListFailure = (error) => ({
  type: SignalsActionTypes.FETCH_SIGNALS_LIST_FAILURE,
  payload: error,
});
