/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_READLIST_LISTING_REQUESTED,
  FETCH_READLIST_LISTING_SUCCESSFULL,
  FETCH_READLIST_LISTING_FAILURE,
  FETCH_READLIST_SUMMARY_REQUESTED,
  FETCH_READLIST_SUMMARY_SUCCESSFULL,
  FETCH_READLIST_SUMMARY_FAILURE,
} from '../../common/constants/actions';

/**
 * Export the types for reducers, actions and sagas
 */
export const ReadListActionTypes = {
  FETCH_READLIST_LISTING_REQUESTED,
  FETCH_READLIST_LISTING_SUCCESSFULL,
  FETCH_READLIST_LISTING_FAILURE,
  FETCH_READLIST_SUMMARY_REQUESTED,
  FETCH_READLIST_SUMMARY_SUCCESSFULL,
  FETCH_READLIST_SUMMARY_FAILURE,
};
