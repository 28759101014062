import { ReadListActionTypes } from './readListTypes';

export const fetchReadListSummaryRequested = (query) => ({
  type: ReadListActionTypes.FETCH_READLIST_SUMMARY_REQUESTED,
  payload: query,
});

export const fetchReadListSummarySuccessfull = (configuration) => ({
  type: ReadListActionTypes.FETCH_READLIST_SUMMARY_SUCCESSFULL,
  payload: configuration,
});

export const fetchReadListSummaryFailure = (error) => ({
  type: ReadListActionTypes.FETCH_READLIST_SUMMARY_FAILURE,
  payload: error,
});

export const fetchReadListListingRequested = (query) => ({
  type: ReadListActionTypes.FETCH_READLIST_LISTING_REQUESTED,
  payload: query,
});

export const fetchReadListListingSuccessfull = (configuration) => ({
  type: ReadListActionTypes.FETCH_READLIST_LISTING_SUCCESSFULL,
  payload: configuration,
});

export const fetchReadListListingFailure = (error) => ({
  type: ReadListActionTypes.FETCH_READLIST_LISTING_FAILURE,
  payload: error,
});
