import ApiUtils from './apiUtils';

/**
 * Fetch readlist
 * @param {*} param
 * @returns {object} readlist and signalCount summary
 */
const fetchReadListSummary = async (param) => {
  try {
    const response = await ApiUtils.get(`readlists/summary${param}`);
    const { data } = response;
    if (data) {
      return {
        response: data.data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Fetch readlist listing
 * @param {Object} param: array of object for grid-list view
 */
const fetchReadListListing = async (params) => {
  try {
    const response = await ApiUtils.get(`readlists${params}`);
    const { data } = response;
    if (data) {
      return {
        response: data.data.readlists,
        count: data.data.count[0].readlistcount,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Fetch readlist download
 * @param {Object} param
 */
const readListDownload = async (categoryId, readListName, date) => {
  try {
    const response = await ApiUtils.get(
      `readlists/${categoryId}/download?readlistName=${readListName}&date=${date}`
    );
    const { data } = response;
    if (data) {
      return {
        response: data.data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchReadListSummary,
  fetchReadListListing,
  readListDownload,
};
