/**
 * Import constant types
 */
import { AreaSuAreaTagsActionTypes } from './areaSubAreaTagsTypes';

export const fetchTagRequested = (query) => ({
  type: AreaSuAreaTagsActionTypes.FETCH_TAG_REQUESTED,
  payload: query,
});

export const fetchTagSuccessfull = (tag) => ({
  type: AreaSuAreaTagsActionTypes.FETCH_TAG_SUCCESSFULL,
  payload: tag,
});

export const fetchTagFailure = (error) => ({
  type: AreaSuAreaTagsActionTypes.FETCH_TAG_FAILURE,
  payload: error,
});
