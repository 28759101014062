/**
 * Import constant types
 */
import { AreaSuAreaTagsActionTypes } from './areaSubAreaTagsTypes';

/**
 *
 * @function :  areaSubAreaTagsReducer
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */

const areaSubAreaTagsReducer = (state = {}, action) => {
  switch (action.type) {
    /**
     * Action Creator - fetchTag
     * This action is use to call the service to get
     */
    case AreaSuAreaTagsActionTypes.FETCH_TAG_REQUESTED: {
      const newState = { ...state };
      newState.subArea.isLoading = true;
      return newState;
    }
    /**
     * Action Creator - fetchTagSuccessfull
     * it will receive the data dispatching from the
     */
    case AreaSuAreaTagsActionTypes.FETCH_TAG_SUCCESSFULL: {
      const data = [];
      action.payload.data.forEach((record) => {
        if (record.alias && record.alias.length) {
          record.alias.forEach((aliasName) => {
            data.push({
              id: aliasName,
              value: record.title,
              label: aliasName,
            });
          });
        }
        data.push({
          id: record.title,
          value: record.title,
          label: record.title,
        });
      });
      const newState = { ...state };
      newState.tag.isLoading = false;
      newState.tag.data = data;
      return newState;
    }
    /**
     * Action Creator - fetchTagFailure
     * it will receive the error dispatching from the
     */
    case AreaSuAreaTagsActionTypes.FETCH_TAG_FAILURE: {
      const newState = { ...state };
      newState.tag.isLoading = false;
      newState.tag.isError = true;
      return newState;
    }

    default:
      return state;
  }
};

export default areaSubAreaTagsReducer;
