/**
 * Import constant types
 */
import { TrendsActionTypes } from './trendsTypes';

/**
 *
 * @function :  trendsReducer
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */
const trendsReducer = (state = {}, action) => {
  switch (action.type) {
    case TrendsActionTypes.FETCH_TRENDS_REQUESTED:
      return { ...state, isLoading: true };

    case TrendsActionTypes.FETCH_TRENDS_SUCCESSFULL:
      return {
        ...state,
        isLoading: false,
        isError: false,
        ...action.payload,
      };

    case TrendsActionTypes.FETCH_TRENDS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        trendsList: [],
      };
    default:
      return state;
  }
};

export default trendsReducer;
