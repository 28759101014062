/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_USER_REQUESTED,
  FETCH_USER_SUCCESSFULL,
  FETCH_USER_FAILURE,
  PUT_USER_REQUESTED,
  PUT_USER_FAILURE,
  PUT_USER_SUCCESSFULL,
  USER_RECORD_UPDATE_SUCCESS,
} from '../../common/constants/actions';

/**
 * Export the actions for reducers, actions and sagas
 */
export const UserActionTypes = {
  FETCH_USER_REQUESTED,
  FETCH_USER_SUCCESSFULL,
  FETCH_USER_FAILURE,
  PUT_USER_REQUESTED,
  PUT_USER_FAILURE,
  PUT_USER_SUCCESSFULL,
  USER_RECORD_UPDATE_SUCCESS,
};
