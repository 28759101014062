import { accessList } from '../constants/roles';

class UserAuth {
  can = (accessResource, loginUserGroups) => {
    let response = false;
    if (loginUserGroups) {
      loginUserGroups.forEach((group) => {
        if (accessList[group] && accessList[group].includes(accessResource)) {
          response = true;
        }
      });
    }
    return response;
  };
}

export default new UserAuth();
