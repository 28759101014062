import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const RenderTooltip = ({ toolTipMsg }) => (
  <OverlayTrigger
    trigger="click"
    placement="auto"
    rootClose
    overlay={<Tooltip id="tooltip-disabled">{toolTipMsg}</Tooltip>}
  >
    <span className="d-inline-block ms-2 lh-1">
      <span className="cmp-question tool-tip" />
    </span>
  </OverlayTrigger>
);
export default RenderTooltip;
