import ApiUtils from './apiUtils';

/**
 * Fetch source list
 * @param {Object} param
 */
const fetchSources = async () => {
  try {
    const response = await ApiUtils.get(`/sources`);
    const { data } = response.data;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Fetch source list
 * @param {Object} param
 */
const fetchTypes = async () => {
  try {
    const response = await ApiUtils.get(`/sources/types`);

    const { data } = response.data;

    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Put user by Id
 * @param {Object} param
 */
const putSources = async (param) => {
  try {
    const response = await ApiUtils.put(`sources/${param.key}`, param.data);
    const { data } = response;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: null,
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Put user by Id
 * @param {Object} param
 */
const postSources = async (param) => {
  try {
    const response = await ApiUtils.post(`sources/`, param.data);
    const { data } = response;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: null,
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};
export default {
  fetchSources,
  fetchTypes,
  putSources,
  postSources,
};
