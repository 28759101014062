import React from 'react';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { MONTHS_LIST, TIME_FORMAT_WITH_MONTH_NAME } from '../../../common/constants/common';
import { COLOR_CODES } from '../../../common/constants/colorCodes';
import { TRENDS_GROUP_BY } from '../../../common/constants/options';
// import { intervalByWidth } from '../../../common/utils/helpers';

/**
 *
 * @param {object} trends -  has name of trends to be rendered on graph
 * @returns renders line for graph of trends received
 */
const renderLine = (trends) =>
  trends.map((trend, index) => (
    <Line
      key={trend.name}
      dataKey={trend.name}
      name={trend.name}
      aria-label="Trend"
      stroke={COLOR_CODES[index] || COLOR_CODES[0]}
      strokeWidth={2}
      legendType="line"
      dot={false}
      connectNulls
    />
  ));

/**
 *
 * @param {object} graphData:object with date and corresponding array of selected tags
 * @param {string} groupBy:which determines how data should be clubbed
 * @returns renders graph on main container with parameters received
 */
const ReceivedTrendsGraph = ({ graphData, groupBy }) => {
  const dataForGraph = graphData.map((data) => {
    let name = '';
    const GroupBykeys = Object.keys(TRENDS_GROUP_BY);
    if (groupBy === GroupBykeys[1]) {
      // days format for graph
      name = moment(data.date).format(TIME_FORMAT_WITH_MONTH_NAME);
    } else if (groupBy === GroupBykeys[2]) {
      // months format for graph
      name = MONTHS_LIST[new Date(data.date).getMonth()];
    } else {
      // days format for graph
      name = moment(data.date).format(TIME_FORMAT_WITH_MONTH_NAME);
    }
    const record = { name };
    data.trends.forEach((trend) => {
      if (trend.count !== 'NaN') {
        record[trend.name.toString()] = trend.count;
      }
    });
    return record;
  });

  return (
    <>
      <div className="graph-wrapper">
        <div className="graph-block">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={1000}
              height={500}
              data={dataForGraph}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" minTickGap={100} />
              <YAxis axisLine={false} domain={[0, 100]} allowDataOverflow stroke="#e58fff" />
              <Tooltip />
              <Legend
                wrapperStyle={{
                  bottom: -16,
                }}
              />
              {graphData.length ? renderLine(graphData[0].trends) : null}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default ReceivedTrendsGraph;
