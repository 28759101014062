import React, { useEffect, useState } from 'react';
import { Container, Col, Row, InputGroup, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Loading from '../../common/components/Loading';
import Error from '../../common/components/Error';
import SearchFilter from './components/SearchFilter';
import { fetchTrendsRequested } from '../../state/trends/trendsActions';
import { fetchTagRequested } from '../../state/areaSubAreaTags/areaSubAreaTagsActions';
import SearchBar from '../../common/components/SearchBar';
import ReceivedTrendsGraph from './components/ReceivedTrendsGraph';
import RenderTooltip from '../../common/components/RenderTooltip ';
import { generateParamsUrl } from '../../common/utils/helpers';
import { TRENDS_GROUP_BY_URL } from '../../common/constants/options';

import {
  TIME_FORMAT_BACKEND,
  TRENDS_CUSTOM_DAYS,
  NUMBER_OF_DAYS,
  TRENDS_CONTAINER_TOOLTIP,
  RECEIVED_TRENDS_GRAPH_TOOLTIP,
} from '../../common/constants/common';
import { setSearchTag } from '../../state/searchTag/searchTagActions';

/**
 *
 * @param {object} props - url query params and browser history
 * @returns jsx to be rendered
 */
const TrendsContainer = (props) => {
  const dispatch = useDispatch();
  /* main search bar related code  start */

  /**
   * Get typehead selected value from url and set default value to state
   */

  const [open, setOpen] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isShowContent, setIsShowContent] = useState(false);
  const [applyFilterError, setApplyFilterError] = useState('Please apply filter');

  /* this state should set and use only after clicking in apply filter */
  const [selectedTagsForFilter, setSelectedTagsForFilter] = useState(() => {
    const incommingTag = new URLSearchParams(props.location.search).get('tags');
    if (incommingTag) {
      return [
        {
          value: incommingTag,
          label: incommingTag,
        },
      ];
    }
    return [];
  });
  const [selectedFromDateForFilter, setSelectedFromDateForFilter] = useState(() => {
    if (new URLSearchParams(props.location.search).get('fromDate')) {
      return new Date(new URLSearchParams(props.location.search).get('fromDate'));
    }
    // return new Date().setDate(new Date().getDate() - 7);
    return null;
  });
  const [selectedToDateForFilter, setSelectedToDateForFilter] = useState(() => {
    if (new URLSearchParams(props.location.search).get('toDate')) {
      return new Date(new URLSearchParams(props.location.search).get('toDate'));
    }
    return null;
  });

  const [mainSearchBarValue, setMainSearchBarValue] = useState(
    new URLSearchParams(props.location.search).get('search') || ''
  );
  /**
   * Get selected value from typehead and set it to state
   * If selected value is empty close the typehead
   * @param {Array} value
   */
  const mainSerchBarChangeHandler = (value) => {
    if (value.length !== 0) {
      setMainSearchBarValue(value.length !== 0 ? value[0].name : '');
    } else {
      setOpen(false);
    }
  };

  /**
   * set typehead to open on click of it
   */
  const onFocusMainSearchHandler = () => {
    setOpen(true);
  };
  /* main search bar related code  end */

  /* search form related codebase start */

  // if tags available in the url then get those, convert into array of object and set tags state
  const [selectedTags, setSelectedTags] = useState(() => {
    const urlParamTag = new URLSearchParams(props.location.search).get('tags');
    if (urlParamTag) {
      const urlParamTagList = urlParamTag.split(',');
      if (urlParamTagList) {
        const preSelectedValues = urlParamTagList.map((item) => ({
          value: item,
          label: item,
        }));
        return preSelectedValues;
      }
    }
    return [];
  });

  const [fromDate, setFromDate] = useState(() => {
    if (new URLSearchParams(props.location.search).get('fromDate')) {
      return new Date(new URLSearchParams(props.location.search).get('fromDate'));
    }
    // return new Date().setDate(new Date().getDate() - 7);
    return null;
  });
  const [toDate, setToDate] = useState(() => {
    if (new URLSearchParams(props.location.search).get('toDate')) {
      return new Date(new URLSearchParams(props.location.search).get('toDate'));
    }
    return null;
  });

  const [groupBy, setGroupBy] = useState(() => {
    if (new URLSearchParams(props.location.search).get('groupBy')) {
      return new URLSearchParams(props.location.search).get('isMonth')
        ? 'month'
        : new URLSearchParams(props.location.search).get('isYear')
        ? 'year'
        : new URLSearchParams(props.location.search).get('groupBy');
    }
    return '';
  });

  // get trends data from redux store
  const { data, isLoading, isError } = useSelector((state) => state.trends);

  // tag data from redux store
  const { tag } = useSelector((state) => state.areaSubAreaTags);

  // tag values from store

  const [allTags, setAllTags] = useState(tag.data);
  const tags = _.orderBy(allTags, 'id');

  /**
   * fetch call to tags list
   */
  const fetchTags = () => {
    dispatch(fetchTagRequested());
  };

  /**
   * Tags dropdown change handler
   * @param {array} selectedTags
   * On change of tags dropdown set it's value into state
   * Change tag list to only show tag's which are related to tags
   * Chnage subArea's list to only show tags related to tags
   */
  const tagChangeHandler = (selectedTagsList) => {
    setSelectedTags(selectedTagsList);
  };

  /**
   * remove main search value
   */
  const mainSearchBarValueRemoveHandler = () => {
    setMainSearchBarValue('');
  };
  /**
   * set date range
   */
  const setSelectedDateRange = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  const setGroupByHandler = (selectedGroupBy) => {
    setGroupBy(selectedGroupBy);
    if (selectedGroupBy !== 'custom') {
      const today = new Date();
      setToDate(today);
      if (selectedGroupBy === 'days') {
        setFromDate(moment(today).subtract(NUMBER_OF_DAYS, 'days').toDate());
      } else if (selectedGroupBy === 'month') {
        setFromDate(moment(today).subtract(1, 'month').toDate());
      } else if (selectedGroupBy === 'year') {
        setFromDate(moment(today).subtract(1, 'year').toDate());
      }
    }
  };

  /**
   * date range remove handler
   */
  const dateRangeRemoveHandler = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedFromDateForFilter(null);
    setSelectedToDateForFilter(null);
    setGroupBy('');
    setShouldRedirect(true);
  };

  /**
   * Remove tag by label from filter
   * @param {String} label
   */
  const searchTagRemoveHandler = (label) => {
    setSelectedTags(selectedTags.filter((tagItem) => !(tagItem.label === label)));
    setSelectedTagsForFilter(selectedTags.filter((tagItem) => !(tagItem.label === label)));
    setShouldRedirect(true);
  };
  /**
   * Remove group by
   */
  const gropByRemoveHandler = () => {
    dateRangeRemoveHandler();
    setGroupBy('');
  };
  /**
   * clear all selected filters
   */
  const clearFilterHandler = () => {
    setSelectedTags([]);
    setSelectedTagsForFilter([]);
    gropByRemoveHandler();
    dispatch(setSearchTag(null));
  };

  /**
   * Generate and return url with search parameters
   * @returns url string
   */
  function getUrlParams() {
    return generateParamsUrl({
      fromDate: selectedFromDateForFilter
        ? moment(selectedFromDateForFilter).format(TIME_FORMAT_BACKEND)
        : '',
      toDate: selectedToDateForFilter
        ? moment(selectedToDateForFilter).format(TIME_FORMAT_BACKEND)
        : '',
      tags: selectedTagsForFilter,

      groupBy: TRENDS_GROUP_BY_URL[groupBy],
      isMonth: groupBy === 'month' ? 1 : 0,
      isYear: groupBy === 'year' ? 1 : 0,
    }).join('&');
  }

  /**
   * change url on updating any filter
   */
  const triggerChangeUrl = () => {
    props.history.push({
      pathname: '/trends',
      search: getUrlParams(),
    });
  };

  /**
   * Update url on applying filters
   */
  const applyFilterHandler = () => {
    let newDate = '';
    if (fromDate && !toDate) {
      newDate = moment(fromDate).subtract(NUMBER_OF_DAYS, 'days').toDate();
      setToDate(fromDate);
      setFromDate(newDate);
      setSelectedFromDateForFilter(newDate);
      setSelectedToDateForFilter(fromDate);
    } else {
      setSelectedFromDateForFilter(fromDate);
      setSelectedToDateForFilter(toDate);
    }
    setSelectedTagsForFilter(selectedTags);
    setShouldRedirect(true);
  };

  /**
   * trigger url change when shouldRedirect flag change
   */
  useEffect(() => {
    if (shouldRedirect) {
      triggerChangeUrl();
      setShouldRedirect(false);
    }
  }, [shouldRedirect]);
  /* search form related codebase end */

  useEffect(() => {
    // set typehead close initialy
    setOpen(false);

    // tag filter should be selected in order to view the content
    if (props.location.search !== '') {
      const tagsFromUrl = new URLSearchParams(props.location.search).get('tags');
      const groupByFromUrl = new URLSearchParams(props.location.search).get('groupBy');
      if (tagsFromUrl && groupByFromUrl) {
        setIsShowContent(true);
        setApplyFilterError('');
        const searchFromdate = moment(new URLSearchParams(props.location.search).get('fromDate'));
        const searchTodate = moment(new URLSearchParams(props.location.search).get('toDate'));
        const totaldateGap = searchTodate.diff(searchFromdate, 'days');
        const updatedTags = tagsFromUrl.split(',').map((item) => ({ value: item, label: item }));

        setSelectedTagsForFilter(updatedTags);
        setSelectedTags(updatedTags);
        let customeGroupBy = '&groupBy=';

        if (groupByFromUrl === 'custom') {
          Object.keys(TRENDS_CUSTOM_DAYS).forEach((range) => {
            const [minLimit, maxLimit] = range.split('-');
            if (
              totaldateGap === parseInt(minLimit, 10) &&
              totaldateGap === parseInt(maxLimit, 10)
            ) {
              customeGroupBy += TRENDS_CUSTOM_DAYS[range];
            }
            if (totaldateGap > parseInt(minLimit, 10) && totaldateGap < parseInt(maxLimit, 10)) {
              customeGroupBy += TRENDS_CUSTOM_DAYS[range];
            }
            if (
              parseInt(minLimit, 10) === totaldateGap ||
              (!maxLimit && parseInt(minLimit, 10) < totaldateGap)
            ) {
              if (maxLimit) {
                customeGroupBy += TRENDS_CUSTOM_DAYS[`${minLimit}-${maxLimit}`];
              } else {
                customeGroupBy += TRENDS_CUSTOM_DAYS[minLimit];
              }
            }
          });
        }
        const url = props.location.search.replace('&groupBy=custom', customeGroupBy);
        // dispatch fetchTrend request
        dispatch(fetchTrendsRequested(url));
      } else if (!tagsFromUrl) {
        setIsShowContent(false);
        setApplyFilterError('Please select tags');
      } else if (!groupByFromUrl) {
        setIsShowContent(false);
        setApplyFilterError('Please select date range');
      }
    } else {
      setApplyFilterError('Please apply filter');
      setIsShowContent(false);
      clearFilterHandler(); // clear all filter if no parameters found in url
    }
  }, [props.location.search]);

  // for getting tags list
  useEffect(() => {
    if (tag.isLoading && tag.data.length === 0) {
      fetchTags();
    } else {
      setAllTags(tag.data);
    }
  }, [tag.isLoading]);
  return (
    <div className="page-wrapper">
      <Container fluid>
        <Row className="page-search-bar">
          <Col>
            <Form>
              <InputGroup className="mb-2 search-input">
                <InputGroup.Text>
                  <span className="cmp-search" />
                </InputGroup.Text>
                <SearchBar
                  mainSerchBarChangeHandler={mainSerchBarChangeHandler}
                  open={open}
                  setOpen={setOpen}
                  mainSearchBarValue={mainSearchBarValue}
                  onFocusMainSearchHandler={onFocusMainSearchHandler}
                  getUrlParams={getUrlParams}
                />
              </InputGroup>
            </Form>
          </Col>
        </Row>
        <Row className="heading-wrapper">
          <Col xs={12}>
            <h1 className="heading-one mb-4">
              Trends
              <span>
                <RenderTooltip toolTipMsg={TRENDS_CONTAINER_TOOLTIP} />
              </span>
            </h1>
          </Col>
        </Row>
        {/* Accordion STARTS HERE */}
        <SearchFilter
          // local state
          selectedTags={selectedTags}
          fromDate={fromDate}
          toDate={toDate}
          mainSearchBarValue={mainSearchBarValue}
          // add change handlers
          tagChangeHandler={tagChangeHandler}
          setSelectedDateRange={setSelectedDateRange}
          groupBy={groupBy}
          setGroupByHandler={setGroupByHandler}
          // remove search handlers
          mainSearchBarValueRemoveHandler={mainSearchBarValueRemoveHandler}
          dateRangeRemoveHandler={dateRangeRemoveHandler}
          searchTagRemoveHandler={searchTagRemoveHandler}
          gropByRemoveHandler={gropByRemoveHandler}
          // clr all handler
          clearFilterHandler={clearFilterHandler}
          // search filter submit handler
          applyFilterHandler={applyFilterHandler}
          // dropdown values
          allTags={tags}
          isShowContent={isShowContent}
        />
        {/* Accordion ENDS HERE */}
        {applyFilterError ? <p className="filter-suggestion">{applyFilterError}</p> : null}

        {isShowContent ? (
          <>
            <Row>
              <Col xs={12}>
                <div className="signals-graph-wrapper">
                  <p className="tooltip-subhead">
                    Received
                    <RenderTooltip toolTipMsg={RECEIVED_TRENDS_GRAPH_TOOLTIP} />
                  </p>
                  {isError ? (
                    <Error />
                  ) : isLoading ? (
                    <Loading />
                  ) : (
                    <ReceivedTrendsGraph graphData={data} groupBy={groupBy} />
                  )}
                </div>
              </Col>
            </Row>
          </>
        ) : null}
      </Container>
    </div>
  );
};
export default TrendsContainer;
