/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_DASHBOARD_REQUESTED,
  FETCH_DASHBOARD_SUCCESSFULL,
  FETCH_DASHBOARD_FAILURE,
} from '../../common/constants/actions';

/**
 * Export the types for reducers, actions and sagas
 */
export const DashBoardActionTypes = {
  FETCH_DASHBOARD_REQUESTED,
  FETCH_DASHBOARD_SUCCESSFULL,
  FETCH_DASHBOARD_FAILURE,
};
