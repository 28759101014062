import React, { Fragment, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Accordion, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchTypeheadRequested } from '../../state/typehead/typeheadActions';
import { TIME_FORMAT_BACKEND, NUMBER_OF_DAYS, NO_DATA_FOUND_MESAGE } from '../constants/common';
import RenderTooltip from './RenderTooltip ';
import Loading from './Loading';
import Error from './Error';
import { setSearchTag } from '../../state/searchTag/searchTagActions';
import UserAuth from '../utils/userAuth';
import { VIEW_SIGNALS_PAGE, VIEW_TRENDS_PAGE, VIEW_READLIST_PAGE } from '../constants/roles';

const SearchBar = (props) => {
  const { loginUserGroups } = useSelector((state) => state.auth);
  const [isApiTrigger, setIsApiTrigger] = useState(false);
  const [isShowClearButton, setIsShowClearButton] = useState(false);
  const { mainSerchBarChangeHandler, open, onFocusMainSearchHandler, setOpen, mainSearchBarValue } =
    props;
  // Selectors
  const { data, isLoading, isError } = useSelector(({ typehead }) => typehead);
  const searchTag = encodeURIComponent(mainSearchBarValue);
  // Dispatch
  const dispatch = useDispatch();

  const handleSearch = (query) => {
    setIsApiTrigger(true);
    dispatch(
      fetchTypeheadRequested({
        searchTerm: query,
      })
    );
  };

  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(activeId);
    }
  }
  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const onInputChange = () => {
    setOpen(true);
  };

  const refContainer = useRef();
  const closeDropdown = () => {
    setOpen(false);
    refContainer.current.clear();
    setIsApiTrigger(false);
    setIsShowClearButton(false);
  };
  const today = new Date();
  const fromDate = moment(today).subtract(NUMBER_OF_DAYS, 'days').format(TIME_FORMAT_BACKEND);
  const toDate = moment(today).format(TIME_FORMAT_BACKEND);

  return (
    <>
      {isLoading ? (
        <div className="typahead-loader">
          <Loading />
        </div>
      ) : isShowClearButton ? (
        <Button onClick={closeDropdown} className="transparent-btn typeahead-close">
          <span className="cmp-close" />
        </Button>
      ) : null}

      <div className="w-100 search-box">
        <AsyncTypeahead
          className="async"
          filterBy={filterBy}
          id="async-example"
          isLoading={isLoading}
          labelKey="name"
          minLength={1}
          onSearch={handleSearch}
          options={data}
          open={open}
          placeholder="Search..."
          onChange={(value) => {
            if (value.length > 0) {
              setIsShowClearButton(true);
              mainSerchBarChangeHandler(value);
              dispatch(setSearchTag(encodeURIComponent(value[0].name)));
              setActiveId(activeId);
            } else {
              setIsShowClearButton(false);
            }
          }}
          onKeyDown={() => {
            setIsShowClearButton(true);
          }}
          onFocus={onFocusMainSearchHandler}
          // clearButton
          ref={refContainer}
          onInputChange={onInputChange}
          renderMenuItemChildren={(option, index) => (
            <>
              <Accordion className="filter-accordion">
                <Accordion.Item eventKey={activeId}>
                  <Accordion.Header onClick={() => toggleActive({ index })}>
                    <div className="header-block">
                      <h2>{option.name}</h2>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="single-tech">
                      <div className="technologies">
                        <h3>Technologies | Tools</h3>
                        <RenderTooltip toolTipMsg="Search bar" />
                      </div>
                      <div className="status">
                        <h2>
                          {/* Status to be removed for now
                          <span className="text-capitalize">{option.status}</span> |{' '}
                          <span className="text-uppercase">{option.hierarchy}</span> */}
                        </h2>
                      </div>
                    </div>
                    <div className="async-card-wrapper">
                      <div className="card-block">
                        {option.signalCount > 0 ? (
                          <Link
                            to={`/signals?fromDate=${fromDate}&toDate=${toDate}&tags=${searchTag}&groupBy=day&limit=6&order=createdAt%2CDESC`}
                            className={
                              UserAuth.can(VIEW_SIGNALS_PAGE, loginUserGroups) ? '' : 'pe-none'
                            }
                          >
                            <div className="item">
                              <div className="item-box">
                                <h2 className="compass-red">{option.signalCount}</h2>
                              </div>
                            </div>
                            <h4 className="sub-heading">Signals</h4>
                          </Link>
                        ) : (
                          <>
                            <div className="item">
                              <div className="item-box">
                                <h2 className="compass-red">0</h2>
                              </div>
                            </div>
                            <h4 className="sub-heading">Signals</h4>
                          </>
                        )}
                      </div>

                      <div className="card-block">
                        {option.trendsCount > 0 ? (
                          <Link
                            to={`/trends?fromDate=${fromDate}&toDate=${toDate}&tags=${searchTag}&groupBy=days`}
                            className={
                              UserAuth.can(VIEW_TRENDS_PAGE, loginUserGroups) ? '' : 'pe-none'
                            }
                          >
                            <div className="item">
                              <div className="item-box">
                                <h2 className="compass-purple">{option.trendsCount}</h2>
                              </div>
                            </div>
                            <h4 className="sub-heading">Trends</h4>
                          </Link>
                        ) : (
                          <>
                            <div className="item">
                              <div className="item-box">
                                <h2 className="compass-purple">0</h2>
                              </div>
                            </div>
                            <h4 className="sub-heading">Trends</h4>
                          </>
                        )}
                      </div>

                      <div className="card-block">
                        {option.readListCount > 0 ? (
                          <Link
                            to={`/readlist?fromDate=${fromDate}&toDate=${toDate}&tags=${searchTag}&groupBy=days&limit=6&order=createdAt%2CDESC`}
                            className={
                              UserAuth.can(VIEW_READLIST_PAGE, loginUserGroups) ? '' : 'pe-none'
                            }
                          >
                            <div className="item">
                              <div className="item-box">
                                <h2 className="compass-yellow">{option.readListCount}</h2>
                              </div>
                              <h4 className="sub-heading">Readlist</h4>
                            </div>
                          </Link>
                        ) : (
                          <>
                            <div className="item">
                              <div className="item-box">
                                <h2 className="compass-yellow">0</h2>
                              </div>
                              <h4 className="sub-heading">Readlist</h4>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )}
        />
      </div>
      {isError ? (
        <div className="typeahead-error">
          <Error msg="Failed to load data" />
        </div>
      ) : null}
      {!isError && !isLoading && isApiTrigger && data.length === 0 ? (
        <div className="typeahead-nodata">
          <p className="no-data-text">{NO_DATA_FOUND_MESAGE}</p>
        </div>
      ) : null}
    </>
  );
};

export default SearchBar;
