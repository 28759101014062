import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Card, Tooltip, OverlayTrigger, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import RenderTooltip from '../../../common/components/RenderTooltip ';
import UserAuth from '../../../common/utils/userAuth';
import { VIEW_SIGNALS_PAGE, VIEW_READLIST_PAGE } from '../../../common/constants/roles';
import { kFormatter } from '../../../common/utils/helpers';
import {
  TIME_FORMAT_BACKEND,
  NUMBER_OF_DAYS,
  SIGNALS_PROCESSED_TOOLTIP,
  READLIST_TOOLTIP,
  SOURCES_TOOLTIP,
  TIME_FORMAT_FOR_SINCE_DATE,
} from '../../../common/constants/common';

/**
 *Component to render Searchbar, side
 *
 * @param {object} dashBoardData: object conataing readlist, signals and sources count for cards
 * @returns - cards to display signals, readlist and sources count
 */
const DashboardCard = ({ dashBoardData }) => {
  const { loginUserGroups } = useSelector((state) => state.auth);

  const today = new Date();
  const fromDate = moment(today).subtract(NUMBER_OF_DAYS, 'days').format(TIME_FORMAT_BACKEND);
  const toDate = moment(today).format(TIME_FORMAT_BACKEND);
  const sinceSignalDate = moment(dashBoardData.firstSignalDate).format(TIME_FORMAT_FOR_SINCE_DATE);
  const sinceSourceDate = moment(dashBoardData.firstSourceDate).format(TIME_FORMAT_FOR_SINCE_DATE);
  const sinceReadlistDate = moment(dashBoardData.firstReadlistDate).format(
    TIME_FORMAT_FOR_SINCE_DATE
  );

  return (
    <Row className="main-card-wrapper">
      <div className="white-tooltip">
        <OverlayTrigger
          trigger="click"
          placement="auto"
          rootClose
          overlay={
            <Tooltip id="tooltip-disabled">
              With lot of technologies emerging every moment it&#39;s difficult to show all in one
              view. Using the search bar, search for specific technologies that you are interested
              in.
            </Tooltip>
          }
        >
          <span className="d-inline-block lh-1 mb-2">
            <span className="cmp-question white-tool-tip" />
          </span>
        </OverlayTrigger>
      </div>
      <Col sm={6} lg={4}>
        <Card>
          <Card.Body>
            <Card.Title>
              {UserAuth.can(VIEW_SIGNALS_PAGE, loginUserGroups) ? (
                <Link
                  to={`/signals?fromDate=${fromDate}&toDate=${toDate}&groupBy=day&limit=6&order=createdAt%2CDESC`}
                >
                  Signals Processed
                </Link>
              ) : (
                'Signals Processed'
              )}

              <RenderTooltip toolTipMsg={SIGNALS_PROCESSED_TOOLTIP} />
              <span className="since-date">(since {sinceSignalDate})</span>
            </Card.Title>
            <Card.Text>
              {UserAuth.can(VIEW_SIGNALS_PAGE, loginUserGroups) ? (
                <Link
                  to={`/signals?fromDate=${fromDate}&toDate=${toDate}&groupBy=day&limit=6&order=createdAt%2CDESC`}
                >
                  <span className="content-heading">{kFormatter(dashBoardData.Signals)}</span>
                </Link>
              ) : (
                <span className="content-heading">{kFormatter(dashBoardData.Signals)}</span>
              )}
              <span className="sub-head">Total</span>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} lg={4}>
        <Card>
          <Card.Body>
            <Card.Title>
              {UserAuth.can(VIEW_READLIST_PAGE, loginUserGroups) ? (
                <Link to="/readlist">Readlists</Link>
              ) : (
                'Readlists'
              )}
              <RenderTooltip toolTipMsg={READLIST_TOOLTIP} />
              <span className="since-date">(since {sinceReadlistDate})</span>
            </Card.Title>
            <Card.Text>
              {UserAuth.can(VIEW_READLIST_PAGE, loginUserGroups) ? (
                <Link to="/readlist">
                  <span className="content-heading">{kFormatter(dashBoardData.Readlists)}</span>
                </Link>
              ) : (
                <span className="content-heading">{kFormatter(dashBoardData.Readlists)}</span>
              )}

              <span className="sub-head">Total</span>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} lg={4}>
        <Card>
          <Card.Body>
            <Card.Title>
              <Link className="pe-none" to="/">
                Sources
              </Link>
              <RenderTooltip toolTipMsg={SOURCES_TOOLTIP} />
              <span className="since-date">(since {sinceSourceDate})</span>
            </Card.Title>
            <Card.Text>
              <Link className="pe-none" to="/">
                <span className="content-heading">{dashBoardData.Sources}</span>
              </Link>
              <span className="sub-head">Total</span>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default DashboardCard;
