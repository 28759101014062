import React from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { TRENDS_GROUP_BY } from '../../../common/constants/options';
import MultiSelect from '../../../common/components/MultiSelect';
import { textCapitalized } from '../../../common/utils/helpers';
import 'react-datepicker/dist/react-datepicker.css';

const TrendsFilterView = (props) => {
  const {
    // selected values
    selectedTags,

    fromDate,
    toDate,

    // change hadlers
    tagChangeHandler,

    setSelectedDateRange,
    groupBy,
    setGroupByHandler,
    // remove handlers

    // clear all filters
    clearFilterHandler,
    // apply filter handler
    applyFilterHandler,
    // all data
    allTags,

    setShowTrendsFilterView,
  } = props;

  return (
    <>
      <div className="modal-head-wrapper">
        <h2 className="modal-heading">Filters</h2>
        <Button
          onClick={() => {
            setShowTrendsFilterView(false);
          }}
          className="transparent-btn close-btn"
        >
          <span className="cmp-close" />
        </Button>
      </div>
      <div className="form-wrapper">
        <Form className="compass-form filter">
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Date Range</Form.Label>
                <div className="select">
                  <Form.Control
                    as="select"
                    size="sm"
                    value={groupBy || setGroupByHandler('days')}
                    onChange={(event) => {
                      if (event.target.value !== 'custom') {
                        setSelectedDateRange([]);
                      }
                      setGroupByHandler(event.target.value);
                    }}
                  >
                    {Object.keys(TRENDS_GROUP_BY).map((item) => (
                      <option key={item} value={item}>
                        {textCapitalized(TRENDS_GROUP_BY[item])}
                      </option>
                    ))}
                  </Form.Control>
                </div>

                {groupBy === 'custom' ? (
                  <>
                    <Form.Label />
                    <div className="select">
                      <DatePicker
                        onChange={setSelectedDateRange}
                        startDate={fromDate}
                        endDate={toDate}
                        selectsRange
                        placeholderText="Select..."
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Tag</Form.Label>
                {allTags.length === 0 ? (
                  <div className="select">
                    <Form.Control as="select" size="sm" disabled>
                      <option>Select...</option>
                    </Form.Control>
                  </div>
                ) : (
                  <div className="select">
                    <div className="multiselect">
                      {selectedTags.length < 10 ? (
                        <MultiSelect
                          options={allTags}
                          onChangeHandler={tagChangeHandler}
                          value={selectedTags}
                        />
                      ) : (
                        <MultiSelect
                          options={selectedTags}
                          onChangeHandler={tagChangeHandler}
                          value={selectedTags}
                        />
                      )}
                    </div>
                  </div>
                )}
                {selectedTags.length < 1 ? (
                  <p className="filter-suggestion">Please apply filter</p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-center submit-btn">
            <Button className="me-2" onClick={clearFilterHandler}>
              Clear
            </Button>
            <Button
              className="me-2"
              onClick={() => {
                applyFilterHandler();
                setShowTrendsFilterView(false);
              }}
              disabled={!selectedTags.length > 0}
            >
              Apply
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default TrendsFilterView;
