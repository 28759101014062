import { put, call, takeLatest } from 'redux-saga/effects';

import { tagsActionTypes } from './tagsTypes';
import {
  fetchTagsSuccessfull,
  fetchTagsFailure,
  putTagsSuccessfull,
  putTagsFailure,
} from './tagsActions';
import tagsApi from '../../api/tagsApi';

/**
 * Featch all tags data
 * @param {object} action
 */
function* fetchTags(action) {
  const { response, error } = yield call(tagsApi.fetchTags, action.payload);
  if (response) {
    yield put(fetchTagsSuccessfull(response));
  } else {
    yield put(fetchTagsFailure(error));
  }
}

/**
 * Put tags
 * @param {Objet} action
 */
function* putTags(action) {
  const { response, error } = yield call(tagsApi.putTagsById, action.payload);
  if (response) {
    yield put(putTagsSuccessfull(response));
  } else {
    yield put(putTagsFailure(error));
  }
}
export default function* tagsSaga() {
  yield takeLatest(tagsActionTypes.FETCH_TAGS_REQUESTED, fetchTags);
  yield takeLatest(tagsActionTypes.PUT_TAGS_REQUESTED, putTags);
}
