/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_TRENDS_REQUESTED,
  FETCH_TRENDS_SUCCESSFULL,
  FETCH_TRENDS_FAILURE,
} from '../../common/constants/actions';

/**
 * Export the actions for reducers, actions and sagas
 */
export const TrendsActionTypes = {
  FETCH_TRENDS_REQUESTED,
  FETCH_TRENDS_SUCCESSFULL,
  FETCH_TRENDS_FAILURE,
};
