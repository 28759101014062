import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Alert, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import EditUser from './components/EditUser';
import Loading from '../../common/components/Loading';
import Error from '../../common/components/Error';
import PageHeading from '../../common/components/PageHeading';
import {
  TIME_FORMAT,
  MSG_EXPIRY_TIME,
  SEARCH_FILTER_PLACEHOLDER,
} from '../../common/constants/common';
import { fetchUserRequested, setUserRecordUpdateStatus } from '../../state/user/userActions';

const UserSetting = () => {
  const [editUser, setEditUser] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const [showEditUser, setShowEditUser] = useState(false);

  /**
   * Close edit user popup
   */
  const closeEditUser = () => setShowEditUser(false);

  const { data, isLoading, isError, isUpdateSuccess } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  /**
   * API call for getting user data
   */
  const getData = () => {
    dispatch(
      fetchUserRequested({
        searchTerm: '',
      })
    );
  };

  /**
   * Show edit user model
   * @param {Object} user
   */
  const editUserModal = (user) => {
    setShowEditUser(true);
    setEditUser(user);
  };
  useEffect(() => {
    if (isLoading && data.length === 0) {
      getData();
    } else {
      const datatabledata = data.map((item) => ({
        name: item.name,
        email: item.email,
        userRole: item.userRole,
        lastUpdated: item.lastUpdated,
        userName: item.userName,
        action: (
          <Button className="btn" onClick={() => editUserModal(item)}>
            Edit
          </Button>
        ),
      }));
      setFilteredData(datatabledata);
    }

    // change state to hide edit success message
    if (isUpdateSuccess) {
      getData();
      closeEditUser();
      setTimeout(() => {
        dispatch(setUserRecordUpdateStatus(false));
      }, MSG_EXPIRY_TIME);
    }
  }, [isLoading, isUpdateSuccess]);

  const [filterToggle, setFilterToggle] = useState(false);

  const toggleFilter = () => setFilterToggle(!filterToggle);

  const USER_TABLE_HEADING = [
    {
      dataField: 'name',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: filterToggle ? textFilter(SEARCH_FILTER_PLACEHOLDER) : null,
      classes: 'col-3 tags',
      headerClasses: 'col-3 tags',
      text: (
        <>
          <Button className="filter-btn" onClick={toggleFilter}>
            <span className="cmp-search" />
          </Button>
          Name
        </>
      ),
    },
    {
      dataField: 'userName',
      text: 'Username',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: filterToggle ? textFilter(SEARCH_FILTER_PLACEHOLDER) : null,
      classes: 'col-3',
      headerClasses: 'col-3',
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: filterToggle ? textFilter(SEARCH_FILTER_PLACEHOLDER) : null,
      classes: 'col-3',
      headerClasses: 'col-3',
    },
    {
      dataField: 'userRole',
      text: 'User Role',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      filter: filterToggle ? textFilter(SEARCH_FILTER_PLACEHOLDER) : null,
      classes: 'col-3 tags',
      headerClasses: 'col-3 tags',
    },
    {
      dataField: 'lastUpdated',
      text: 'Last Updated',
      sort: true,
      formatter: (value) => moment(value).format(TIME_FORMAT),
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return new Date(a) - new Date(b);
        }
        return new Date(b) - new Date(a);
      },
      align: 'center',
      headerAlign: 'center',
      filter: filterToggle ? textFilter(SEARCH_FILTER_PLACEHOLDER) : null,
      classes: 'col-1',
      headerClasses: 'col-1',
    },
    {
      dataField: 'action',
      text: 'Action',

      align: 'center',
      headerAlign: 'center',

      classes: 'col-1',
      headerClasses: 'col-1',
    },
  ];

  return (
    <div className="configuration main">
      <Container fluid>
        <Row>
          <PageHeading pageHeading="Users" />
          <Col xs={12} />
          <Col xs={12}>
            <div className="config-content">
              <Row>
                <Col md={12} xl={12} className="d-flex mb-3 mb-md-0">
                  {isUpdateSuccess ? (
                    <Alert className="mb-4" variant="success">
                      Record updated successfully
                    </Alert>
                  ) : null}
                </Col>
              </Row>
              {/* SHOW USER TABLE START */}
              {isLoading ? (
                <Loading />
              ) : isError ? (
                <Error />
              ) : (
                <>
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="id"
                      data={filteredData}
                      columns={USER_TABLE_HEADING}
                      filter={filterFactory()}
                      classes="compass-table"
                      filterPosition="inline"
                      bordered={false}
                    />
                  </div>
                </>
              )}

              {/* SHOW USER TABLE END */}
            </div>
          </Col>
        </Row>
      </Container>
      {/* EDIT USER STARTS */}
      {showEditUser ? <div className="back-drop" /> : null}
      <div
        className="add-config-form"
        style={{
          transform: showEditUser ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        {showEditUser ? (
          <EditUser show={showEditUser} closeEdit={closeEditUser} user={editUser} />
        ) : null}
      </div>
      {/* EDIT USER ENDS */}
    </div>
  );
};
export default UserSetting;
