import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { CONFIGURATION_TYPE, IS_SECRET } from '../../../common/constants/options';
import { putConfigurationRequested } from '../../../state/configuration/configurationActions';
import Error from '../../../common/components/Error';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { cronTotext } from '../../../common/utils/helpers';
import { DATE_TIME_FORMAT } from '../../../common/constants/common';

/**
 *
 * @param {object} props:handlers to show and close edit modal with configuration detail
 * @returns form component to edit configuraion values
 */
const EditConfiguration = (props) => {
  const { closeEdit, configuration } = props;
  const [configurationValue, setConfigurationValue] = useState(
    configuration.value.trim().replace('cron(', '').replace(')', '')
  );
  const [isEncrypted, setIsEncrypted] = useState(configuration.isEncrypted);
  const [type, setType] = useState(configuration.configType);
  const dispatch = useDispatch();

  const { isLoading, isError, errorMessage } = useSelector((state) => state.configuration);

  const [valueType, setValueType] = useState(configuration.configType);

  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

  const [valueError, setValueError] = useState('');
  const [cronText, setCronText] = useState('');

  /**
   * Function to validate cron values edited in edit-form
   * @returns {Boolean} - checks if the cron entered is valid or not
   */
  const validate = () => {
    let isFormValid = true;

    if (valueType === 'cron') {
      const { err } = cronTotext(configurationValue);
      if (!err) {
        setValueError('');
        setIsDisabledSubmit(false);
      } else {
        isFormValid = false;
        setValueError('Invalid cron value');
        setIsDisabledSubmit(true);
      }
    }
    return isFormValid || false;
  };

  /**
   * Edit configuration form submit handler
   * @param {event} event
   */
  const editConfigurationFormHandler = (event) => {
    event.preventDefault();
    const isFormValid = validate();
    if (isFormValid) {
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure want to submit?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              dispatch(
                putConfigurationRequested({
                  key: configuration.key,
                  data: {
                    isEncrypted: isEncrypted === 'true',
                    type: type || 'string',
                    value: configurationValue === '' ? configuration.value : configurationValue,
                  },
                })
              );
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
  };
  useEffect(() => {
    validate();
  }, [configurationValue]);

  useEffect(() => {
    if (valueType === 'cron') {
      const { err, crontext } = cronTotext(configurationValue);
      if (!err) {
        setCronText(moment(crontext).format(DATE_TIME_FORMAT));
      } else {
        setCronText('');
      }
    }
  }, [configurationValue]);
  return (
    <>
      <div className="modal-head-wrapper">
        <h2 className="modal-heading">Edit Configuration</h2>
        <Button onClick={closeEdit} className="transparent-btn close-btn">
          <span className="cmp-close" />
        </Button>
      </div>
      {isError ? <Error msg={errorMessage} /> : null}
      <div className="form-wrapper">
        <Form className="compass-form" onSubmit={editConfigurationFormHandler}>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Key:</Form.Label>
                <Form.Control type="text" value={configuration.key} disabled />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Type:</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={type}
                  disabled
                  onChange={(event) => {
                    if (event.target.value === 'date') {
                      setValueType('date');
                      setConfigurationValue('');
                    } else if (event.target.value === 'number') {
                      setValueType('number');
                      setConfigurationValue('');
                    } else if (event.target.value === 'cron') {
                      setValueType('cron');
                      setConfigurationValue('cron(* * * * *)');
                    } else {
                      setValueType('text');
                      setConfigurationValue('');
                    }
                    setType(event.target.value);
                  }}
                >
                  {CONFIGURATION_TYPE.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Value:</Form.Label>
                {cronText ? (
                  <div className="no-data-found">
                    <p>{cronText}</p>
                  </div>
                ) : null}
                <Form.Control
                  type={valueType}
                  value={configurationValue}
                  onChange={(event) => setConfigurationValue(event.target.value)}
                />
                <p className="compass-red mt-2">{valueError}</p>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Secret:</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={isEncrypted}
                  onChange={(event) => setIsEncrypted(event.target.value)}
                >
                  {Object.keys(IS_SECRET).map((option) => (
                    <option key={option} value={option}>
                      {IS_SECRET[option]}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-end submit-btn">
            <Button
              className={isLoading ? 'button-loader' : ''}
              type="submit"
              disabled={isDisabledSubmit}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
export default EditConfiguration;
