import React from 'react';
import { Pagination } from 'react-bootstrap';
import { PAGINATION_PER_PAGE } from '../constants/common';

const PaginationComponent = ({ recordCount, setOffsetHandler, offset }) => {
  const max = Math.ceil(recordCount / PAGINATION_PER_PAGE) - 1;
  const maximumSlideCount = 4;
  const sideByCount = 2;
  const initialOffset = 0;
  let frameStart = offset < maximumSlideCount ? initialOffset : offset - sideByCount;
  let frameEnd = max - maximumSlideCount < offset ? offset : offset + sideByCount;
  if (offset < maximumSlideCount) {
    frameStart = initialOffset;
    frameEnd = max < maximumSlideCount ? max : maximumSlideCount;
  } else if (frameEnd > max - sideByCount) {
    frameStart = max - maximumSlideCount;
    frameEnd = max;
  } else {
    frameStart = offset - sideByCount;
    frameEnd = offset + sideByCount;
  }

  const items = [];
  for (let number = frameStart; number <= frameEnd; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === offset}
        onClick={() => setOffsetHandler(number)}
      >
        {number + 1}
      </Pagination.Item>
    );
  }
  return (
    <div>
      <Pagination size="sm">
        {frameStart !== 0 ? <Pagination.First onClick={() => setOffsetHandler(0)} /> : null}
        {items.length > 1 ? items : null}
        {frameEnd !== max ? <Pagination.Last onClick={() => setOffsetHandler(max)} /> : null}
      </Pagination>
    </div>
  );
};
export default PaginationComponent;
