import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { postSourcesRequested } from '../../../state/sourcesTypes/sourcesTypesActions';

const ImportModal = (props) => {
  const { closeImport } = props;
  const dispatch = useDispatch();
  const [res, setRes] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };
  const check = () => {
    const isFormValid = true;

    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile, 'UTF-8');
    fileReader.onload = (e) => {
      const value = JSON.parse(e.target.result);
      setRes(value);
      setIsDisabledSubmit(false);
    };
    return isFormValid || false;
  };

  useEffect(() => {
    if (isFilePicked) {
      check();
    }
  }, [isFilePicked]);

  const handleSubmission = (event) => {
    event.preventDefault();

    const isValid = check();
    if (isValid) {
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure want to submit?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              dispatch(postSourcesRequested({ data: res }));
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
  };

  return (
    <>
      <div className="modal-head-wrapper">
        <h2 className="modal-heading">Upload file</h2>
        <Button onClick={closeImport} className="transparent-btn close-btn">
          <span className="cmp-close" />
        </Button>
      </div>
      <div className="form-wrapper">
        <Form className="compass-form">
          <Row className="justify-content-center">
            <Col md={10} lg={6} className="text-center">
              <div className="import-file">
                <input type="file" name="file" onChange={changeHandler} accept=".json" />
              </div>
              <div className="d-flex justify-content-end mt-3">
                <Button onClick={handleSubmission} disabled={isDisabledSubmit}>
                  Upload
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default ImportModal;
