import { ConfigurationActionTypes } from './configurationTypes';

export const fetchConfigurationRequested = (query) => ({
  type: ConfigurationActionTypes.FETCH_CONFIGURATION_REQUESTED,
  payload: query,
});

export const fetchConfigurationSuccessfull = (configuration) => ({
  type: ConfigurationActionTypes.FETCH_CONFIGURATION_SUCCESSFULL,
  payload: configuration,
});

export const fetchConfigurationFailure = (error) => ({
  type: ConfigurationActionTypes.FETCH_CONFIGURATION_FAILURE,
  payload: error,
});

export const fetchConfigurationByIdRequested = (query) => ({
  type: ConfigurationActionTypes.FETCH_CONFIGURATION_REQUESTED,
  payload: query,
});

export const fetchConfigurationByIdSuccessfull = (configuration) => ({
  type: ConfigurationActionTypes.FETCH_CONFIGURATION_SUCCESSFULL,
  payload: configuration,
});

export const fetchConfigurationByIdFailure = (error) => ({
  type: ConfigurationActionTypes.FETCH_CONFIGURATION_FAILURE,
  payload: error,
});

export const putConfigurationRequested = (query) => ({
  type: ConfigurationActionTypes.PUT_CONFIGURATION_REQUESTED,
  payload: query,
});

export const putConfigurationSuccessfull = (configuration) => ({
  type: ConfigurationActionTypes.PUT_CONFIGURATION_SUCCESSFULL,
  payload: configuration,
});

export const putConfigurationFailure = (error) => ({
  type: ConfigurationActionTypes.PUT_CONFIGURATION_FAILURE,
  payload: error,
});

export const setConfigurationRecordUpdateStatus = (status) => ({
  type: ConfigurationActionTypes.CONFIGURATION_RECORD_UPDATE_SUCCESS,
  payload: status,
});
