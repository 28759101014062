import ApiUtils from './apiUtils';

/**
 * Fetch trends
 * @param {Object} param
 */
const fetchTrends = async (param) => {
  try {
    const response = await ApiUtils.get(`trends${param}`);
    const { data } = response;
    if (data && data.data.length > 0) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchTrends,
};
