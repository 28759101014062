import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardContainer from '../../pages/Dashboard/DashboardContainer';
import SignalsContainer from '../../pages/Signals/SignalsContainer';
import TrendsContainer from '../../pages/Trends/TrendsContainer';
import Configuration from '../../pages/Configuration/Configuration';
import UserSetting from '../../pages/UserSetting/UserSetting';
import Tags from '../../pages/Tags/Tags';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import LoginEmail from './LoginEmail';
import LoginPassword from './LoginPassword';
import ConfirmPassword from './ConfirmPassword';
import NotFoundPage from './NotFoundPage';
import Logout from './Logout';
import ReadListContainer from '../../pages/ReadList/ReadListContainer';
import UnderConstruction from './UnderConstruction';
import UserAuth from '../utils/userAuth';
import Sources from '../../pages/Sources/Sources';
import Report from './Report';
import {
  VIEW_SIGNALS_PAGE,
  VIEW_TRENDS_PAGE,
  VIEW_READLIST_PAGE,
  VIEW_CONFIGURATION,
  VIEW_USERS,
  VIEW_FAIELD_SIGNALS,
} from '../constants/roles';
import ScrollButton from './ScrollButton';
import SignalDownload from './SignalDownload';

const AppRouter = () => {
  const { loginUserGroups } = useSelector((state) => state.auth);

  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Router>
        {window.location.pathname === '/report' ? (
          <Route exact path="/report" component={Report} />
        ) : (
          <>
            <Header toggle={toggle} />
            <div className="content-wrap">
              <Sidebar isOpen={isOpen} />
              <div className={isOpen ? 'main-wrapper padding-left-0' : 'main-wrapper'}>
                <Switch>
                  <Route exact path="/" component={DashboardContainer} />
                  <Route exact path="/dashboard" component={DashboardContainer} />

                  {UserAuth.can(VIEW_SIGNALS_PAGE, loginUserGroups) ? (
                    <Route exact path="/signals" component={SignalsContainer} />
                  ) : null}

                  {UserAuth.can(VIEW_TRENDS_PAGE, loginUserGroups) ? (
                    <Route exact path="/trends" component={TrendsContainer} />
                  ) : null}

                  {UserAuth.can(VIEW_READLIST_PAGE, loginUserGroups) ? (
                    <Route exact path="/readlist" component={ReadListContainer} />
                  ) : null}

                  {UserAuth.can(VIEW_CONFIGURATION, loginUserGroups) ? (
                    <Route exact path="/configuration" component={Configuration} />
                  ) : null}

                  {UserAuth.can(VIEW_USERS, loginUserGroups) ? (
                    <Route exact path="/user-setting" component={UserSetting} />
                  ) : null}

                  {UserAuth.can(VIEW_USERS, loginUserGroups) ? (
                    <Route exact path="/report" component={Report} />
                  ) : null}

                  {UserAuth.can(VIEW_FAIELD_SIGNALS, loginUserGroups) ? (
                    <Route exact path="/tags" component={Tags} />
                  ) : null}

                  {UserAuth.can(VIEW_FAIELD_SIGNALS, loginUserGroups) ? (
                    <Route exact path="/sources" component={Sources} />
                  ) : null}
                  <Route exact path="/login-email" component={LoginEmail} />
                  <Route exact path="/login-password" component={LoginPassword} />
                  <Route exact path="/confirm-password" component={ConfirmPassword} />
                  <Route exact path="/logout" component={Logout} />
                  <Route exact path="/under-construction" component={UnderConstruction} />
                  <Route exact path="/signals/:id/download" component={SignalDownload} />

                  <Route component={NotFoundPage} />
                </Switch>
              </div>
              <Footer />
              <ScrollButton />
            </div>
          </>
        )}
      </Router>
    </>
  );
};
export default AppRouter;
