export const PAGINATION_PER_PAGE = 6;
export const OFFSET_DEFAULT_VALUE = 0;

export const TIME_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:MM:SS';
export const TIME_FORMAT_WITH_MONTH_NAME = 'MMM DD/YY';
export const TIME_FORMAT_FOR_SINCE_DATE = 'MMMM YYYY';
export const DATE_FORMAT_FOR_REPORT_HEADING = 'Do MMMM YYYY';
export const DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE = 'Do MMM YYYY';
export const DATE_FORMAT_FOR_DATE = 'Do ';
export const DATE_FORMAT_FOR_CHART_LEGENDS = 'Do MMM';

export const TIME_FORMAT_BACKEND = 'YYYY-MM-DD';
export const MSG_EXPIRY_TIME = 2000;
export const DEFAULT_ORDER_BY = 'score,desc';
export const DEFAULT_ORDER_BY_SIGNALS = 'createdAt,DESC';

export const NUMBER_OF_DAYS = 7;
export const REDIRECT_BUTTON = 'Redirect to Homepage';
export const RECORD_ADDED_SUCCESS_MESSAGE = 'Record Added Successfully';
export const REDIRECT_MESSAGE =
  'You will be redirected to Homepage in 10 seconds, if not click below.';
export const SUCCESS_MESSAGE = 'Record Updated Successfully.';
export const NO_DATA_FOUND_MESAGE = 'No data found';
export const SEARCH_FILTER_PLACEHOLDER = { placeholder: 'Search...' };
export const SIGNALS_CONTAINER_TOOLTIP =
  'For every predefined time interval we pull signals(articles, newsletters, writeups, etc.) into the system.';

export const READLIST_CONTAINER_TOOLTIP =
  'Readlists are collections of curated signals related to a certain technology.';

export const READLIST_CARD_TOOLTIP =
  'Number represents the count of Readlist processed successfully within the Date-Range and/or other filters applied.';

export const SIGNAL_CARD_TOOLTIP =
  'Numbers are the count of total signals categorised by their types viz. Manual, Newsletter and RSS.';
export const SEARCH_TOOLTIP = 'Search with the keywords to highlight just what you need.';
export const SIGNALS_PROCESSED_CONTAINER_TOOLTIP =
  'Number represents the count of signals processed successfully over selected time period.';
export const SIGNALS_FAILED_CONTAINER_TOOLTIP =
  'Number is the count of signals failed to process till date.';
export const RECEIVED_SIGNALS_GRAPH_TOOLTIP =
  'Graph shows the Signals processed over selected time period.';
export const RECEIVED_TRENDS_GRAPH_TOOLTIP =
  'This depicts the popularity of tags selected over a period of time. Scale of 0-100, where 100 shows most and 0 the least popular.';

export const TRENDS_CONTAINER_TOOLTIP =
  'Trends represent a visual reference to indicate the popularity of selected technologies across selected time frame.';
export const SIGNALS_PROCESSED_TOOLTIP =
  'For every predefined time interval we pull signals (articles, newsletters, writeups, etc.) into the system. These are the total number of signals processed till date.';
export const READLIST_TOOLTIP =
  'Based on certain logic, we club the signals into various Readlists (just like music playlist), which can help understand a technology for R&D purposes.These are total number of readlists generated till date.';
export const SOURCES_TOOLTIP =
  'These are different knowledge banks, from which we find new signals. These are total number of sources which we observe for new signals';

export const MONTHS_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const TRENDS_CUSTOM_DAYS = {
  '0-60': 'days',
  '60-730': 'month',
  730: 'year',
};

export const READLISTS_CUSTOM_DAYS = {
  '0-60': 'days',
  '60-730': 'month',
  730: 'year',
};

export const SIGNALS_CUSTOM_DAYS = {
  '0-60': 'day',
  '60-730': 'month',
  730: 'year',
};
