import ApiUtils from './apiUtils';

/**
 * Fetch user
 * @param {Object} param
 */
const fetchUser = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);
  try {
    const response = await ApiUtils.get(`users?${queryString}`);
    const { data } = response;
    if (data && data.data.length > 0) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Put user by Id
 * @param {Object} param
 */
const putUserById = async (param) => {
  try {
    const response = await ApiUtils.put(`users/${param.id}`, param.data);
    const { data } = response;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: null,
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchUser,
  putUserById,
};
