/**
 * Import constant types
 */
import { tagsActionTypes } from './tagsTypes';

/**
 *
 * @function :  tagsReducer
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */
const tagsReducer = (state = {}, action) => {
  switch (action.type) {
    case tagsActionTypes.FETCH_TAGS_REQUESTED:
      return { ...state, isLoading: true };

    case tagsActionTypes.FETCH_TAGS_SUCCESSFULL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case tagsActionTypes.FETCH_TAGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case tagsActionTypes.PUT_TAGS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case tagsActionTypes.PUT_TAGS_SUCCESSFULL: {
      const updatedData = state.data.filter(
        (el) => !(el.signalId === action.payload.data.signalId)
      );
      return {
        ...state,
        data: updatedData,
        isLoading: false,
        isUpdateSuccess: true,
      };
    }
    case tagsActionTypes.PUT_TAGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case tagsActionTypes.TAGS_RECORD_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: action.payload,
      };
    default:
      return state;
  }
};

export default tagsReducer;
