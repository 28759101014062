import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const Footer = () => (
  <div className="footer">
    <Container fluid>
      <Row className="align-items-center">
        <Col xs={12}>
          <p className="version-text">Version 1.0.0</p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;
