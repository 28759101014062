import { put, call, takeLatest } from 'redux-saga/effects';

import {
  FETCH_SIGNALS_REQUESTED,
  FETCH_SIGNALS_GRAPH_REQUESTED,
  FETCH_SIGNALS_LIST_REQUESTED,
} from '../../common/constants/actions';
import {
  fetchSignalSuccessfull,
  fetchSignalFailure,
  fetchSignalGraphSuccessfull,
  fetchSignalGraphFailure,
  fetchSignalListSuccessfull,
  fetchSignalListFailure,
} from './signalsActions';
import signalsApi from '../../api/signalsApi';

/**
 * Featch sinals
 * @param {Object} action
 */
function* fetchSignals(action) {
  const { response, error } = yield call(signalsApi.fetchSignals, action.payload);
  if (response) {
    yield put(fetchSignalSuccessfull(response));
  } else {
    yield put(fetchSignalFailure(error));
  }
}

/**
 * Featch sinals graph
 * @param {Object} action
 */
function* fetchSignalsGraph(action) {
  const { response, error } = yield call(signalsApi.fetchSignalsGraph, action.payload);
  if (response) {
    yield put(fetchSignalGraphSuccessfull(response));
  } else {
    yield put(fetchSignalGraphFailure(error));
  }
}

/**
 * Featch sinals list
 * @param {Object} action
 */
function* fetchSignalsList(action) {
  const { response, count, error } = yield call(signalsApi.fetchSignalsList, action.payload);

  if (response) {
    yield put(fetchSignalListSuccessfull({ response, count }));
  } else {
    yield put(fetchSignalListFailure(error));
  }
}

export default function* signalsSaga() {
  yield takeLatest(FETCH_SIGNALS_REQUESTED, fetchSignals);
  yield takeLatest(FETCH_SIGNALS_GRAPH_REQUESTED, fetchSignalsGraph);
  yield takeLatest(FETCH_SIGNALS_LIST_REQUESTED, fetchSignalsList);
}
