import React from 'react';
import { useSelector } from 'react-redux';
import { ListGroup } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { TIME_FORMAT_BACKEND, NUMBER_OF_DAYS } from '../constants/common';
import UserAuth from '../utils/userAuth';
import { VIEW_TRENDS_PAGE, VIEW_SIGNALS_PAGE, VIEW_READLIST_PAGE } from '../constants/roles';

const SidebarContainer = ({ isOpen }) => {
  const { loginUserGroups } = useSelector((state) => state.auth);

  const location = useLocation();
  const today = new Date();
  const fromDate = moment(today).subtract(NUMBER_OF_DAYS, 'days').format(TIME_FORMAT_BACKEND);
  const toDate = moment(today).format(TIME_FORMAT_BACKEND);

  const searchTag = useSelector((state) => state.searchTag);
  const searchTrend = searchTag ? (searchTag.searchTag ? `tags=${searchTag.searchTag}` : '') : '';
  let searchTrendTags = '/trends';

  if (searchTag && searchTag.searchTag) {
    searchTrendTags = `/trends?fromDate=${fromDate}&toDate=${toDate}&${searchTrend}&groupBy=days`;
  }

  const signalsLink = searchTag ? (searchTag.searchTag ? `tags=${searchTag.searchTag}` : '') : '';
  let signalsLinkTags = '/signals';

  if (searchTag && searchTag.searchTag) {
    signalsLinkTags = `/signals?fromDate=${fromDate}&toDate=${toDate}&${signalsLink}&groupBy=day&limit=6&order=createdAt%2CDESC`;
  } else {
    signalsLinkTags = `/signals?fromDate=${fromDate}&toDate=${toDate}&groupBy=day&limit=6&order=createdAt%2CDESC`;
  }

  const readlistsLink = searchTag ? (searchTag.searchTag ? `tags=${searchTag.searchTag}` : '') : '';
  let readlistsLinkTags = '/readlist';

  if (searchTag && searchTag.searchTag) {
    readlistsLinkTags = `/readlist?fromDate=${fromDate}&toDate=${toDate}&${readlistsLink}&groupBy=days&limit=6&order=score%2Cdesc`;
  } else {
    readlistsLinkTags = `/readlist?fromDate=${fromDate}&toDate=${toDate}&groupBy=days&limit=6&order=score%2Cdesc`;
  }

  return (
    <div className="sidebar-wrapper">
      <div className={`${isOpen ? 'sidebar sidebaractive' : 'sidebar'}`}>
        <ListGroup variant="flush">
          <ListGroup.Item className={location.pathname === '/' ? 'active' : ''}>
            <div className="item">
              <Link to="/" className={location.pathname === '/' ? 'disable' : ''}>
                <span className="cmp-home icon" />
                <span className="icon-text">Home</span>
              </Link>
            </div>
          </ListGroup.Item>
          {UserAuth.can(VIEW_TRENDS_PAGE, loginUserGroups) ? (
            <ListGroup.Item className={location.pathname === '/trends' ? 'active' : ''}>
              <div className="item">
                <Link
                  to={searchTrendTags}
                  className={location.pathname === '/trends' ? 'disable' : ''}
                >
                  <span className="cmp-trends icon" />
                  <span className="icon-text">Trends</span>
                </Link>
              </div>
            </ListGroup.Item>
          ) : null}
          {UserAuth.can(VIEW_SIGNALS_PAGE, loginUserGroups) ? (
            <ListGroup.Item className={location.pathname === '/signals' ? 'active' : ''}>
              <div className="item">
                <Link
                  to={signalsLinkTags}
                  className={location.pathname === '/signals' ? 'disable' : ''}
                >
                  <span className="cmp-signals icon" />
                  <span className="icon-text">Signals</span>
                </Link>
              </div>
            </ListGroup.Item>
          ) : null}
          {UserAuth.can(VIEW_READLIST_PAGE, loginUserGroups) ? (
            <ListGroup.Item className={location.pathname === '/readlist' ? 'active' : ''}>
              <div className="item">
                <Link
                  to={readlistsLinkTags}
                  className={location.pathname === '/readlist' ? 'disable' : ''}
                >
                  <span className="cmp-readlist icon" />
                  <span className="icon-text">Readlists</span>
                </Link>
              </div>
            </ListGroup.Item>
          ) : null}
        </ListGroup>
      </div>
    </div>
  );
};
export default SidebarContainer;
