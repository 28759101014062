import React from 'react';
import { kFormatter } from '../../../common/utils/helpers';

/**
 *
 * @param {number} receivedCounters:count to display on card for sucessful processed signals
 * @returns JSX with the signal count
 */
const ReceivedSignalCount = ({ receivedCounters }) => (
  <>
    <div className="count-wrapper failed-processing">
      <div className="count-box">
        <p className="content-heading text-yellow compass-yellow">
          {kFormatter(receivedCounters || 0)}
        </p>
        <p className="count-text">Total</p>
      </div>
    </div>
  </>
);
export default ReceivedSignalCount;
