/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_SIGNALS_REQUESTED,
  FETCH_SIGNALS_SUCCESSFULL,
  FETCH_SIGNALS_FAILURE,
  FETCH_SIGNALS_GRAPH_REQUESTED,
  FETCH_SIGNALS_GRAPH_SUCCESSFULL,
  FETCH_SIGNALS_GRAPH_FAILURE,
  FETCH_SIGNALS_LIST_REQUESTED,
  FETCH_SIGNALS_LIST_SUCCESSFULL,
  FETCH_SIGNALS_LIST_FAILURE,
} from '../../common/constants/actions';

/**
 * Export the types for reducers, actions and sagas
 */
export const SignalsActionTypes = {
  FETCH_SIGNALS_REQUESTED,
  FETCH_SIGNALS_SUCCESSFULL,
  FETCH_SIGNALS_FAILURE,
  FETCH_SIGNALS_GRAPH_REQUESTED,
  FETCH_SIGNALS_GRAPH_SUCCESSFULL,
  FETCH_SIGNALS_GRAPH_FAILURE,
  FETCH_SIGNALS_LIST_REQUESTED,
  FETCH_SIGNALS_LIST_SUCCESSFULL,
  FETCH_SIGNALS_LIST_FAILURE,
};
