import { tagsActionTypes } from './tagsTypes';

export const fetchTagsRequested = (query) => ({
  type: tagsActionTypes.FETCH_TAGS_REQUESTED,
  payload: query,
});

export const fetchTagsSuccessfull = (tags) => ({
  type: tagsActionTypes.FETCH_TAGS_SUCCESSFULL,
  payload: tags,
});

export const fetchTagsFailure = (error) => ({
  type: tagsActionTypes.FETCH_TAGS_FAILURE,
  payload: error,
});

export const putTagsRequested = (query) => ({
  type: tagsActionTypes.PUT_TAGS_REQUESTED,
  payload: query,
});

export const putTagsSuccessfull = (user) => ({
  type: tagsActionTypes.PUT_TAGS_SUCCESSFULL,
  payload: user,
});

export const putTagsFailure = (error) => ({
  type: tagsActionTypes.PUT_TAGS_FAILURE,
  payload: error,
});

export const setTagsRecordUpdateStatus = (status) => ({
  type: tagsActionTypes.TAGS_RECORD_UPDATE_SUCCESS,
  payload: status,
});
