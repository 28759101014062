import React, { useState } from 'react';
import moment from 'moment';
import { Col, Row, ListGroup, Badge, Button } from 'react-bootstrap';
import { TIME_FORMAT } from '../../../common/constants/common';
import Loading from '../../../common/components/Loading';
import Error from '../../../common/components/Error';
import PaginationComponent from '../../../common/components/PaginationComponent';
import DetailView from './DetailView';

const renderTags = (tag, index) => <Badge key={index}>{tag}</Badge>;
const SignalGridView = (item) => {
  const [showDetailModal, setShowDetailModal] = useState(false);

  return (
    <>
      <Col md={6} lg={4} className="d-flex" key={item.signalId}>
        <div className="card-box">
          <div>
            <div className="card-date-wrap">
              <p className="card-date">Date: {moment(item.date).format(TIME_FORMAT)}</p>
            </div>
            <p className="grey-btn">
              Title:{' '}
              <a href={item.url} target="_blank" rel="noreferrer">
                {item.title}
              </a>
            </p>
            <div className="card-list">
              <ListGroup variant="flush">
                <ListGroup.Item>Signal Type: {item.signalType}</ListGroup.Item>
                <ListGroup.Item className="source-text">Source: {item.source}</ListGroup.Item>
              </ListGroup>
            </div>
            <div className="mt-4">
              <p className="tag-title"> Tags: </p>
              <p className="grey-btn">
                {item.tags && item.tags.length > 0
                  ? item.tags.sort().map((tag, index) => renderTags(tag, index))
                  : ''}
              </p>
            </div>
            <div className="d-flex justify-content-end mt-4 flex-wrap w-100">
              <Button className="download-btn" onClick={() => setShowDetailModal(true)}>
                Details
              </Button>
            </div>
          </div>
        </div>
      </Col>
      {showDetailModal ? <div className="back-drop" /> : null}
      <div
        className="add-config-form readlists-detail"
        style={{
          transform: showDetailModal ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        {showDetailModal ? (
          <DetailView setShowDetailModal={setShowDetailModal} signalsObj={item} />
        ) : null}
      </div>
    </>
  );
};

const GridView = ({ signalsListData, setOffsetHandler, offset, summaryData }) => {
  const { isLoading, isError, data } = signalsListData;
  const { isLoading: isSummaryLoading, isError: isSummaryError } = summaryData;
  if (isLoading) return <Loading />;
  if (isError) return <Error />;
  return (
    <div className="card-wrapper">
      <Row>{data ? data.map((item) => SignalGridView(item)) : null}</Row>
      {isSummaryLoading ? (
        <Loading />
      ) : isSummaryError ? (
        <Error />
      ) : (
        <PaginationComponent
          recordCount={signalsListData.count}
          setOffsetHandler={setOffsetHandler}
          offset={offset}
        />
      )}
    </div>
  );
};
export default GridView;
