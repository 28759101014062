import ApiUtils from './apiUtils';

/**
 * Fetch configuration
 * @param {Object} param: searchTerm which is empty to fetch all the configuration data
 */
const fetchConfiguration = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);
  try {
    const response = await ApiUtils.get(`configurations?${queryString}`);
    const { data } = response;
    if (data && data.data.length > 0) {
      return {
        response: data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Put configuration by Id
 * @param {Object} param:key of configuration
 */
const putConfigurationById = async (param) => {
  try {
    const response = await ApiUtils.put(`configurations/${param.key}`, param.data);
    const { data } = response;
    if (data) {
      return {
        response: data,
      };
    }
    return {
      response: null,
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchConfiguration,
  putConfigurationById,
};
