import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import EditTag from './components/EditTag';
import {
  MSG_EXPIRY_TIME,
  SEARCH_FILTER_PLACEHOLDER,
  NO_DATA_FOUND_MESAGE,
  TIME_FORMAT,
} from '../../common/constants/common';
import { fetchTagsRequested, setTagsRecordUpdateStatus } from '../../state/tags/tagsActions';
import Loading from '../../common/components/Loading';
import Error from '../../common/components/Error';

const Tags = () => {
  const [showTag, setShowTag] = useState(false);
  const [editTag, setEditTag] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const closeTag = () => setShowTag(false);
  const dispatch = useDispatch();
  const { data, isLoading, isError, isUpdateSuccess } = useSelector((state) => state.tags);
  /**
   * Disptach API call for getting configuration data
   */
  const getData = () => {
    dispatch(
      fetchTagsRequested({
        searchTerm: '',
      })
    );
  };

  /**
   * Show edit configuration model
   * @param {object} configutaion
   */
  const editTagModal = (tag) => {
    setShowTag(true);
    setEditTag(tag);
  };

  useEffect(() => {
    if (isLoading && data.length === 0) {
      getData();
    } else {
      const datatableData = data.map((record) => ({
        signalTitle: record.title,
        url: (
          <a href={record.url} target="_blank" rel="noreferrer">
            {record.url}
          </a>
        ),
        signalId: record.signalId,
        signalType: record.signalType,
        source: record.source,
        signalDate: record.date,
        tags: record.tags.join(),
        actions: (
          <Button className="btn me-1" onClick={() => editTagModal(record)}>
            Add Tags
          </Button>
        ),
      }));
      setFilteredData(datatableData);
    }

    // close edit status successfull message
    if (isUpdateSuccess) {
      closeTag();
      setTimeout(() => {
        dispatch(setTagsRecordUpdateStatus(false));
      }, MSG_EXPIRY_TIME);
    }
  }, [isLoading, isUpdateSuccess]);

  const [filterToggle, setFilterToggle] = useState(false);
  const toggleFilter = () => setFilterToggle(!filterToggle);

  const TAGS_TABLE_HEADING = [
    {
      dataField: 'signalTitle',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: filterToggle ? textFilter(SEARCH_FILTER_PLACEHOLDER) : null,
      classes: 'col-3 url',
      headerClasses: 'col-3 url',
      text: (
        <>
          <Button className="filter-btn" onClick={toggleFilter}>
            <span className="cmp-search" />
          </Button>
          Signal Title
        </>
      ),
    },
    {
      dataField: 'signalType',
      text: 'Signal Type',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      filter: filterToggle ? textFilter(SEARCH_FILTER_PLACEHOLDER) : null,
      classes: 'col-2',
      headerClasses: 'col-2',
    },
    {
      dataField: 'url',
      text: 'URL',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      filter: filterToggle ? textFilter(SEARCH_FILTER_PLACEHOLDER) : null,
      classes: 'col-3 url',
      headerClasses: 'col-3 url',
    },
    {
      dataField: 'source',
      text: 'Source',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      filter: filterToggle ? textFilter(SEARCH_FILTER_PLACEHOLDER) : null,
      classes: 'col-2',
      headerClasses: 'col-2',
    },
    {
      dataField: 'signalDate',
      text: 'Signal Date',
      sort: true,

      formatter: (value) => moment(value).format(TIME_FORMAT),
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return new Date(a) - new Date(b);
        }
        return new Date(b) - new Date(a);
      },
      align: 'center',
      headerAlign: 'center',
      filter: filterToggle ? textFilter(SEARCH_FILTER_PLACEHOLDER) : null,
      classes: 'col-1',
      headerClasses: 'col-1',
    },
    {
      dataField: 'actions',
      text: 'Action',
      align: 'center',
      headerAlign: 'center',
      classes: 'col-1',
      headerClasses: 'col-1',
    },
  ];

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div>
            <Row>
              <Col md={12} xl={12} className="d-flex mb-12 mb-md-0">
                {isUpdateSuccess ? (
                  <Alert className="mb-4" variant="success">
                    Record updated successfully
                  </Alert>
                ) : null}
              </Col>
            </Row>
            {isLoading ? (
              <Loading />
            ) : isError ? (
              <Error />
            ) : (
              <>
                <div className="table-responsive">
                  <BootstrapTable
                    keyField="signalId"
                    data={filteredData}
                    columns={TAGS_TABLE_HEADING}
                    filter={filterFactory()}
                    classes="compass-table"
                    filterPosition="inline"
                    bordered={false}
                    scrollY="200px"
                  />
                </div>
                {filteredData.length === 0 ? (
                  <div className="no-data-found">
                    <p>{NO_DATA_FOUND_MESAGE}</p>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </Col>
      </Row>
      {/* EDIT CONFIGURATION STARTS */}
      {showTag ? <div className="back-drop" /> : null}
      <div
        className="add-config-form"
        style={{
          transform: showTag ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        {showTag ? <EditTag show={showTag} editObj={editTag} close={closeTag} /> : null}
      </div>
      {/* EDIT CONFIGURATION ENDS */}
    </div>
  );
};
export default Tags;
