import { put, call, takeLatest } from 'redux-saga/effects';

import { DashBoardActionTypes } from './dashboardTypes';
import { fetchDashBoardSuccessfull, fetchDashBoardFailure } from './dashboardActions';
import dashBoardApi from '../../api/dashBoardApi';

/**
 * Featch dashboard
 * @param {Object} action
 */
function* fetchDashBoard(action) {
  const { response, error } = yield call(dashBoardApi.fetchDashboard, action.payload);
  if (response) {
    yield put(fetchDashBoardSuccessfull(response));
  } else {
    yield put(fetchDashBoardFailure(error));
  }
}

export default function* dashboardSaga() {
  yield takeLatest(DashBoardActionTypes.FETCH_DASHBOARD_REQUESTED, fetchDashBoard);
}
