import { SourcesTypesTypes } from './sourcesTypesTypes';

export const fetchSourcesRequested = (query) => ({
  type: SourcesTypesTypes.FETCH_SOURCES_REQUESTED,
  payload: query,
});

export const fetchSourcesSuccessfull = (sources) => ({
  type: SourcesTypesTypes.FETCH_SOURCES_SUCCESSFULL,
  payload: sources,
});

export const fetchSourcesFailure = (error) => ({
  type: SourcesTypesTypes.FETCH_SOURCES_FAILURE,
  payload: error,
});

export const fetchTypesRequested = (query) => ({
  type: SourcesTypesTypes.FETCH_TYPES_REQUESTED,
  payload: query,
});

export const fetchTypesSuccessfull = (types) => ({
  type: SourcesTypesTypes.FETCH_TYPES_SUCCESSFULL,
  payload: types,
});

export const fetchTypesFailure = (error) => ({
  type: SourcesTypesTypes.FETCH_TYPES_FAILURE,
  payload: error,
});

export const putSourcesRequested = (query) => ({
  type: SourcesTypesTypes.PUT_SOURCES_REQUESTED,
  payload: query,
});

export const putSourcesSuccessfull = (user) => ({
  type: SourcesTypesTypes.PUT_SOURCES_SUCCESSFULL,
  payload: user,
});

export const putSourcesFailure = (error) => ({
  type: SourcesTypesTypes.PUT_SOURCES_FAILURE,
  payload: error,
});

export const setSourcesRecordUpdateStatus = (status) => ({
  type: SourcesTypesTypes.SOURCES_RECORD_UPDATE_SUCCESS,
  payload: status,
});

export const postSourcesRequested = (query) => ({
  type: SourcesTypesTypes.POST_SOURCES_REQUESTED,
  payload: query,
});

export const postSourcesSuccessfull = (user) => ({
  type: SourcesTypesTypes.POST_SOURCES_SUCCESSFULL,
  payload: user,
});

export const postSourcesFailure = (error) => ({
  type: SourcesTypesTypes.POST_SOURCES_FAILURE,
  payload: error,
});

export const setSourcesRecordAddStatus = (status) => ({
  type: SourcesTypesTypes.SOURCES_RECORD_ADD_SUCCESS,
  payload: status,
});
