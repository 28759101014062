import { combineReducers } from 'redux';

import configurationReducer from './configuration/configurationReducer';
import trendsReducer from './trends/trendsReducer';
import userReducer from './user/userReducer';
import signalsReducer from './signals/signalsReducer';
import tagsReducer from './tags/tagsReducer';
import areaSubAreaTagsReducer from './areaSubAreaTags/areaSubAreaTagsReducer';
import readListReducer from './readList/readListReducer';
import dashBoardReducer from './dashboard/dashboardReducer';
import typeheadReducer from './typehead/typeheadReducer';
import sourcesTypesReducers from './sourcesTypes/sourcesTypesReducers';
import searchTagReducer from './searchTag/searchTagReducer';
import authReducer from './auth/authReducer';
import reportReducers from './report/reportReducers';

const rootReducer = combineReducers({
  configuration: configurationReducer,
  trends: trendsReducer,
  user: userReducer,
  signals: signalsReducer,
  tags: tagsReducer,
  areaSubAreaTags: areaSubAreaTagsReducer,
  readList: readListReducer,
  dashboard: dashBoardReducer,
  typehead: typeheadReducer,
  sourcesTypes: sourcesTypesReducers,
  searchTag: searchTagReducer,
  auth: authReducer,
  report: reportReducers,
});

export default rootReducer;
