import { TypeheadActionTypes } from './typeheadTypes';

export const fetchTypeheadRequested = (query) => ({
  type: TypeheadActionTypes.FETCH_TYPEHEAD_REQUESTED,
  payload: query,
});

export const fetchTypeheadSuccessfull = (configuration) => ({
  type: TypeheadActionTypes.FETCH_TYPEHEAD_SUCCESSFULL,
  payload: configuration,
});

export const fetchTypeheadFailure = (error) => ({
  type: TypeheadActionTypes.FETCH_TYPEHEAD_FAILURE,
  payload: error,
});
