import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Row, Col, Container } from 'react-bootstrap';
import Parameters from '../Parameters/Parameters';
import Tags from '../Tags/Tags';
import PageHeading from '../../common/components/PageHeading';
import Sources from '../Sources/Sources';

const ConfigurationDemo = (props) => {
  const defaultTab = new URLSearchParams(props.location.search).get('tabName') || 'Parameters';
  const [key, setKey] = useState(defaultTab);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [defaultTab]);
  return (
    <div className="configuration main">
      <Container fluid>
        <Row>
          <Col>
            <div className="mb-5">
              <PageHeading pageHeading="Configuration" />
            </div>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-4"
            >
              <Tab eventKey="Parameters" title="Parameters">
                <Parameters />
              </Tab>
              <Tab eventKey="Sources" title="Sources">
                <Sources />
              </Tab>
              <Tab eventKey="Signals" title="Failed Signals">
                <Tags />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ConfigurationDemo;
