import { authTypes } from './authTypes';

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case authTypes.SET_LOGIN_USER_GROUP:
      return { ...state, loginUserGroups: action.payload };

    default:
      return state;
  }
};

export default authReducer;
