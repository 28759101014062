/**
 * Import constant types
 */
import { TypeheadActionTypes } from './typeheadTypes';

/**
 *
 * @function :  typeheadReducer
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */
const typeheadReducer = (state = {}, action) => {
  switch (action.type) {
    case TypeheadActionTypes.FETCH_TYPEHEAD_REQUESTED:
      return { ...state, isLoading: true };

    case TypeheadActionTypes.FETCH_TYPEHEAD_SUCCESSFULL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case TypeheadActionTypes.FETCH_TYPEHEAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default typeheadReducer;
