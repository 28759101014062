import ApiUtils from './apiUtils';

/**
 * Fetch signals
 * @param {Object} param
 */
const fetchSignals = async (param) => {
  try {
    const response = await ApiUtils.get(`signals/summary${param}`);
    const { data } = response;
    if (data) {
      return {
        response: data[0],
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Fetch signals graph
 * @param {Object} param
 */
const fetchSignalsGraph = async (param) => {
  try {
    const response = await ApiUtils.get(`signals/chart${param}`);
    const { data } = response;
    if (data && data.data.length > 0) {
      return {
        response: data.data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

/**
 * Fetch signals list
 * @param {Object} param
 */
const fetchSignalsList = async (param) => {
  try {
    const response = await ApiUtils.get(`signals${param}`);
    const { data } = response;

    if (data && data.data.signals.length > 0) {
      return {
        response: data.data.signals,
        count: data.data.count[0].signalCount,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const signalDownload = async (id, search) => {
  try {
    const response = await ApiUtils.get(`signals/${id}/download${search}`);
    const { data } = response;
    if (data) {
      return {
        response: data.data,
      };
    }
    return {
      response: [],
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};
export default {
  fetchSignals,
  fetchSignalsGraph,
  fetchSignalsList,
  // signalsDownload,
  signalDownload,
};
