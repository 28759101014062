import { UserActionTypes } from './userTypes';

export const fetchUserRequested = (query) => ({
  type: UserActionTypes.FETCH_USER_REQUESTED,
  payload: query,
});

export const fetchUserSuccessfull = (user) => ({
  type: UserActionTypes.FETCH_USER_SUCCESSFULL,
  payload: user,
});

export const fetchUserFailure = (error) => ({
  type: UserActionTypes.FETCH_USER_FAILURE,
  payload: error,
});

export const putUserRequested = (query) => ({
  type: UserActionTypes.PUT_USER_REQUESTED,
  payload: query,
});

export const putUserSuccessfull = (user) => ({
  type: UserActionTypes.PUT_USER_SUCCESSFULL,
  payload: user,
});

export const putUserFailure = (error) => ({
  type: UserActionTypes.PUT_USER_FAILURE,
  payload: error,
});

export const setUserRecordUpdateStatus = (status) => ({
  type: UserActionTypes.USER_RECORD_UPDATE_SUCCESS,
  payload: status,
});
