import React, { useState } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { TIME_FORMAT } from '../../../common/constants/common';
import Loading from '../../../common/components/Loading';
import Error from '../../../common/components/Error';
import PaginationComponent from '../../../common/components/PaginationComponent';
import DetailView from './DetailView';
import Download from './Download';
/**
 *
 * @param {object} :with data for redalist and pagination details
 * @returns list-view for received data of readlist
 */
const ListView = ({ readListListData, setOffsetHandler, offset, summaryData }) => {
  const { isLoading, isError, data } = readListListData;
  const [detailsData, setDetailsData] = useState(null);
  const [isErrorDownload, setIsError] = useState(false);

  const { isLoading: isSummaryLoading, isError: isSummaryError } = summaryData;

  if (isLoading) return <Loading />;
  if (isError) return <Error />;
  const [showDetailModal, setShowDetailModal] = useState(false);

  const listdata = data
    ? data.map((item) => (
        <>
          <tr>
            <td className="col-md-1">{item.title}</td>
            <td className="col-md-1 text-center">{item.signalCount}</td>
            <td className="col-md-7 tags">
              {item.tags != null ? (
                ((
                  <span className="compass-yellow">
                    {item.tags.major.split(',').sort().join(', ')}
                  </span>
                ),
                (<span> {item.tags.minor.split(',').sort().join(', ')}</span>))
              ) : item.technologyList.length > 0 ? (
                <span> {item.technologyList.sort().join(', ')}</span>
              ) : (
                <span> {item.title}</span>
              )}
            </td>
            <td className="col-md-1 text-center">
              {moment(item.orderCreatedAt).format(TIME_FORMAT)}
            </td>
            <td className="col-md-1 text-center">
              <Button
                className="download-btn"
                onClick={() => {
                  setShowDetailModal(true);
                  setDetailsData(item);
                }}
              >
                Details
              </Button>
            </td>
            <td className="col-md-1">
              <Download
                setIsError={setIsError}
                categoryId={item.id}
                readListName={item.title}
                date={moment(item.createdAt).format(TIME_FORMAT)}
              />
              {isErrorDownload ? <p className="download-failed">Download failed</p> : null}
            </td>
          </tr>
        </>
      ))
    : [];

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  return (
    <>
      <div className="table-responsive grid-list-table">
        <table className="table table-borderless compass-table">
          <thead>
            <th>Parent</th>
            <th className="text-center">Signal Count</th>
            <th>Tags</th>
            <th className="text-center">Date</th>
            <th className="text-center">Details</th>
            <th className="text-center">Action</th>
          </thead>
          <tbody>{listdata}</tbody>
        </table>
        {showDetailModal ? <div className="back-drop" /> : null}
        <div
          className="add-config-form readlists-detail"
          style={{
            transform: showDetailModal ? 'translateX(0)' : 'translateX(100%)',
          }}
        >
          {showDetailModal ? (
            <DetailView setShowDetailModal={setShowDetailModal} readListObj={detailsData} />
          ) : null}
        </div>
      </div>
      {isSummaryLoading ? (
        <Loading />
      ) : isSummaryError ? (
        <Error />
      ) : (
        <PaginationComponent
          recordCount={readListListData.recordCount}
          setOffsetHandler={setOffsetHandler}
          offset={offset}
        />
      )}
    </>
  );
};
export default ListView;
