/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_OVERVIEW_REQUESTED,
  FETCH_OVERVIEW_SUCCESSFULL,
  FETCH_OVERVIEW_FAILURE,
  FETCH_REPORT_TAGS_REQUESTED,
  FETCH_REPORT_TAGS_SUCCESSFULL,
  FETCH_REPORT_TAGS_FAILURE,
  FETCH_REPORT_SOURCES_REQUESTED,
  FETCH_REPORT_SOURCES_SUCCESSFULL,
  FETCH_REPORT_SOURCES_FAILURE,
  FETCH_REPORT_SIGNALS_REQUESTED,
  FETCH_REPORT_SIGNALS_SUCCESSFULL,
  FETCH_REPORT_SIGNALS_FAILURE,
  FETCH_REPORT_READLISTS_REQUESTED,
  FETCH_REPORT_READLISTS_SUCCESSFULL,
  FETCH_REPORT_READLISTS_FAILURE,
} from '../../common/constants/actions';

/**
 * Export the actions for reducers, actions and sagas
 */
export const ReportTypes = {
  FETCH_OVERVIEW_REQUESTED,
  FETCH_OVERVIEW_SUCCESSFULL,
  FETCH_OVERVIEW_FAILURE,
  FETCH_REPORT_TAGS_REQUESTED,
  FETCH_REPORT_TAGS_SUCCESSFULL,
  FETCH_REPORT_TAGS_FAILURE,
  FETCH_REPORT_SOURCES_REQUESTED,
  FETCH_REPORT_SOURCES_SUCCESSFULL,
  FETCH_REPORT_SOURCES_FAILURE,
  FETCH_REPORT_SIGNALS_REQUESTED,
  FETCH_REPORT_SIGNALS_SUCCESSFULL,
  FETCH_REPORT_SIGNALS_FAILURE,
  FETCH_REPORT_READLISTS_REQUESTED,
  FETCH_REPORT_READLISTS_SUCCESSFULL,
  FETCH_REPORT_READLISTS_FAILURE,
};
