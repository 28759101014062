import { put, call, takeLatest } from 'redux-saga/effects';
import { ReportTypes } from './reportTypes';
import {
  fetchOverviewSuccessfull,
  fetchOverviewFailure,
  fetchReportTagsSuccessfull,
  fetchReportTagsFailure,
  fetchReportSignalsFailure,
  fetchReportSourcesFailure,
  fetchReportSignalsSuccessfull,
  fetchReportSourcesSuccessfull,
  fetchReportReadlsitsSuccessfull,
  fetchReportReadlsitsFailure,
} from './reportActions';

import reportApi from '../../api/reportApi';

/**
 * Fetch Types
 * @param {Object} action
 */
function* fetchOverview(action) {
  const { response } = yield call(reportApi.fetchOverview, action.payload);
  if (response) {
    yield put(fetchOverviewSuccessfull(response));
  } else {
    yield put(fetchOverviewFailure(response));
  }
}

function* fetchReportTags(action) {
  const { response } = yield call(reportApi.fetchTags, action.payload);
  if (response) {
    yield put(fetchReportTagsSuccessfull(response));
  } else {
    yield put(fetchReportTagsFailure(response));
  }
}

function* fetchReportSignals(action) {
  const { response } = yield call(reportApi.fetchSignals, action.payload);
  if (response) {
    yield put(fetchReportSignalsSuccessfull(response));
  } else {
    yield put(fetchReportSignalsFailure(response));
  }
}

function* fetchReportSources(action) {
  const { response } = yield call(reportApi.fetchSources, action.payload);
  if (response) {
    yield put(fetchReportSourcesSuccessfull(response));
  } else {
    yield put(fetchReportSourcesFailure(response));
  }
}

function* fetchReportReadlists(action) {
  const { response } = yield call(reportApi.fetchReadlists, action.payload);
  if (response) {
    yield put(fetchReportReadlsitsSuccessfull(response));
  } else {
    yield put(fetchReportReadlsitsFailure(response));
  }
}

export default function* reportSaga() {
  yield takeLatest(ReportTypes.FETCH_OVERVIEW_REQUESTED, fetchOverview);
  yield takeLatest(ReportTypes.FETCH_REPORT_TAGS_REQUESTED, fetchReportTags);
  yield takeLatest(ReportTypes.FETCH_REPORT_SIGNALS_REQUESTED, fetchReportSignals);
  yield takeLatest(ReportTypes.FETCH_REPORT_SOURCES_REQUESTED, fetchReportSources);
  yield takeLatest(ReportTypes.FETCH_REPORT_READLISTS_REQUESTED, fetchReportReadlists);
}
