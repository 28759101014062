import React from 'react';
import { Link } from 'react-router-dom';
import { kFormatter } from '../../../common/utils/helpers';

/**
 *
 * @param {number} failedProcessCount:count to display on card for failed signals
 * @returns
 */
const FailedProcessingSignal = ({ failedProcessCount }) => (
  <>
    <div className="count-wrapper failed-processing">
      <div className="count-box">
        <Link to="/configuration?tabName=Signals">
          <p className="content-heading text-end compass-red">{kFormatter(failedProcessCount)}</p>
          <p className="count-text">Total</p>
        </Link>
      </div>
    </div>
  </>
);

export default FailedProcessingSignal;
