import React, { useState } from 'react';
import moment from 'moment';
import { Col, Row, ListGroup, Badge, Button } from 'react-bootstrap';
import { TIME_FORMAT } from '../../../common/constants/common';
import Loading from '../../../common/components/Loading';
import Error from '../../../common/components/Error';
import PaginationComponent from '../../../common/components/PaginationComponent';
import Download from './Download';
import DetailView from './DetailView';

const renderTags = (tag, index) => <Badge key={index}>{tag}</Badge>;
const SignalGridView = (item) => {
  const [isError, setIsError] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  return (
    <>
      <Col md={6} lg={4} className="d-flex" key={item.signalId}>
        <div className="card-box">
          <div>
            <div className="card-date-wrap">
              <p className="card-date">Date: {moment(item.orderCreatedAt).format(TIME_FORMAT)}</p>
              <p className="grey-btn">Parent: {item.title}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="grey-btn">Signal:</p>
              <Button
                className="transparent-btn total-btn"
                onClick={() => setShowDetailModal(true)}
              >
                Total: {item.signalCount}
              </Button>
            </div>
            <div className="card-list">
              <ListGroup variant="flush">
                {item.signalMeta.slice(0, 3).map((articals) => (
                  <ListGroup.Item>{articals.signalTitle}</ListGroup.Item>
                ))}
              </ListGroup>
              {item.signalMeta.length > 3 ? (
                <div className="d-flex justify-content-end mt-2">
                  <Button
                    className="transparent-btn total-btn"
                    onClick={() => setShowDetailModal(true)}
                  >
                    <span className="compass-white"> +{item.signalMeta.length - 3} more</span>
                  </Button>
                </div>
              ) : null}
            </div>
            {item.tags != null ? (
              <div className="mt-4">
                <p className="tag-title"> Tags: </p>
                <p className="minor-major grey-btn ">
                  {item.tags.major.length > 0
                    ? item.tags.major
                        .split(',')
                        .sort()
                        .map((tag, index) => renderTags(tag, index))
                    : ''}
                </p>
                <p className="grey-btn">
                  {item.tags.minor.length > 0
                    ? item.tags.minor
                        .split(',')
                        .sort()
                        .map((tag, index) => renderTags(tag, index))
                    : ''}
                </p>
              </div>
            ) : (
              <div className="mt-4">
                <p className="tag-title"> Tags: </p>
                <p className="grey-btn ">
                  {item.technologyList.length > 0
                    ? item.technologyList.sort().map((tag, index) => renderTags(tag, index))
                    : renderTags(item.title)}
                </p>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end mt-4 flex-wrap w-100">
            <Button className="download-btn" onClick={() => setShowDetailModal(true)}>
              Details
            </Button>
            <Download
              setIsError={setIsError}
              categoryId={item.id}
              readListName={item.title}
              date={moment(item.createdAt).format(TIME_FORMAT)}
            />
            {isError ? <p className="download-failed">Download failed</p> : null}
          </div>
        </div>
      </Col>
      {showDetailModal ? <div className="back-drop" /> : null}
      <div
        className="add-config-form readlists-detail"
        style={{
          transform: showDetailModal ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        {showDetailModal ? (
          <DetailView setShowDetailModal={setShowDetailModal} readListObj={item} />
        ) : null}
      </div>
    </>
  );
};

const GridView = ({ readListListData, setOffsetHandler, offset, summaryData, selectedTitle }) => {
  const { isLoading, isError, data } = readListListData;
  const { isLoading: isSummaryLoading, isError: isSummaryError } = summaryData;
  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  return (
    <div className="card-wrapper">
      <Row>{data ? data.map((item) => SignalGridView(item, selectedTitle)) : null}</Row>
      {isSummaryLoading ? (
        <Loading />
      ) : isSummaryError ? (
        <Error />
      ) : (
        <PaginationComponent
          recordCount={readListListData.recordCount}
          setOffsetHandler={setOffsetHandler}
          offset={offset}
        />
      )}
    </div>
  );
};
export default GridView;
