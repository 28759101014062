import { put, call, takeLatest } from 'redux-saga/effects';

import { UserActionTypes } from './userTypes';
import {
  fetchUserSuccessfull,
  fetchUserFailure,
  putUserSuccessfull,
  putUserFailure,
} from './userActions';
import userApi from '../../api/userApi';

/**
 * Featch all user data
 * @param {object} action
 */
function* fetchUser(action) {
  const { response, error } = yield call(userApi.fetchUser, action.payload);
  if (response) {
    yield put(fetchUserSuccessfull(response));
  } else {
    yield put(fetchUserFailure(error));
  }
}

/**
 * Put user
 * @param {Objet} action
 */
function* putUser(action) {
  const { response, error } = yield call(userApi.putUserById, action.payload);
  if (response) {
    yield put(putUserSuccessfull(response));
  } else {
    yield put(putUserFailure(error));
  }
}
export default function* userSaga() {
  yield takeLatest(UserActionTypes.FETCH_USER_REQUESTED, fetchUser);
  yield takeLatest(UserActionTypes.PUT_USER_REQUESTED, putUser);
}
