import { put, call, takeLatest } from 'redux-saga/effects';
import { ConfigurationActionTypes } from './configurationTypes';
import {
  fetchConfigurationSuccessfull,
  fetchConfigurationFailure,
  putConfigurationSuccessfull,
  putConfigurationFailure,
} from './configurationActions';
import configurationApi from '../../api/configurationApi';

/**
 * Featch all configuration
 * @param {Object} action
 */
function* fetchConfiguration(action) {
  const { response, error } = yield call(configurationApi.fetchConfiguration, action.payload);
  if (response) {
    yield put(fetchConfigurationSuccessfull(response));
  } else {
    yield put(fetchConfigurationFailure(error));
  }
}

/**
 * Put configuration
 * @param {Object} action
 */
function* putConfiguration(action) {
  const { response, error } = yield call(configurationApi.putConfigurationById, action.payload);
  if (response) {
    yield put(putConfigurationSuccessfull(response));
  } else {
    yield put(putConfigurationFailure(error));
  }
}
export default function* configurationSaga() {
  yield takeLatest(ConfigurationActionTypes.FETCH_CONFIGURATION_REQUESTED, fetchConfiguration);
  yield takeLatest(ConfigurationActionTypes.PUT_CONFIGURATION_REQUESTED, putConfiguration);
}
