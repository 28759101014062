/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Legend,
  YAxis,
  PieChart,
  Pie,
  Cell,
  Line,
  LineChart,
} from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import * as d3 from 'd3';

import {
  fetchOverviewRequested,
  fetchReportTagsRequested,
  fetchReportSourcesRequested,
  fetchReportSignalsRequested,
  fetchReportReadlsitsRequested,
} from '../../state/report/reportActions';
import Loading from './Loading';
import {
  DATE_FORMAT_FOR_REPORT_HEADING,
  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE,
  DATE_FORMAT_FOR_DATE,
  DATE_FORMAT_FOR_CHART_LEGENDS,
} from '../constants/common';
import { dateConversion, kFormatter } from '../utils/helpers';

const totalData = [
  {
    fromVal: '421',
    toVal: '442',
    diff: 1121,
  },
];

const piChartData = [
  {
    name: 'Framework',
    value: 44.3,
  },
  {
    name: 'Tool',
    value: 25.31,
  },
  {
    name: 'Language',
    value: 24.05,
  },
  {
    name: 'Technique',
    value: 6.32,
  },
];

const areaWiseData = [
  {
    fromVal: 11,
    toVal: 36,
    diff: 35,
    key: 'Framework',
  },
  {
    fromVal: 11,
    toVal: 21,
    diff: 20,
    key: 'Tool',
  },
  {
    fromVal: 1,
    toVal: 19,
    diff: 19,
    key: 'Language',
  },
  {
    fromVal: 11,
    toVal: 6,
    diff: 5,
    key: 'Technique',
  },
];
const pageWrapper = {
  width: '297mm',
  height: '208mm',
  margin: '0 auto',
  padding: '0 ',
  position: 'relative',
};

const pageWrapper2 = {
  width: '297mm',
  height: '208mm',
  margin: '0 auto',
  padding: '0 ',
  position: 'relative',
  top: '15px',
};
const pageWrapper3 = {
  width: '297mm',
  height: '208mm',
  margin: '0 auto',
  padding: '0 ',
  position: 'relative',
  top: '130px',
};
const pageWrapper4 = {
  width: '297mm',
  height: '208mm',
  margin: '0 auto',
  padding: '0 ',
  position: 'relative',
  top: '235px',
};

const pageWrapper5 = {
  width: '297mm',
  height: '208mm',
  margin: '0 auto',
  padding: '0 ',
  position: 'relative',
  top: '185px',
};
const container = {
  color: '#fff',
};
const header = {
  color: '#fff',
};
const headerBox = {
  fontFamily: 'robotoRegular',

  background: 'rgba(10,92,144,0.8)',
  padding: '0 34px',
};
const headerTitle = {
  fontFamily: 'robotoBold',
  fontWeight: 'bold',
  fontSize: '36px',
  marginBottom: '0',
};
const totlTagWrapper = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  fontFamily: 'robotoBold',
};
const overViewbox = {
  paddingRight: '20px',
  width: '200px',
};
const overViewbox2 = {
  width: '130px',
  position: 'absolute',
  top: '-30px',
  right: '142px',
};

const overViewbox3 = {
  width: '100px',
  paddingRight: '20px',
  marginTop: '-20px',
  // paddingRight: '-20px',
};
const overviewTotaltitle = {
  fontFamily: 'robotoBold',

  fontWeight: 'bold',
  fontSize: '20px',
  marginTop: '15px',
  color: '#000',
  textAlign: 'center',
};

const overviewTotaltitle2 = {
  fontFamily: 'robotoBold',

  fontWeight: 'bold',
  fontSize: '12px',
  marginTop: '30px',
  color: '#000',
  textAlign: 'center',
};
const headerPara = {
  fontSize: '16px',
  paddingLeft: '10px',
};
const main = {
  marginTop: '10px',
  backgroundColor: '#fff',
  padding: '30px 0',
  position: 'relative',
  height: '100%',
};
const overViewCount = {
  fontSize: '50px',
  fontWeight: 'bold',
  border: '10px solid #707070',
  borderRadius: '20px',
  textAlign: 'center',
  color: 'rgb(10,92,144)',
};

const overViewCount2 = {
  fontSize: '30px',
  fontWeight: 'bold',
  border: '8px solid #707070',
  borderRadius: '20px',
  textAlign: 'center',
  color: 'rgb(10,92,144)',
  marginTop: '-10px',
};
const overViewCount3 = {
  fontSize: '16px',
  fontWeight: 'bold',
  border: '8px solid #707070',
  borderRadius: '12px',
  textAlign: 'center',
  color: 'rgb(10,92,144)',
  marginTop: '-10px',
};
const table = {
  width: '90%',
  color: '#000',
  margin: '30px auto',
  border: '1px solid #707070',
  padding: '0',
  fontSize: '12px',
};
const table4 = {
  width: '90%',
  height: '30%',
  color: '#000',
  margin: '30px auto',
  border: '1px solid #fff',
  padding: '0',
  fontSize: '12px',
};

const table3 = {
  position: 'absolute',
  color: 'rgb(0, 0, 0)',
  right: '0',
  top: '24px',
  padding: '0px',
  fontSize: '10px',
  width: '300px',
};

const tableHead = {
  fontFamily: 'robotoBold',

  fontSize: '14px',
  backgroundColor: '#ddd',
};
const tableHead2 = {
  fontFamily: 'robotBold',
  fontSize: '18px',
  backgroundColor: '#8AB8D7',
};
const tableTh = {
  fontSize: '14px',
  padding: '15px 0',
  border: '1px solid #000',
  textAlign: 'center',
};
const tableTh2 = {
  fontFamily: 'robotoBold',
  fontSize: '12px',
  padding: '6px',
  border: '1px solid #000',
  textAlign: 'center',
};
const tableTd = {
  fontFamily: 'robotoMedium',
  fontSize: '12px',
  padding: '5px',
  border: '1px solid #000',
  textAlign: 'center',
  fontWeight: 'bold',
};
const tableCountBlue = {
  fontSize: '36px',
  color: 'rgb(10,92,144)',
  fontWeight: 'bold',
};
const tableCountGreen = {
  fontSize: '36px',
  color: '#5BB46C',
  fontWeight: 'bold',
};
const footer = {
  fontFamily: 'robotRegualr',
  backgroundColor: '#173B52',
  bottom: '40px',
  color: '#fff',
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: '45px',
  padding: '0 16px',
  width: '100%',
  height: '45px',
};
const footerInner = {
  backgroundColor: '#173B52',
  color: '#fff',
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: '45px',
  width: '100%',
};
const footerPara = {
  marginBottom: '0',
};
const footerCompass = {
  marginBottom: '0',
  fontSize: '14px',
  fontWeight: 'bold',
};
const tagsWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '0 62px',
};

const tagsWrapper5 = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '0 62px',
};

const tagsWrapper6 = {
  display: 'flex',

  padding: '0 62px',
};
const layred = {
  position: 'relative',
};

const graphHeading = {
  fontFamily: 'robotoBold',
  color: '#000',
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '30px',
  // paddingLeft: '50px',
};
const graphHeading4 = {
  fontFamily: 'robotoBold',
  color: '#000',
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '30px',
  paddingLeft: '110px',
};

const graphHeading5 = {
  fontFamily: 'robotoBold',
  color: '#000',
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '30px',
  paddingLeft: '50px',
};

const graphHeading3 = {
  fontFamily: 'robotoBold',

  color: '#000',
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '30px',
  paddingLeft: '250px',
  marginTop: '-55px',
};

const printButton = {
  fontFamily: 'robotoRegular',
  position: 'relative',
  left: '4px',
  top: '11px',
  float: 'right',
};

const verticalBar = {
  position: 'absolute',
  backgroundColor: '#C9C9C9',
  width: '50px',
  height: '100%',
  left: '50%',
  top: '0',
  writingMode: 'vertical-rl',
  transform: 'translateX(-50%) rotate(180deg)',
  textAlign: 'center',
  fontSize: '32px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

const verticalBarRadar = {
  position: 'absolute',
  backgroundColor: '#C9C9C9',
  width: '50px',
  height: '100%',
  left: '40%',
  top: '0',
  writingMode: 'vertical-rl',
  transform: 'translateX(-40%) rotate(180deg)',
  textAlign: 'center',
  fontSize: '32px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

const tagsBarWrapper = {
  justifyContent: 'space-between',
  display: 'flex',
  width: '460px',
};

const tagsBarWrapper5 = {
  justifyContent: 'space-between',
  display: 'flex',
};

const Report = () => {
  const { overview, tags, sources, signals, readlists } = useSelector((state) => state.report);
  const dispatch = useDispatch();

  const getData = (dateRange) => {
    dispatch(fetchOverviewRequested(dateRange));
    dispatch(fetchReportTagsRequested(dateRange));
    dispatch(fetchReportSignalsRequested(dateRange));
    dispatch(fetchReportSourcesRequested(dateRange));
    dispatch(fetchReportReadlsitsRequested(dateRange));
  };
  const [colors, setColors] = useState([]);
  const colorScale = async () => {
    const d = d3.schemeTableau10;
    setColors(d);
  };
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    // eslint-disable-next-line no-unused-vars
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (percent > 0.1) {
      return (
        <text
          x={x}
          y={y}
          fill="white"
          labelLine={false}
          fontSize=".7rem"
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    }
    return (
      <text
        x={x}
        y={y}
        fill="white"
        labelLine={false}
        fontSize=".7rem"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      />
    );
  };
  const today = moment().format('Y-M-D');
  const lastMonth = moment().subtract(1, 'months').format('Y-M-D');
  const qMonth = moment().subtract(3, 'months').format('Y-M-D');

  const yMonth = moment().subtract(6, 'months').format('Y-M-D');
  const ddState = window.location.search.split('=')[1];
  function tableSources(datas) {
    const listData = datas
      ? datas.map((item, index) => (
          <tr>
            <td style={{ fontFamily: 'robotoBold', fontSize: '12px', textTransform: 'capitalize' }}>
              {index + 1}.{item.title}
            </td>
            <td style={{ fontFamily: 'robotoRegular', fontSize: '12px', paddingLeft: '10px' }}>
              {item.type}
            </td>
          </tr>
        ))
      : [];
    return <table style={table4}>{listData}</table>;
  }

  useEffect(() => {
    let dateFilter = '';
    if (ddState === 'monthly') {
      dateFilter = `fromDate=${lastMonth}&toDate=${today}&groupBy=day`;
    } else if (ddState === 'quarterly') {
      dateFilter = `fromDate=${qMonth}&toDate=${today}&groupBy=month`;
    } else if (ddState === 'halfyearly') {
      dateFilter = `fromDate=${yMonth}&toDate=${today}&groupBy=month`;
    }
    getData(dateFilter);
    colorScale();
  }, []);
  if (
    overview.isLoading ||
    sources.isLoading ||
    tags.isLoading ||
    signals.isLoading ||
    readlists.isLoading
  ) {
    return <Loading />;
  }
  function headerCommon(data) {
    return (
      <span style={headerPara}>
        ({dateConversion(data.data.tableResult[0].fromDate, DATE_FORMAT_FOR_REPORT_HEADING)} -{' '}
        {dateConversion(data.data.tableResult[0].toDate, DATE_FORMAT_FOR_REPORT_HEADING)} &{' '}
        {dateConversion(data.data.tableResult[1].fromDate, DATE_FORMAT_FOR_REPORT_HEADING)} -{' '}
        {dateConversion(data.data.tableResult[1].toDate, DATE_FORMAT_FOR_REPORT_HEADING)})
      </span>
    );
  }

  function footerCommon(data) {
    return (
      <div style={footerInner}>
        <p style={footerPara}>
          * Data used in this report is for{' '}
          {dateConversion(data.data.tableResult[0].fromDate, DATE_FORMAT_FOR_REPORT_HEADING)} -{' '}
          {dateConversion(data.data.tableResult[0].toDate, DATE_FORMAT_FOR_REPORT_HEADING)} &{' '}
          {dateConversion(data.data.tableResult[1].fromDate, DATE_FORMAT_FOR_REPORT_HEADING)} -{' '}
          {dateConversion(data.data.tableResult[1].toDate, DATE_FORMAT_FOR_REPORT_HEADING)}.
        </p>
        <p style={footerCompass}>Compass</p>
      </div>
    );
  }

  const percentageSuffixFormatter = (value) =>
    value > 0 ? `+${value}%` : value !== 0 ? `${value}%` : '';

  const signPrefixFormatter = (value) =>
    value > 0 ? `+${kFormatter(value)}` : value < 0 ? `-${value}` : '';

  const getDataForGraph = (data, inverse = false) => {
    for (const key in data) {
      let difff = data[key].toVal - data[key].fromVal;
      if (inverse) {
        difff *= -1;
      }
      if (data[key].fromVal > 0) {
        const per = Math.round((difff / data[key].fromVal) * 100);
        // eslint-disable-next-line no-param-reassign
        data[key].percentag = per;
      }
    }
    return data;
  };
  const Overview = (
    <div style={pageWrapper}>
      <div style={header}>
        <div style={header}>
          <div style={headerBox}>
            <span style={headerTitle}>Overview</span>
            {headerCommon(overview)}
          </div>
        </div>
      </div>
      <div style={container}>
        <div style={main}>
          <div style={totlTagWrapper}>
            <div style={overViewbox}>
              <p style={overviewTotaltitle}>TOTAL TAGS</p>
              <div style={overViewCount}>{kFormatter(overview.data.total.Tags)}</div>
            </div>
            <div style={overViewbox}>
              <p style={overviewTotaltitle}>TOTAL SIGNALS</p>
              <div style={overViewCount}>{kFormatter(overview.data.total.Signals)}</div>
            </div>
            <div style={overViewbox}>
              <p style={overviewTotaltitle}>TOTAL READLISTS</p>
              <div style={overViewCount}>{kFormatter(overview.data.total.Readlists)}</div>
            </div>
            <div style={overViewbox}>
              <p style={overviewTotaltitle}>TOTAL SOURCES</p>
              <div style={overViewCount}>{kFormatter(overview.data.total.Sources)}</div>
            </div>
            <div style={overViewbox}>
              <p style={overviewTotaltitle}>ATLAS SCENARIOS</p>
              <div style={overViewCount}>{kFormatter(overview.data.total.Tags)}</div>
            </div>
            <div style={overViewbox}>
              <p style={overviewTotaltitle}>TENET ACTIVITIES</p>
              <div style={overViewCount}>{overview.data.total.Tags}</div>
            </div>
          </div>
          <table style={table}>
            <tr style={tableHead}>
              <th style={tableTh}>TOTAL TAGS</th>
              <th style={tableTh}>TOTAL SIGNALS</th>
              <th style={tableTh}>TOTAL READLISTS</th>
              <th style={tableTh}>TOTAL SOURCES</th>
              <th style={tableTh}>ATLAS SCENARIOS</th>
              <th style={tableTh}>TENET ACTIVITIES</th>
            </tr>
            <tr>
              <td style={tableTd}>
                {dateConversion(overview.data.tableResult[0].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[0].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountBlue}>{overview.data.tableResult[0].Tags}</span>
                <br />
                {dateConversion(overview.data.tableResult[1].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[1].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountGreen}>{overview.data.tableResult[1].Tags}</span>
              </td>
              <td style={tableTd}>
                {dateConversion(overview.data.tableResult[0].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[0].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountBlue}>{overview.data.tableResult[0].Signals}</span>
                <br />
                {dateConversion(overview.data.tableResult[1].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[1].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountGreen}>{overview.data.tableResult[1].Signals}</span>
              </td>
              <td style={tableTd}>
                {dateConversion(overview.data.tableResult[0].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[0].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountBlue}>{overview.data.tableResult[0].Readlists}</span>
                <br /> {dateConversion(
                  overview.data.tableResult[1].fromDate,
                  DATE_FORMAT_FOR_DATE
                )}{' '}
                -{' '}
                {dateConversion(
                  overview.data.tableResult[1].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountGreen}>{overview.data.tableResult[1].Readlists}</span>
              </td>
              <td style={tableTd}>
                {dateConversion(overview.data.tableResult[0].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[0].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountBlue}>{overview.data.tableResult[0].Sources}</span>
                <br />
                {dateConversion(overview.data.tableResult[1].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[1].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountGreen}>{overview.data.tableResult[1].Sources}</span>
              </td>
              <td style={tableTd}>
                {dateConversion(overview.data.tableResult[0].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[0].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountBlue}>{overview.data.tableResult[0].Tags}</span>
                <br />
                {dateConversion(overview.data.tableResult[1].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[1].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountGreen}>{overview.data.tableResult[1].Tags}</span>
              </td>
              <td style={tableTd}>
                {dateConversion(overview.data.tableResult[0].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[0].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountBlue}>{overview.data.tableResult[0].Tags}</span>
                <br />
                {dateConversion(overview.data.tableResult[1].fromDate, DATE_FORMAT_FOR_DATE)} -{' '}
                {dateConversion(
                  overview.data.tableResult[1].toDate,
                  DATE_FORMAT_FOR_REPORT_OVERVIEW_TABLE
                )}
                <br />
                <span style={tableCountGreen}>{overview.data.tableResult[1].Tags}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div style={footer}>{footerCommon(overview)}</div>
    </div>
  );
  const Tags = (
    <div style={pageWrapper}>
      <div style={container}>
        <div style={header}>
          <div style={headerBox}>
            <span style={headerTitle}>Tags</span>
            {headerCommon(overview)}
          </div>
        </div>
        <div style={main}>
          <div style={verticalBar}>Trends</div>
          <div style={tagsWrapper}>
            <div className="report-bar-chart">
              <h3 style={graphHeading}>Area wise new inclusions</h3>
              {tags.data.areaWiseData.lenght === 0 ? (
                <div
                  style={{ transform: 'translate(-10px,-50px)', height: '200px', width: '250px' }}
                >
                  <p
                    style={{
                      color: '#000',
                      height: ' 200px',
                      width: ' 250px',
                      justifyContent: ' center',
                      display: ' flex',
                      alignItems: ' center',
                    }}
                  >
                    No data
                  </p>
                </div>
              ) : (
                <BarChart
                  width={400}
                  height={210}
                  data={getDataForGraph(tags.data.areaWiseData)}
                  margin={{
                    top: 0,
                    right: 0,
                    left: -16,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="key" interval={0} dy={-10} tickLine={false} />

                  <Legend
                    layout="horizontal"
                    verticalAlign="top"
                    align="right"
                    wrapperStyle={{
                      fontSize: '12px',
                      top: 0,
                      right: 0,
                    }}
                  />
                  <Bar
                    name={dateConversion(
                      overview.data.tableResult[0].fromDate,
                      DATE_FORMAT_FOR_CHART_LEGENDS
                    )}
                    dataKey="fromVal"
                    fill="#0A5C90"
                  />
                  <Bar
                    name={dateConversion(
                      overview.data.tableResult[1].toDate,
                      DATE_FORMAT_FOR_CHART_LEGENDS
                    )}
                    dataKey="fromVal"
                    fill="#B2D2C3"
                    stackId="a"
                  />
                  <Bar
                    dataKey="diff"
                    stackId="a"
                    fill="#FCBB72"
                    legendType="none"
                    label={{
                      dataKey: 'diff',
                      position: 'top',
                      stroke: '#01721A',
                      stackId: 'a',
                      fill: '#01721A',
                      formatter: signPrefixFormatter,
                    }}
                  />
                </BarChart>
              )}
            </div>
            <div className="report-bar-chart">
              <h3 style={graphHeading}>Top 5 positive shifts</h3>
              {tags.data.topTagsData.length === 0 ? (
                <div
                  style={{ transform: 'translate(-10px,-50px)', height: '200px', width: '250px' }}
                >
                  <p
                    style={{
                      color: '#000',
                      height: ' 200px',
                      width: ' 250px',
                      justifyContent: ' center',
                      display: ' flex',
                      alignItems: ' center',
                    }}
                  >
                    No data
                  </p>
                </div>
              ) : (
                <BarChart
                  width={400}
                  height={230}
                  data={getDataForGraph(tags.data.topTagsData)}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                  <XAxis dataKey="key" dy={-10} tickLine={false} interval={0} />

                  <Legend
                    layout="horizontal"
                    verticalAlign="top"
                    align="right"
                    wrapperStyle={{
                      fontSize: '12px',
                      top: 0,
                      right: 0,
                    }}
                  />
                  <Bar
                    name={dateConversion(
                      overview.data.tableResult[0].fromDate,
                      DATE_FORMAT_FOR_CHART_LEGENDS
                    )}
                    dataKey="fromVal"
                    fill="#0A5C90"
                  />
                  <Bar
                    name={dateConversion(
                      overview.data.tableResult[1].toDate,
                      DATE_FORMAT_FOR_CHART_LEGENDS
                    )}
                    dataKey="toVal"
                    fill="#6AA74F"
                    label={{
                      dataKey: 'percentag',
                      position: 'top',
                      stroke: '#327BBE',
                      formatter: percentageSuffixFormatter,
                      fill: '#327BBE',
                    }}
                  />
                </BarChart>
              )}
            </div>
            <div className="d-flex flex-column">
              <h3 style={graphHeading}>Total new inclusions</h3>

              <div style={tagsBarWrapper}>
                <div className="report-bar-chart">
                  <div className="report-bar-chart">
                    {tags.data.totalData.length === 0 ? (
                      <div
                        style={{
                          transform: 'translate(-10px,-50px)',
                          height: '200px',
                          width: '250px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            height: ' 200px',
                            width: ' 250px',
                            justifyContent: ' center',
                            display: ' flex',
                            alignItems: ' center',
                          }}
                        >
                          No data
                        </p>
                      </div>
                    ) : (
                      <BarChart
                        width={250}
                        height={120}
                        data={tags.data.totalData}
                        layout="vertical"
                        margin={{ right: 50 }}
                      >
                        <XAxis type="number" hide />
                        <YAxis type="category" hide />
                        <Legend
                          layout="vertical"
                          verticalAlign="top"
                          align="right"
                          stroke="#FF0000"
                          wrapperStyle={{
                            fontSize: '0.5rem',
                            top: 0,
                            right: 0,
                          }}
                        />
                        <Bar
                          dataKey="fromVal"
                          fill="#0A5C90"
                          name={dateConversion(
                            overview.data.tableResult[0].fromDate,
                            DATE_FORMAT_FOR_CHART_LEGENDS
                          )}
                        />
                        <Bar
                          dataKey="fromVal"
                          fill="#64E1FE"
                          stackId="a"
                          name={dateConversion(
                            overview.data.tableResult[1].toDate,
                            DATE_FORMAT_FOR_CHART_LEGENDS
                          )}
                        />
                        <Bar
                          dataKey="diff"
                          stackId="a"
                          fill="#FCBB72"
                          legendType="none"
                          label={{
                            dataKey: 'diff',
                            position: 'right',
                            stroke: '#01721A',
                            stackId: 'a',
                            fill: '#01721A',
                            formatter: signPrefixFormatter,
                          }}
                        />
                      </BarChart>
                    )}
                  </div>
                </div>
                <div>
                  {tags.data.piChartData.length === 0 ? (
                    <div
                      style={{
                        transform: 'translate(-10px,-50px)',
                        height: '200px',
                        width: '250px',
                      }}
                    >
                      <p
                        style={{
                          color: '#000',
                          height: ' 200px',
                          width: ' 250px',
                          justifyContent: ' center',
                          display: ' flex',
                          alignItems: ' center',
                        }}
                      >
                        No data
                      </p>
                    </div>
                  ) : (
                    <PieChart width={250} height={120}>
                      <Pie
                        data={tags.data.piChartData}
                        fill="#8884d8"
                        dataKey="value"
                        stroke=""
                        label={renderCustomizedLabel}
                        // fontSize=".7em"
                        nameKey="key"
                        labelLine={false}
                      >
                        {piChartData.map((entry, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                      </Pie>
                      <Legend
                        dataKey="key"
                        layout="vertical"
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{
                          fontSize: '8px',
                          top: 30,
                          right: 30,
                        }}
                      />
                    </PieChart>
                  )}
                </div>
              </div>

              {tags.data.tableData.length > 0 ? (
                <div>
                  <table style={table}>
                    <tr style={tableHead2}>
                      <th style={tableTh2}>Framework</th>
                      <th style={tableTh2}>Techniques</th>
                      <th style={tableTh2}>Tools</th>
                      <th style={tableTh2}>Languages</th>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        1. {tags.data.tableData[0].framework.framework[0]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        1. {tags.data.tableData[0].technique.technique[0]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        1. {tags.data.tableData[0].tool.tool[0]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        1. {tags.data.tableData[0].language.language[0]}
                      </td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        2. {tags.data.tableData[0].framework.framework[1]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        2. {tags.data.tableData[0].technique.technique[1]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        2. {tags.data.tableData[0].tool.tool[1]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        2. {tags.data.tableData[0].language.language[1]}
                      </td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        3. {tags.data.tableData[0].framework.framework[2]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        3. {tags.data.tableData[0].technique.technique[2]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        3. {tags.data.tableData[0].tool.tool[2]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        3. {tags.data.tableData[0].language.language[2]}
                      </td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        4. {tags.data.tableData[0].framework.framework[3]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        4. {tags.data.tableData[0].technique.technique[3]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        4. {tags.data.tableData[0].tool.tool[3]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        4. {tags.data.tableData[0].language.language[3]}
                      </td>
                    </tr>
                  </table>
                </div>
              ) : (
                <div>
                  <table style={table3}>
                    <tr style={tableHead2}>
                      <th style={tableTh2}>RSS</th>
                      <th style={tableTh2}>Newsletter</th>
                      <th style={tableTh2}>Manual</th>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        *
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        *
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        *
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        *
                      </td>
                    </tr>
                  </table>
                </div>
              )}
            </div>
            <div className="report-bar-chart">
              <h3 style={graphHeading}>Top 5 negative shifts </h3>
              {tags.data.leastTagsData.length === 0 ? (
                <div
                  style={{ transform: 'translate(-10px,-50px)', height: '200px', width: '250px' }}
                >
                  <p
                    style={{
                      color: '#000',
                      height: ' 200px',
                      width: ' 250px',
                      justifyContent: ' center',
                      display: ' flex',
                      alignItems: ' center',
                    }}
                  >
                    No data
                  </p>
                </div>
              ) : (
                <BarChart
                  width={400}
                  height={230}
                  data={getDataForGraph(tags.data.leastTagsData, true)}
                  margin={{
                    top: 10,
                    right: 0,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                  <XAxis dataKey="key" dy={-10} tickLine={false} interval={0} />

                  <Legend
                    layout="horizontal"
                    verticalAlign="top"
                    align="right"
                    wrapperStyle={{
                      fontSize: '12px',
                      top: 0,
                      right: 0,
                    }}
                  />
                  <Bar
                    dataKey="toVal"
                    name={dateConversion(
                      overview.data.tableResult[0].fromDate,
                      DATE_FORMAT_FOR_CHART_LEGENDS
                    )}
                    fill="#0A5C90"
                  />
                  <Bar
                    name={dateConversion(
                      overview.data.tableResult[1].toDate,
                      DATE_FORMAT_FOR_CHART_LEGENDS
                    )}
                    dataKey="fromVal"
                    fill="#DB0000"
                    label={{
                      dataKey: 'percentag',
                      position: 'top',
                      stroke: '#DB0000',
                      fill: '#327BBE',
                      formatter: percentageSuffixFormatter,
                    }}
                    wrapperStyle={{ left: 100 }}
                  />
                </BarChart>
              )}
            </div>
          </div>
        </div>
        <div style={footer}>{footerCommon(overview)}</div>
      </div>
    </div>
  );
  const Sources = (
    <div style={pageWrapper2}>
      <div style={container}>
        <div style={header}>
          <div style={header}>
            <div style={headerBox}>
              <span style={headerTitle}>Sources</span>
              {headerCommon(overview)}
            </div>
          </div>
        </div>

        <div style={main}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 62px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <div style={overViewbox}>
                    <p style={overviewTotaltitle}>TOTAL</p>
                    <div style={overViewCount}>{sources.data.totalData[0].toVal}</div>
                  </div>
                  <div style={overViewbox}>
                    <p style={overviewTotaltitle}>New</p>
                    <div style={overViewCount}>{kFormatter(sources.data.newSources.length)}</div>
                  </div>
                </div>
                {sources.data.newSources.length <= 15 ? (
                  <div style={{ paddingLeft: '60px' }}>
                    <h3 style={overviewTotaltitle}>New Sources</h3>
                    {tableSources(sources.data.newSources)}
                  </div>
                ) : (
                  <>
                    <div>
                      <h3 style={overviewTotaltitle}>New Sources</h3>
                      {tableSources(_.slice(sources.data.newSources, 0, 15))}
                    </div>
                    <div>{tableSources(_.slice(sources.data.newSources, 15))}</div>
                  </>
                )}
              </div>
              <div className="report-bar-chart">
                <h3 style={graphHeading}>Type wise new inclusions</h3>
                {sources.data.typeWiseData.length === 0 ? (
                  <div
                    style={{ transform: 'translate(-10px,-50px)', height: '200px', width: '250px' }}
                  >
                    <p
                      style={{
                        color: '#000',
                        height: ' 200px',
                        width: ' 250px',
                        justifyContent: ' center',
                        display: ' flex',
                        alignItems: ' center',
                      }}
                    >
                      No data
                    </p>
                  </div>
                ) : (
                  <BarChart
                    width={400}
                    height={210}
                    data={sources.data.typeWiseData}
                    margin={{
                      top: 0,
                      right: 0,
                      left: -16,
                      bottom: 0,
                    }}
                  >
                    <XAxis dataKey="key" interval={0} dy={-10} />

                    <Legend
                      layout="horizontal"
                      verticalAlign="top"
                      align="right"
                      wrapperStyle={{
                        fontSize: '12px',
                        top: 0,
                        right: 0,
                      }}
                    />
                    <Bar
                      name={dateConversion(
                        overview.data.tableResult[0].fromDate,
                        DATE_FORMAT_FOR_CHART_LEGENDS
                      )}
                      dataKey="fromVal"
                      fill="#0A5C90"
                    />
                    <Bar
                      name={dateConversion(
                        overview.data.tableResult[1].toDate,
                        DATE_FORMAT_FOR_CHART_LEGENDS
                      )}
                      dataKey="fromVal"
                      fill="#B2D2C3"
                      stackId="a"
                    />
                    <Bar
                      dataKey="diff"
                      stackId="a"
                      fill="#FCBB72"
                      legendType="none"
                      label={{
                        dataKey: 'diff',
                        position: 'top',
                        stroke: '#01721A',
                        stackId: 'a',
                        fill: '#01721A',
                        formatter: signPrefixFormatter,
                      }}
                    />
                  </BarChart>
                )}
              </div>
            </div>
            <div
              style={{
                position: 'absolute',
                left: '62px',
                bottom: '0',
                width: 'calc(100% - 96px)',
                zIndex: '0',
              }}
            >
              <div className="report-bar-chart" style={tagsBarWrapper}>
                {sources.data.sourcesBySignals.length === 0 ? (
                  <div
                    style={{ transform: 'translate(-10px,-50px)', height: '200px', width: '250px' }}
                  >
                    <p
                      style={{
                        color: '#000',
                        height: ' 200px',
                        width: ' 250px',
                        justifyContent: ' center',
                        display: ' flex',
                        alignItems: ' center',
                      }}
                    >
                      No data
                    </p>
                  </div>
                ) : (
                  <BarChart
                    width={960}
                    height={300}
                    data={sources.data.sourcesBySignals}
                    margin={{
                      top: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <XAxis dataKey="sigCount" dy={-10} tick={false} />
                    <Bar dataKey="sigCount" fill="#FCBB72" />
                  </BarChart>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                padding: '0 62px',
              }}
            >
              <div className="d-flex flex-column">
                <h3 style={graphHeading4}>Total new inclusions</h3>
                <div style={tagsBarWrapper}>
                  <div className="report-bar-chart">
                    {sources.data.totalData.length === 0 ? (
                      <div
                        style={{
                          transform: 'translate(-10px,-50px)',
                          height: '200px',
                          width: '250px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            height: ' 200px',
                            width: ' 250px',
                            justifyContent: ' center',
                            display: ' flex',
                            alignItems: ' center',
                          }}
                        >
                          No data
                        </p>
                      </div>
                    ) : (
                      <BarChart
                        width={250}
                        height={250}
                        data={sources.data.totalData}
                        layout="vertical"
                        margin={{ right: 50 }}
                      >
                        <XAxis type="number" hide />
                        <YAxis type="category" hide />
                        <Bar dataKey="fromVal" fill="#0A5C90" />
                        <Bar dataKey="fromVal" fill="#64E1FE" stackId="a" />
                        <Bar
                          dataKey="diff"
                          stackId="a"
                          fill="#FCBB72"
                          legendType="none"
                          label={{
                            dataKey: 'diff',
                            position: 'right',
                            stroke: '#01721A',
                            stackId: 'a',
                            fill: '#01721A',
                            formatter: signPrefixFormatter,
                          }}
                        />
                      </BarChart>
                    )}
                  </div>

                  <div>
                    {sources.data.piChartData.lenght === 0 ? (
                      <div
                        style={{
                          transform: 'translate(-10px,-50px)',
                          height: '200px',
                          width: '250px',
                        }}
                      >
                        <p
                          style={{
                            color: '#000',
                            height: ' 200px',
                            width: ' 250px',
                            justifyContent: ' center',
                            display: ' flex',
                            alignItems: ' center',
                          }}
                        >
                          No data
                        </p>
                      </div>
                    ) : (
                      <PieChart width={250} height={225}>
                        <Pie
                          data={sources.data.piChartData}
                          height={600}
                          width={400}
                          fill="#8884d8"
                          label={renderCustomizedLabel}
                          fontSize=".7em"
                          nameKey="key"
                          stroke={false}
                          labelLine={false}
                        >
                          {piChartData.map((entry, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Cell
                              // eslint-disable-next-line react/no-array-index-key
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          ))}
                        </Pie>

                        <Legend
                          layout="vertical"
                          verticalAlign="top"
                          align="right"
                          wrapperStyle={{
                            fontSize: '12px',
                            top: 55,
                            right: 0,
                          }}
                        />
                      </PieChart>
                    )}
                  </div>
                </div>
              </div>

              <div />
            </div>
          </div>
        </div>
        <div style={footer}>{footerCommon(overview)}</div>
      </div>
    </div>
  );
  const Signals = (
    <div style={pageWrapper3}>
      <div style={container}>
        <div style={header}>
          <div style={header}>
            <div style={headerBox}>
              <span style={headerTitle}>Signals</span>
              {headerCommon(overview)}
            </div>
          </div>
        </div>
        <div style={main}>
          <div style={tagsWrapper}>
            <div style={layred}>
              <h3 style={graphHeading}>Signals Received</h3>
              <div style={overViewbox2}>
                <p style={overviewTotaltitle2}>Signals</p>
                <div style={overViewCount2}>{kFormatter(signals.data.signalsKpi[0].sigCount)}</div>
              </div>
              <div>
                {signals.data.signalsChart.length === 0 ? (
                  <div
                    style={{ transform: 'translate(-10px,-50px)', height: '200px', width: '250px' }}
                  >
                    <p
                      style={{
                        color: '#000',
                        height: ' 200px',
                        width: ' 250px',
                        justifyContent: ' center',
                        display: ' flex',
                        alignItems: ' center',
                      }}
                    >
                      No data
                    </p>
                  </div>
                ) : (
                  <LineChart
                    width={550}
                    height={230}
                    data={signals.data.signalsChart}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <XAxis tick={false} />
                    <Legend
                      layout="vertical"
                      verticalAlign="top"
                      align="right"
                      wrapperStyle={{
                        fontSize: '12px',
                        top: 0,
                        left: 0,
                      }}
                    />
                    <Line
                      name={`${dateConversion(
                        signals.data.fromDate,
                        DATE_FORMAT_FOR_REPORT_HEADING
                      )} - ${dateConversion(signals.data.toDate, DATE_FORMAT_FOR_REPORT_HEADING)}`}
                      dataKey="signalCount"
                      stroke="#8884d8"
                      dot={false}
                    />
                  </LineChart>
                )}
              </div>
            </div>
            <div style={layred}>
              <h3 style={graphHeading}>Processed Signals</h3>
              <div>
                {tags.data.tableData.length > 0 ? (
                  <div>
                    <table style={table3}>
                      <tr style={tableHead2}>
                        <th style={tableTh2}>RSS</th>
                        <th style={tableTh2}>Newsletter</th>
                        <th style={tableTh2}>Manual</th>
                      </tr>
                      <tr style={{ border: '1px black solid' }}>
                        <td
                          style={{
                            border: '1px black solid',
                            padding: '0 6px',
                            fontSize: '.7em',
                          }}
                        >
                          1. {tags.data.tableData[0].framework.framework[0]}
                        </td>
                        <td
                          style={{
                            border: '1px black solid',
                            padding: '0 6px',
                            fontSize: '.7em',
                          }}
                        >
                          1. {tags.data.tableData[0].technique.technique[0]}
                        </td>
                        <td
                          style={{
                            border: '1px black solid',
                            padding: '0 6px',
                            fontSize: '.7em',
                          }}
                        >
                          1. {tags.data.tableData[0].tool.tool[0]}
                        </td>
                      </tr>
                      <tr style={{ border: '1px black solid' }}>
                        <td
                          style={{
                            border: '1px black solid',
                            padding: '0 6px',
                            fontSize: '.7em',
                          }}
                        >
                          2. {tags.data.tableData[0].technique.technique[1]}
                        </td>
                        <td
                          style={{
                            border: '1px black solid',
                            padding: '0 6px',
                            fontSize: '.7em',
                          }}
                        >
                          2. {tags.data.tableData[0].tool.tool[1]}
                        </td>
                        <td
                          style={{
                            border: '1px black solid',
                            padding: '0 6px',
                            fontSize: '.7em',
                          }}
                        >
                          2. {tags.data.tableData[0].language.language[1]}
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  <div>
                    <table style={table3}>
                      <tr style={tableHead2}>
                        <th style={tableTh2}>RSS</th>
                        <th style={tableTh2}>Newsletter</th>
                        <th style={tableTh2}>Manual</th>
                      </tr>
                    </table>
                  </div>
                )}
              </div>
              <div className="report-bar-chart">
                {signals.data.successData === 0 ? (
                  <div
                    style={{ transform: 'translate(-10px,-50px)', height: '200px', width: '250px' }}
                  >
                    <p
                      style={{
                        color: '#000',
                        height: ' 200px',
                        width: ' 250px',
                        justifyContent: ' center',
                        display: ' flex',
                        alignItems: ' center',
                      }}
                    >
                      No data
                    </p>
                  </div>
                ) : (
                  <BarChart
                    width={400}
                    height={225}
                    data={getDataForGraph(signals.data.successData)}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <Legend
                      layout="vertical"
                      verticalAlign="top"
                      align="right"
                      wrapperStyle={{
                        fontSize: '12px',
                        top: '80px',
                        right: 0,
                      }}
                    />
                    <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                    <XAxis dataKey="key" dy={-10} tickLine={false} />
                    <Bar
                      name={dateConversion(
                        overview.data.tableResult[0].fromDate,
                        DATE_FORMAT_FOR_CHART_LEGENDS
                      )}
                      dataKey="fromVal"
                      fill="#0A5C90"
                    />
                    <Bar
                      name={dateConversion(
                        overview.data.tableResult[1].toDate,
                        DATE_FORMAT_FOR_CHART_LEGENDS
                      )}
                      dataKey="toVal"
                      fill="#6AA74F"
                      label={{
                        dataKey: 'percentag',
                        position: 'top',
                        stroke: '#0A5C90',
                        fill: '#01721A',
                        formatter: percentageSuffixFormatter,
                      }}
                    />
                  </BarChart>
                )}
              </div>
            </div>
            <div className="d-flex flex-column">
              <h3 style={graphHeading}>Total Signals Received</h3>
              <h3 style={graphHeading3}>Popular in tags</h3>

              <div style={tagsBarWrapper}>
                <div className="report-bar-chart">
                  {signals.data.totalData.lenght === 0 ? (
                    <div
                      style={{
                        transform: 'translate(-10px,-50px)',
                        height: '200px',
                        width: '250px',
                      }}
                    >
                      <p
                        style={{
                          color: '#000',
                          height: ' 200px',
                          width: ' 250px',
                          justifyContent: ' center',
                          display: ' flex',
                          alignItems: ' center',
                        }}
                      >
                        No data
                      </p>
                    </div>
                  ) : (
                    <BarChart
                      width={250}
                      height={120}
                      data={signals.data.totalData}
                      layout="vertical"
                      margin={{ right: 30 }}
                    >
                      <XAxis type="number" hide />
                      <YAxis type="category" hide />
                      <Legend
                        layout="vertical"
                        verticalAlign="top"
                        align="right"
                        stroke="#FF0000"
                        wrapperStyle={{
                          fontSize: '0.5rem',
                          top: 0,
                          right: 0,
                        }}
                      />
                      <Bar
                        dataKey="fromVal"
                        fill="#0A5C90"
                        name={dateConversion(
                          overview.data.tableResult[0].fromDate,
                          DATE_FORMAT_FOR_CHART_LEGENDS
                        )}
                      />
                      <Bar
                        dataKey="fromVal"
                        fill="#82ca9d"
                        stackId="a"
                        name={dateConversion(
                          overview.data.tableResult[1].toDate,
                          DATE_FORMAT_FOR_CHART_LEGENDS
                        )}
                      />
                      <Bar
                        dataKey="diff"
                        stackId="a"
                        fill="#FCBB72"
                        legendType="none"
                        label={{
                          dataKey: 'diff',
                          position: 'right',
                          stroke: '#01721A',
                          stackId: 'a',
                          fill: '#01721A',
                          formatter: signPrefixFormatter,
                        }}
                      />
                    </BarChart>
                  )}
                </div>
                <div className="d-flex  ">
                  <div style={overViewbox3}>
                    <p style={overviewTotaltitle2}>
                      {signals.data.signalsTagPopularityPercent[0].key}
                    </p>
                    <div style={overViewCount3}>
                      {_.round(signals.data.signalsTagPopularityPercent[0].percent)}%
                    </div>
                  </div>
                  <div style={overViewbox3}>
                    <p style={overviewTotaltitle2}>
                      {signals.data.signalsTagPopularityPercent[1].key}
                    </p>
                    <div style={overViewCount3}>
                      {_.round(signals.data.signalsTagPopularityPercent[1].percent)}%
                    </div>
                  </div>
                  <div style={overViewbox3}>
                    <p style={overviewTotaltitle2}>
                      {signals.data.signalsTagPopularityPercent[2].key}
                    </p>
                    <div style={overViewCount3}>
                      {_.round(signals.data.signalsTagPopularityPercent[2].percent)}%
                    </div>
                  </div>
                </div>
              </div>

              <div style={tagsBarWrapper}>
                <div>
                  {signals.data.piChartData.length === 0 ? (
                    <div
                      style={{
                        transform: 'translate(-10px,-50px)',
                        height: '200px',
                        width: '250px',
                      }}
                    >
                      <p
                        style={{
                          color: '#000',
                          height: ' 200px',
                          width: ' 250px',
                          justifyContent: ' center',
                          display: ' flex',
                          alignItems: ' center',
                        }}
                      >
                        No data
                      </p>
                    </div>
                  ) : (
                    <PieChart
                      width={225}
                      height={225}
                      margin={{
                        top: -55,
                      }}
                    >
                      <Pie
                        data={signals.data.piChartData}
                        fill="#8884d8"
                        dataKey="value"
                        stroke={false}
                        label={renderCustomizedLabel}
                        nameKey="key"
                        labelLine={false}
                      >
                        {piChartData.map((entry, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                      </Pie>

                      <Legend
                        layout="vertical"
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{
                          fontSize: '12px',
                          top: 55,
                          right: 0,
                        }}
                      />
                    </PieChart>
                  )}
                </div>
                <div className="d-flex report-bar-chart">
                  {signals.data.signalTagPopularityList.lenght === 0 ? (
                    <div
                      style={{
                        transform: 'translate(-10px,-50px)',
                        height: '200px',
                        width: '250px',
                      }}
                    >
                      <p
                        style={{
                          color: '#000',
                          height: ' 200px',
                          width: ' 250px',
                          justifyContent: ' center',
                          display: ' flex',
                          alignItems: ' center',
                        }}
                      >
                        No data
                      </p>
                    </div>
                  ) : (
                    <BarChart
                      width={350}
                      height={250}
                      layout="vertical"
                      data={signals.data.signalTagPopularityList}
                    >
                      <XAxis dataKey="tagCount" type="number" hide />
                      <YAxis dataKey="tagTitle" interval={0} type="category" tickLine={false} />
                      <Bar dataKey="tagCount" fill="#F1B454" />
                    </BarChart>
                  )}
                </div>
              </div>
            </div>
            <div style={layred}>
              <h3 style={graphHeading5}>Failed processing</h3>
              {tags.data.tableData.length > 0 ? (
                <div>
                  <table style={table3}>
                    <tr style={tableHead2}>
                      <th style={tableTh2}>RSS</th>
                      <th style={tableTh2}>Newsletter</th>
                      <th style={tableTh2}>Manual</th>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        1. {tags.data.tableData[0].framework.framework[0]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        1. {tags.data.tableData[0].technique.technique[0]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        1. {tags.data.tableData[0].tool.tool[0]}
                      </td>
                    </tr>
                    <tr style={{ border: '1px black solid' }}>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        2. {tags.data.tableData[0].technique.technique[1]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        2. {tags.data.tableData[0].tool.tool[1]}
                      </td>
                      <td
                        style={{
                          border: '1px black solid',
                          padding: '0 6px',
                          fontSize: '.7em',
                        }}
                      >
                        2. {tags.data.tableData[0].language.language[1]}
                      </td>
                    </tr>
                  </table>
                </div>
              ) : (
                <div>
                  <table style={table3}>
                    <tr style={tableHead2}>
                      <th style={tableTh2}>RSS</th>
                      <th style={tableTh2}>Newsletter</th>
                      <th style={tableTh2}>Manual</th>
                    </tr>
                  </table>
                </div>
              )}
              <div className="report-bar-chart">
                {signals.data.failedData.length === 0 ? (
                  <div
                    style={{ transform: 'translate(-10px,-50px)', height: '200px', width: '250px' }}
                  >
                    <p
                      style={{
                        color: '#000',
                        height: ' 200px',
                        width: ' 250px',
                        justifyContent: ' center',
                        display: ' flex',
                        alignItems: ' center',
                      }}
                    >
                      No data
                    </p>
                  </div>
                ) : (
                  <BarChart
                    width={400}
                    height={225}
                    data={getDataForGraph(signals.data.failedData, true)}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                    <XAxis dataKey="key" dy={-10} tickLine={false} interval={0} />

                    <Legend
                      layout="horizontal"
                      verticalAlign="top"
                      align="right"
                      wrapperStyle={{
                        fontSize: '12px',
                        top: '80px',
                        right: 0,
                      }}
                    />
                    <Bar
                      dataKey="toVal"
                      name={dateConversion(
                        overview.data.tableResult[0].fromDate,
                        DATE_FORMAT_FOR_CHART_LEGENDS
                      )}
                      fill="#0A5C90"
                    />
                    <Bar
                      name={dateConversion(
                        overview.data.tableResult[1].toDate,
                        DATE_FORMAT_FOR_CHART_LEGENDS
                      )}
                      dataKey="fromVal"
                      fill="#DB0000"
                      label={{
                        dataKey: 'percentag',
                        position: 'top',
                        stroke: '#DB0000',
                        fill: '#327BBE',
                        formatter: percentageSuffixFormatter,
                      }}
                    />
                  </BarChart>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={footer}>{footerCommon(overview)}</div>
      </div>
    </div>
  );
  const Readlist = (
    <div style={pageWrapper4}>
      <div style={container}>
        <div style={header}>
          <div style={headerBox}>
            <span style={headerTitle}>R & D Outcomes</span>
            {headerCommon(overview)}
          </div>
        </div>
        <div style={main}>
          <div style={verticalBarRadar}>RADAR</div>
          <div style={tagsWrapper5}>
            <div className="d-flex flex-column" style={{ marginRight: '55px' }}>
              <h3 style={graphHeading}>Total Readlists generated</h3>
              <div style={tagsBarWrapper5}>
                <div className="report-bar-chart">
                  {readlists.data.totalData.length === 0 ? (
                    <div
                      style={{
                        transform: 'translate(-10px,-50px)',
                        height: '200px',
                        width: '250px',
                      }}
                    >
                      <p
                        style={{
                          color: '#000',
                          height: ' 200px',
                          width: ' 250px',
                          justifyContent: ' center',
                          display: ' flex',
                          alignItems: ' center',
                        }}
                      >
                        No data
                      </p>
                    </div>
                  ) : (
                    <BarChart
                      width={120}
                      height={180}
                      data={readlists.data.totalData}
                      margin={{
                        top: 10,
                        right: 0,
                        left: -16,
                        bottom: 0,
                      }}
                    >
                      <XAxis dataKey="key" interval={0} dy={-10} tickLine={false} />

                      <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{
                          fontSize: '12px',
                          top: -20,
                          right: 0,
                        }}
                      />
                      <Bar
                        name={dateConversion(
                          overview.data.tableResult[0].fromDate,
                          DATE_FORMAT_FOR_CHART_LEGENDS
                        )}
                        dataKey="fromVal"
                        fill="#0A5C90"
                      />
                      <Bar
                        name={dateConversion(
                          overview.data.tableResult[1].toDate,
                          DATE_FORMAT_FOR_CHART_LEGENDS
                        )}
                        dataKey="fromVal"
                        fill="#B2D2C3"
                        stackId="a"
                      />
                      <Bar
                        dataKey="diff"
                        stackId="a"
                        fill="#FCBB72"
                        legendType="none"
                        label={{
                          dataKey: 'diff',
                          position: 'top',
                          stroke: '#01721A',
                          stackId: 'a',
                          fill: '#01721A',
                          formatter: signPrefixFormatter,
                        }}
                      />
                    </BarChart>
                  )}
                </div>

                {readlists.data.piChartData.length === 0 ? (
                  <div
                    style={{ transform: 'translate(-10px,-50px)', height: '200px', width: '250px' }}
                  >
                    <p
                      style={{
                        color: '#000',
                        height: ' 200px',
                        width: ' 250px',
                        justifyContent: ' center',
                        display: ' flex',
                        alignItems: ' center',
                      }}
                    >
                      No data
                    </p>
                  </div>
                ) : (
                  <div style={{ transform: 'translate(-10px,-50px)' }}>
                    <PieChart width={250} height={200}>
                      <Pie
                        data={readlists.data.piChartData}
                        height={600}
                        width={400}
                        fill="#8884d8"
                        label={renderCustomizedLabel}
                        fontSize=".7em"
                        nameKey="key"
                        stroke={false}
                        labelLine={false}
                      >
                        {piChartData.map((entry, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            fill={colors[index % colors.length]}
                          />
                        ))}
                      </Pie>

                      <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{
                          position: 'relative',
                          fontSize: '12px',
                          bottom: 0,
                          right: 0,
                        }}
                      />
                    </PieChart>
                  </div>
                )}
              </div>

              <div className="report-bar-chart">
                <h3 style={graphHeading}>Major tags in Readlists</h3>
                {readlists.data.readlistByTags.length === 0 ? (
                  <div
                    style={{ transform: 'translate(-10px,-50px)', height: '200px', width: '250px' }}
                  >
                    <p
                      style={{
                        color: '#000',
                        height: ' 200px',
                        width: ' 250px',
                        justifyContent: ' center',
                        display: ' flex',
                        alignItems: ' center',
                      }}
                    >
                      No data
                    </p>
                  </div>
                ) : (
                  <BarChart
                    width={350}
                    height={230}
                    data={readlists.data.readlistByTags}
                    margin={{
                      top: 10,
                      right: 0,
                      left: 20,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                    <XAxis dataKey="tag" dy={-10} tickLine={false} interval={0} />
                    <Bar
                      dataKey="tagCount"
                      name={dateConversion(
                        overview.data.tableResult[0].fromDate,
                        DATE_FORMAT_FOR_CHART_LEGENDS
                      )}
                      fill="#F1B454"
                    />
                  </BarChart>
                )}
              </div>
            </div>

            <div className="d-flex flex-column">
              <div style={tagsBarWrapper} />
              <h1 style={{ color: 'black' }}>For Radar</h1>
            </div>
          </div>
        </div>
        <div style={footer}>{footerCommon(overview)}</div>
      </div>
    </div>
  );

  // const Atlas = (
  //   <div style={pageWrapper5}>
  //     <div style={container}>
  //       <div style={header}>
  //         <div style={headerBox}>
  //           <span style={headerTitle}>Atlas</span>
  //           {headerCommon(overview)}
  //         </div>
  //       </div>
  //       <div style={main}>
  //         <div style={tagsWrapper6}>
  //           <div className="d-flex flex-column" style={{ marginRight: '55px' }}>
  //             <div className="report-bar-chart">
  //               <h3 style={graphHeading}>Major tags in Readlists</h3>

  //               <BarChart
  //                 width={400}
  //                 height={210}
  //                 data={areaWiseData}
  //                 margin={{
  //                   top: 0,
  //                   right: 0,
  //                   left: -16,
  //                   bottom: 0,
  //                 }}
  //               >
  //                 <XAxis dataKey="key" interval={0} dy={-10} />

  //                 <Legend
  //                   layout="horizontal"
  //                   verticalAlign="top"
  //                   align="right"
  //                   wrapperStyle={{
  //                     fontSize: '12px',
  //                     top: 0,
  //                     right: 0,
  //                   }}
  //                 />
  //                 <Bar
  //                   dataKey="fromVal"
  //                   fill="#0A5C90"
  //                   name={dateConversion(
  //                     overview.data.tableResult[0].fromDate,
  //                     DATE_FORMAT_FOR_CHART_LEGENDS
  //                   )}
  //                 />
  //                 <Bar
  //                   dataKey="fromVal"
  //                   fill="#B2D2C3"
  //                   stackId="a"
  //                   name={dateConversion(
  //                     overview.data.tableResult[1].toDate,
  //                     DATE_FORMAT_FOR_CHART_LEGENDS
  //                   )}
  //                 />
  //                 <Bar
  //                   dataKey="diff"
  //                   stackId="a"
  //                   fill="#FCBB72"
  //                   legendType="none"
  //                   label={{
  //                     dataKey: 'percentag',
  //                     position: 'top',
  //                     stroke: '#027213',
  //                     stackId: 'a',
  //                     fill: '#027213',
  //                     formatter: percentageSuffixFormatter,
  //                   }}
  //                 />
  //               </BarChart>
  //             </div>

  //             <h3 style={graphHeading}>Total Readlists generated</h3>
  //             <div style={tagsBarWrapper5}>
  //               <div className="report-bar-chart">
  //                 <BarChart
  //                   width={120}
  //                   height={180}
  //                   data={totalData}
  //                   margin={{
  //                     top: 10,
  //                     right: 0,
  //                     left: -16,
  //                     bottom: 0,
  //                   }}
  //                 >
  //                   <XAxis dataKey="key" interval={0} dy={-10} tickLine={false} />

  //                   <Legend
  //                     layout="horizontal"
  //                     verticalAlign="top"
  //                     align="right"
  //                     wrapperStyle={{
  //                       fontSize: '12px',
  //                       top: -20,
  //                       right: 0,
  //                     }}
  //                   />
  //                   <Bar
  //                     name={dateConversion(
  //                       overview.data.tableResult[0].fromDate,
  //                       DATE_FORMAT_FOR_CHART_LEGENDS
  //                     )}
  //                     dataKey="fromVal"
  //                     fill="#0A5C90"
  //                   />
  //                   <Bar
  //                     name={dateConversion(
  //                       overview.data.tableResult[1].toDate,
  //                       DATE_FORMAT_FOR_CHART_LEGENDS
  //                     )}
  //                     dataKey="fromVal"
  //                     fill="#B2D2C3"
  //                     stackId="a"
  //                   />
  //                   <Bar
  //                     dataKey="diff"
  //                     stackId="a"
  //                     fill="#FCBB72"
  //                     legendType="none"
  //                     label={{
  //                       dataKey: 'diff',
  //                       position: 'top',
  //                       stroke: '#01721A',
  //                       stackId: 'a',
  //                       fill: '#01721A',
  //                       formatter: signPrefixFormatter,
  //                     }}
  //                   />
  //                 </BarChart>
  //               </div>

  //               <div style={{ transform: 'translate(-10px,-50px)' }}>
  //                 <PieChart width={250} height={200}>
  //                   <Pie
  //                     data={piChartData}
  //                     height={600}
  //                     width={400}
  //                     fill="#8884d8"
  //                     label={renderCustomizedLabel}
  //                     fontSize=".7em"
  //                     nameKey="key"
  //                     stroke={false}
  //                     labelLine={false}
  //                   >
  //                     {piChartData.map((entry, index) => (
  //                       // eslint-disable-next-line react/no-array-index-key
  //                       <Cell
  //                         // eslint-disable-next-line react/no-array-index-key
  //                         key={`cell-${index}`}
  //                         fill={colors[index % colors.length]}
  //                       />
  //                     ))}
  //                   </Pie>

  //                   <Legend
  //                     layout="vertical"
  //                     align="right"
  //                     wrapperStyle={{
  //                       fontSize: '12px',
  //                       bottom: 0,
  //                       right: 0,
  //                     }}
  //                   />
  //                 </PieChart>
  //               </div>
  //             </div>
  //           </div>

  //           <div>
  //             <div className="d-flex  ">
  //               <h3 style={graphHeading}>Tags associated with newly added accelerators</h3>
  //               <div style={overViewbox3}>
  //                 <p style={overviewTotaltitle2}>
  //                   TOTAL ACCELERATORS
  //                   {/* {signals.data.signalsTagPopularityPercent[0].key} */}
  //                 </p>
  //                 <div style={overViewCount3}>
  //                   {_.round(signals.data.signalsTagPopularityPercent[0].percent)}%
  //                 </div>
  //               </div>
  //               <div style={overViewbox3}>
  //                 <p style={overviewTotaltitle2}>
  //                   NEW ACCELERATORS
  //                   {/* {signals.data.signalsTagPopularityPercent[1].key} */}
  //                 </p>
  //                 <div style={overViewCount3}>
  //                   {_.round(signals.data.signalsTagPopularityPercent[1].percent)}%
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="report-bar-chart">
  //               <BarChart
  //                 width={400}
  //                 height={210}
  //                 data={readlists.data.readlistByTags}
  //                 margin={{
  //                   top: 0,
  //                   right: 0,
  //                   left: -16,
  //                   bottom: 0,
  //                 }}
  //               >
  //                 <XAxis dataKey="tag" interval={0} dy={-10} tickLine={false} />

  //                 <Bar
  //                   name={dateConversion(
  //                     overview.data.tableResult[0].fromDate,
  //                     DATE_FORMAT_FOR_CHART_LEGENDS
  //                   )}
  //                   dataKey="tagCount"
  //                   fill="#0A5C90"
  //                 />
  //               </BarChart>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div style={footer}>{footerCommon(overview)}</div>
  //       <Button className="btn me-1" style={printButton} onClick={() => window.print()}>
  //         Print
  //       </Button>
  //     </div>
  //   </div>
  // );

  return (
    <>
      {ddState === '' ? null : (
        <>
          {Overview}
          {Tags}
          {Sources}
          {Signals}
          {Readlist}
          {/* {Atlas} */}
        </>
      )}
    </>
  );
};

export default Report;
