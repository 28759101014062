/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_TAGS_REQUESTED,
  FETCH_TAGS_SUCCESSFULL,
  FETCH_TAGS_FAILURE,
  PUT_TAGS_REQUESTED,
  PUT_TAGS_SUCCESSFULL,
  PUT_TAGS_FAILURE,
  TAGS_RECORD_UPDATE_SUCCESS,
} from '../../common/constants/actions';

/**
 * Export the actions for reducers, actions and sagas
 */
export const tagsActionTypes = {
  FETCH_TAGS_REQUESTED,
  FETCH_TAGS_SUCCESSFULL,
  FETCH_TAGS_FAILURE,
  PUT_TAGS_REQUESTED,
  PUT_TAGS_SUCCESSFULL,
  PUT_TAGS_FAILURE,
  TAGS_RECORD_UPDATE_SUCCESS,
};
