import { put, call, takeLatest } from 'redux-saga/effects';
import { TypeheadActionTypes } from './typeheadTypes';
import { fetchTypeheadSuccessfull, fetchTypeheadFailure } from './typeheadActions';
import trendsApi from '../../api/typeHeadApi';

/**
 * Featch typehead
 * @param {Object} action
 */
function* fetchTypehead(action) {
  const { response, error } = yield call(trendsApi.fetchTrends, action.payload);
  if (response) {
    yield put(fetchTypeheadSuccessfull(response));
  } else {
    yield put(fetchTypeheadFailure(error));
  }
}

export default function* typeheadSaga() {
  yield takeLatest(TypeheadActionTypes.FETCH_TYPEHEAD_REQUESTED, fetchTypehead);
}
