import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import signalsApi from '../../api/signalsApi';
import { REDIRECT_BUTTON, REDIRECT_MESSAGE } from '../constants/common';

const redirectMethod = () => {
  setTimeout(() => {
    window.open('/', '_self');
  }, 10000);
};
const SignalDownload = (props) => {
  useEffect(() => {
    signalsApi
      .signalDownload(props.match.params.id, props.location.search)
      .then((response) => {
        window.open(response.response);

        redirectMethod();
      })
      .catch(() => {});
  }, []);

  return (
    <div className="container">
      <div className="signals-download-block">
        <p className="compass-white ">{REDIRECT_MESSAGE}</p>
        <Button onClick={redirectMethod}>{REDIRECT_BUTTON}</Button>
      </div>
    </div>
  );
};

export default SignalDownload;
