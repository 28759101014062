import { put, call, takeLatest } from 'redux-saga/effects';
import { ReadListActionTypes } from './readListTypes';
import {
  fetchReadListSummarySuccessfull,
  fetchReadListSummaryFailure,
  fetchReadListListingFailure,
  fetchReadListListingSuccessfull,
} from './readListActions';
import readListApi from '../../api/readListApi';

/**
 * Featch readList summary
 * @param {Object} action
 */
function* fetchReadListSummary(action) {
  const { response, error } = yield call(readListApi.fetchReadListSummary, action.payload);
  if (response) {
    yield put(fetchReadListSummarySuccessfull(response));
  } else {
    yield put(fetchReadListSummaryFailure(error));
  }
}

/**
 * Featch readList listing
 * @param {Object} action
 */
function* fetchReadListListing(action) {
  const { response, count, error } = yield call(readListApi.fetchReadListListing, action.payload);
  if (response) {
    yield put(fetchReadListListingSuccessfull({ response, count }));
  } else {
    yield put(fetchReadListListingFailure(error));
  }
}

export default function* readListSaga() {
  yield takeLatest(ReadListActionTypes.FETCH_READLIST_SUMMARY_REQUESTED, fetchReadListSummary);
  yield takeLatest(ReadListActionTypes.FETCH_READLIST_LISTING_REQUESTED, fetchReadListListing);
}
