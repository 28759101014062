/**
 * Import constant types
 */
import {
  FETCH_SIGNALS_REQUESTED,
  FETCH_SIGNALS_SUCCESSFULL,
  FETCH_SIGNALS_FAILURE,
  FETCH_SIGNALS_GRAPH_REQUESTED,
  FETCH_SIGNALS_GRAPH_SUCCESSFULL,
  FETCH_SIGNALS_GRAPH_FAILURE,
  FETCH_SIGNALS_LIST_REQUESTED,
  FETCH_SIGNALS_LIST_SUCCESSFULL,
  FETCH_SIGNALS_LIST_FAILURE,
} from '../../common/constants/actions';

/**
 *
 * @function :  signalReducer
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */

const signalReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SIGNALS_REQUESTED:
      return { ...state, isLoading: true };

    case FETCH_SIGNALS_SUCCESSFULL: {
      return {
        ...state,

        isLoading: false,
        isError: false,
        ...action.payload,
      };
    }

    case FETCH_SIGNALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case FETCH_SIGNALS_GRAPH_REQUESTED: {
      const newSignal = { ...state };
      newSignal.graphData.isLoading = true;
      return newSignal;
    }
    case FETCH_SIGNALS_GRAPH_SUCCESSFULL: {
      const newSignal = { ...state };
      newSignal.graphData.isLoading = false;
      newSignal.graphData.isError = false;
      newSignal.graphData.data = action.payload;
      return newSignal;
    }
    case FETCH_SIGNALS_GRAPH_FAILURE: {
      const newState = { ...state };
      newState.graphData.isLoading = false;
      newState.graphData.isError = true;
      newState.graphData.errorMessage = action.payload.errorMessage;

      return newState;
    }
    case FETCH_SIGNALS_LIST_REQUESTED: {
      const newSignal = { ...state };
      newSignal.listData.isLoading = true;
      return newSignal;
    }
    case FETCH_SIGNALS_LIST_SUCCESSFULL: {
      const newSignal = { ...state };
      newSignal.listData.isLoading = false;
      newSignal.listData.isError = false;

      newSignal.listData.data = action.payload.response;
      newSignal.listData.count = action.payload.count;

      return newSignal;
    }
    case FETCH_SIGNALS_LIST_FAILURE: {
      const newState = { ...state };
      newState.listData.isLoading = false;
      newState.listData.isError = true;
      return newState;
    }
    default:
      return state;
  }
};

export default signalReducer;
