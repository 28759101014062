export const COLOR_CODES = [
  '#999fcc',
  '#d6980d',
  '#ff0000',
  '#fcba03',
  '#67fc03',
  '#439491',
  '#64677a',
  '#69385d',
  '#a35563',
  '#663b34',
];
