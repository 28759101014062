export const VIEW_CONFIGURATION = 'VIEW_CONFIGURATION';
export const VIEW_USERS = 'VIEW_USERS';
export const VIEW_FAIELD_SIGNALS = 'VIEW_FAIELD_SIGNALS';

export const VIEW_REPORT = 'VIEW_REPORT';

export const VIEW_TRENDS_PAGE = 'VIEW_TRENDS_PAGE';
export const VIEW_SIGNALS_PAGE = 'VIEW_SIGNALS_PAGE';
export const VIEW_READLIST_PAGE = 'VIEW_READLIST_PAGE';

export const accessList = {
  Admin: [
    VIEW_CONFIGURATION,
    VIEW_USERS,
    VIEW_FAIELD_SIGNALS,
    VIEW_TRENDS_PAGE,
    VIEW_SIGNALS_PAGE,
    VIEW_READLIST_PAGE,
    VIEW_REPORT,
  ],
  Internal: [VIEW_SIGNALS_PAGE, VIEW_TRENDS_PAGE, VIEW_READLIST_PAGE],
};
