/**
 * Import constant types
 */
import { ReadListActionTypes } from './readListTypes';

/**
 *
 * @function :  readListReducer
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */
const readListReducer = (state = {}, action) => {
  switch (action.type) {
    case ReadListActionTypes.FETCH_READLIST_SUMMARY_REQUESTED: {
      const newState = { ...state };
      newState.summaryData.isLoading = true;
      return newState;
    }

    case ReadListActionTypes.FETCH_READLIST_SUMMARY_SUCCESSFULL: {
      const newState = { ...state };
      newState.summaryData.isLoading = false;
      newState.summaryData.readlistCount = action.payload.readlistCount;
      newState.summaryData.signalCount = action.payload.signalCount;
      return newState;
    }
    case ReadListActionTypes.FETCH_READLIST_SUMMARY_FAILURE: {
      const newState = { ...state };
      newState.summaryData.isLoading = false;
      newState.summaryData.isError = true;
      return newState;
    }

    case ReadListActionTypes.FETCH_READLIST_LISTING_REQUESTED: {
      const newState = { ...state };
      newState.listingData.isLoading = true;
      return newState;
    }

    case ReadListActionTypes.FETCH_READLIST_LISTING_SUCCESSFULL: {
      const newState = { ...state };
      newState.listingData.isLoading = false;
      newState.listingData.data = action.payload.response;
      newState.listingData.recordCount = action.payload.count;
      newState.listingData.signalCount = action.payload.signalCount;
      return newState;
    }
    case ReadListActionTypes.FETCH_READLIST_LISTING_FAILURE: {
      const newState = { ...state };
      newState.listingData.isLoading = false;
      newState.listingData.isError = true;
      return newState;
    }
    default:
      return state;
  }
};

export default readListReducer;
