/**
 * Import constant actions from commonn/constants
 */
import {
  FETCH_TYPEHEAD_REQUESTED,
  FETCH_TYPEHEAD_SUCCESSFULL,
  FETCH_TYPEHEAD_FAILURE,
} from '../../common/constants/actions';

/**
 * Export the action for reducers, actions and sagas
 */
export const TypeheadActionTypes = {
  FETCH_TYPEHEAD_REQUESTED,
  FETCH_TYPEHEAD_SUCCESSFULL,
  FETCH_TYPEHEAD_FAILURE,
};
