import ApiUtils from './apiUtils';

/**
 * Fetch trends
 * @param {Object} param
 */
const fetchTrends = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);
  try {
    const response = await ApiUtils.get(`search?${queryString}`);
    const { data } = response;
    if (data && data.data.length > 0) {
      return {
        response: data,
      };
    }
    return {
      response: { data: [] },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchTrends,
};
