const initialState = {
  configuration: {
    isLoading: true,
    isError: false,
    isUpdateSuccess: false,
    data: [],
    errorMessage: '',
  },
  trends: {
    isLoading: true,
    data: [],
  },
  user: {
    isLoading: true,
    isUpdateSuccess: false,
    data: [],
  },
  report: {
    overview: {
      isLoading: true,
      isError: false,
      data: {},
    },
    tags: {
      isLoading: true,
      isError: false,
      data: [],
    },
    signals: {
      isLoading: true,
      isError: false,
      data: [],
    },
    sources: {
      isLoading: true,
      isError: false,
      data: [],
    },
    readlists: {
      isLoading: true,
      isError: false,
      data: [],
    },
  },

  sourcesTypes: {
    sources: {
      isLoading: true,
      isError: false,
      isUpdateSuccess: false,
      isAddSuccess: false,
      data: [],
      errorMessage: '',
    },
    types: {
      isLoading: true,
      data: [],
    },
  },
  signals: {
    isLoading: true,
    isError: false,
    data: [],
    summaryData: {
      isLoading: false,
      isError: false,
      data: [],
      readlistCount: 10,
      signalCount: 0,
    },
    graphData: {
      isLoading: true,
      isError: false,
      errorMessage: '',
      data: [],
    },
    listData: {
      isLoading: true,
      isError: false,
      count: 0,
      data: [],
    },
  },
  tags: {
    isLoading: true,
    isUpdateSuccess: false,
    data: [],
  },
  readList: {
    summaryData: {
      isLoading: true,
      isError: false,
      readlistCount: 0,
      signalCount: 0,
    },
    listingData: {
      isLoading: true,
      isError: false,
      recordCount: 0,
      signalCount: 0,
      data: [],
    },
  },
  areaSubAreaTags: {
    area: {
      isLoading: true,
      data: [],
    },
    subArea: {
      isLoading: true,
      data: [],
    },
    tag: {
      isLoading: true,
      data: [],
    },
  },
  dashboard: {
    isLoading: true,
    data: [],
    isError: false,
    errorMessage: '',
  },
  typehead: {
    isLoading: false,
    data: [],
    isError: false,
  },
  searchTag: null,
  auth: { loginUserGroups: [] },
};

export default initialState;
