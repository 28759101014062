import { searchTagTypes } from './searchTagTypes';

/**
 *
 * @function : searchTagReducer
 * @param {*} state:contains initial and final state of data
 * @param {*} action:returns the action object
 * @returns sets tag from main seachrbar
 */
const searchTagReducer = (state = {}, action) => {
  switch (action.type) {
    case searchTagTypes.SET_SEARCH_TAG:
      return { ...state, searchTag: action.payload };

    default:
      return state;
  }
};

export default searchTagReducer;
