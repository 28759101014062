import { all } from 'redux-saga/effects';
import configurationSaga from './configuration/configurationSagas';
import trendsSaga from './trends/trendsSaga';
import userSaga from './user/userSaga';
import signalsSaga from './signals/signalsSagas';
import tagsSignal from './tags/tagsSaga';
import areaSubAreaTagsSaga from './areaSubAreaTags/areaSubAreaTagsSagas';
import readListSaga from './readList/readListSagas';
import dashboardSaga from './dashboard/dashboardSagas';
import typeheadSaga from './typehead/typeheadSagas';
import sourcesTypesSaga from './sourcesTypes/sourcesTypesSagas';
import reportSaga from './report/reportSagas';

export default function* rootSaga() {
  yield all([
    userSaga(),
    trendsSaga(),
    configurationSaga(),
    signalsSaga(),
    tagsSignal(),
    areaSubAreaTagsSaga(),
    readListSaga(),
    dashboardSaga(),
    typeheadSaga(),
    sourcesTypesSaga(),
    reportSaga(),
  ]);
}
