import cron from 'cron-validate';

export const CHAR_LENGTH = 256;

class Validation {
  /**
   * required validation
   * @param {String} value
   * @returns Boolean
   */
  isRequired = (value) => value.replace(/\s+/g, '').length !== 0;

  /**
   * required validation
   * @param {any} value
   * @returns Boolean
   */
  checkIsEmpty = (value) => value.length;

  /**
   * check lenght of string
   * @param {String, length} value,length
   * @returns Boolean
   */
  checkLength = (value, charLength) => !(value.trim().length <= charLength);

  /**
   * check for valid cron value
   * @param {String} cronString
   * @return Boolean
   */
  checkCron = (cronValue) => cron(cronValue).isValid();
}

export default new Validation();
