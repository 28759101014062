import React, { useState } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { USER_ROLES } from '../../../common/constants/options';
import { putUserRequested } from '../../../state/user/userActions';
import Error from '../../../common/components/Error';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import Validation, { CHAR_LENGTH } from '../../../common/utils/validation';

const EditUser = (props) => {
  const { closeEdit, user } = props;
  const [userRole, setUserRole] = useState(user.userRole);
  const [isEnabled, setUserEnabled] = useState(user.isEnabled);

  // const [organization, setOrganization] = useState(user.organization);

  const dispatch = useDispatch();

  const { isLoading, isError } = useSelector((state) => state.user);

  /**
   * Edit user form handler
   * @param {event} event
   */
  const editUserFormHandler = (event) => {
    event.preventDefault();

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure want to submit?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(
              putUserRequested({
                id: user.userName,
                data: {
                  userRole,
                  isEnabled,
                },
              })
            );
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const onSwitchAction = () => {
    setUserEnabled(!isEnabled);
  };

  return (
    <>
      <div className="modal-head-wrapper">
        <h2 className="modal-heading">Edit User</h2>
        <Button onClick={closeEdit} className="transparent-btn close-btn">
          <span className="cmp-close" />
        </Button>
      </div>
      {isError ? <Error /> : null}
      <h3 className="modal-subheading">{user.userName}</h3>
      <div className="form-wrapper">
        <Form className="compass-form" onSubmit={editUserFormHandler}>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name:</Form.Label>
                <Form.Control type="text" value={user.name} disabled />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Email:</Form.Label>
                <Form.Control type="text" value={user.email} disabled />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Role:</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={userRole}
                  onChange={(event) => setUserRole(event.target.value)}
                >
                  {USER_ROLES.map((userRoles) => (
                    <option key={userRoles} value={userRoles}>
                      {userRoles}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Status:</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Check
                    className="users-switch"
                    onChange={onSwitchAction}
                    type="switch"
                    checked={isEnabled}
                    label={isEnabled ? 'Enabled' : 'Disabled'}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-end submit-btn">
            <Button className={isLoading ? 'button-loader' : ''} type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
export default EditUser;
