import React from 'react';
import { kFormatter } from '../../../common/utils/helpers';

/**
 *
 * @param {number} readListCount:count to display on card for failed signals
 * @returns JSX with the signal count
 */
const ReadListCount = ({ readListCount }) => (
  <>
    <div className="count-wrapper failed-processing">
      <div className="count-box">
        <p className="content-heading text-end compass-pink">{kFormatter(readListCount || 0)}</p>
        <p className="count-text">Total</p>
      </div>
    </div>
  </>
);

export default ReadListCount;
