import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const NotFoundPage = () => (
  <div className="page-not-found">
    <h1 className="heading-one"> Page not Found</h1>
    <h5 className="not-found-text ">We are sorry the page you requested could not be found.</h5>
    <Link to="/">
      <Button className="mt-4">Home</Button>
    </Link>
  </div>
);

export default NotFoundPage;
