/**
 * Import constant types
 */
import { DashBoardActionTypes } from './dashboardTypes';

/**
 *
 * @function :  dashBoardReducer
 * @param {object} state:contains initial and final state of data
 * @param {object} action:returns the action object
 * @returns {object}
 */

const dashBoardReducer = (state = {}, action) => {
  switch (action.type) {
    /**
     * Action Creator - fetchDashBoard
     * This action is use to call the service to get dashboard data
     */
    case DashBoardActionTypes.FETCH_DASHBOARD_REQUESTED:
      return { ...state, isLoading: true };
    /**
     * Action Creator - fetchDashBoardSuccessfull
     * it will receive the data dispatching from the fetchDashBoardSuccessfull
     */
    case DashBoardActionTypes.FETCH_DASHBOARD_SUCCESSFULL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    /**
     * Action Creator - fetchDashBoardFailure
     * it will receive the error dispatching from the fetchDashBoardFailure
     */
    case DashBoardActionTypes.FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default dashBoardReducer;
