import React, { useEffect, useState } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { Button, Form, Image } from 'react-bootstrap';
import { Provider } from 'react-redux';
import loginlogo from './assets/images/login-logo.svg';
import './assets/scss/app.scss';
import awsConfig from './common/config/awsAmplify';
import AppRouter from './common/components/AppRouter';
import store from './state/store';
import apiUtils from './api/apiUtils';
import Loading from './common/components/Loading';
import Error from './common/components/Error';

Amplify.configure(awsConfig);

const App = () => {
  /**
   * Initial form states
   */
  const initialFormState = {
    username: '',
    password: '',
    name: '',
    newPassword: '',
    authCode: '',
    formType: 'signIn',
  };
  const [formState, updateFormState] = useState(initialFormState);

  const [user, updateUser] = useState(null);

  const [loader, setLoader] = useState(true);

  const [errorMsg, setErrorMsg] = useState();

  const [loginButton, setLoginButton] = useState('Log In');

  /**
   *
   */
  const checkUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      updateUser(currentUser);
      updateFormState(() => ({ ...formState, formType: 'signedIn' }));

      apiUtils.setUserInformation(Auth.user.signInUserSession.idToken.jwtToken);
      updateUser(Auth.user);
      const authenticatedUser = await Auth.currentAuthenticatedUser();

      if (authenticatedUser && authenticatedUser.signInUserSession) {
        // Set Access token in the API layer
        apiUtils.setUserInformation(authenticatedUser.signInUserSession.idToken.jwtToken);
        updateUser(authenticatedUser);
      }
      setLoader(false);
    } catch (err) {
      if (err) {
        setErrorMsg(err.message);
      }
      updateUser(null);
      setLoader(false);
    }
  };

  const authListner = async () => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          break;
        case 'signUp':
          break;
        case 'signOut':
          break;
        default:
          break;
      }
    });
  };

  const onChange = (e) => {
    e.persist();
    setErrorMsg();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  const onChangeNewUser = (e) => {
    e.persist();
    setErrorMsg();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };
  const { formType } = formState;

  /**
   * Change handler to update form states
   */
  const changeHandler = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const { name, newPassword, username, password } = formState;
      const cUser = await Auth.signIn({ username, password });

      const con = await Auth.completeNewPassword(
        cUser, // the Cognito User Object
        newPassword, // the new password
        {
          name,
        }
      );
      if (con) {
        updateFormState(() => ({ ...formState, formType: 'signIn' }));
        setLoginButton('Log In with new password');
      }
    } catch (error) {
      if (error) {
        setErrorMsg(error.message);
      }
    }
    setLoader(false);
  };

  const signIn = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const { username, password } = formState;
      const userSignin = await Auth.signIn({ username, password });

      /**
       * To check if the current login is from a new user
       */
      if (userSignin.challengeName === 'NEW_PASSWORD_REQUIRED') {
        updateFormState(() => ({ ...formState, formType: 'changePass' }));
      } else {
        const registeredUser = await Auth.currentAuthenticatedUser();
        if (Auth.user && Auth.user.signInUserSession) {
          apiUtils.setUserInformation(registeredUser.signInUserSession.idToken.jwtToken);
          updateUser(registeredUser);
          updateFormState(() => ({ ...formState, formType: 'signedIn' }));
          setLoader(false);
        }
      }
    } catch (error) {
      if (error.code === 'InvalidParameterException') {
        setErrorMsg('Incorrect username');
      } else {
        setErrorMsg(error.message);
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    setErrorMsg();
    checkUser();
    authListner();
  }, []);

  if (loader) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 vh-100 flex-column">
        <Loading />;
      </div>
    );
  }

  return user && formType === 'signedIn' ? (
    <div>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </div>
  ) : (
    <>
      <div className="d-flex justify-content-center align-items-center w-100 vh-100 flex-column">
        <Image src={loginlogo} className="signin-box-logo" fluid />

        {formType === 'signIn' ? (
          <Form className="signin-box" onSubmit={(e) => signIn(e)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="compass-white">Username</Form.Label>
              <Form.Control
                type="username"
                name="username"
                placeholder="Username"
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="compass-white">Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="Password"
                onChange={onChange}
              />
            </Form.Group>
            {errorMsg ? (
              <p>
                <Error msg={errorMsg} />
              </p>
            ) : (
              ''
            )}
            <div className="d-grid gap-2">
              <Button className="py-3" size="lg" type="submit">
                {loginButton}
              </Button>
            </div>
          </Form>
        ) : null}
        {formType === 'changePass' ? (
          <Form className="signin-box" onSubmit={(e) => changeHandler(e)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="compass-white">Name</Form.Label>
              <Form.Control type="name" name="name" placeholder="Name" onChange={onChangeNewUser} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="compass-white">New Password</Form.Label>
              <Form.Control
                name="newPassword"
                type="password"
                placeholder="New Password"
                onChange={onChangeNewUser}
              />
            </Form.Group>
            {errorMsg ? (
              <p>
                <Error msg={errorMsg} />
              </p>
            ) : (
              ''
            )}
            <div className="d-grid gap-2">
              <Button className="py-3" size="lg" type="submit">
                Confirm password change
              </Button>
            </div>
          </Form>
        ) : null}
      </div>
    </>
  );
};

export default App;
